import React, { useState, useEffect } from 'react';
import { ajaxFail, showErrorMessage, showSuccessMessage } from '../GlobalState.js';

export default function CommunicationSetting(props) {
    var postdata = {};
    const [listData, setListData] = useState([]);

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;


    }


    const changeAktifPanel = (id) => {
        document.getElementById("iletisimMakinasi").disabled = true;
        document.getElementById("Mobildev").disabled = true;
        document.getElementById("Asistel").disabled = true;
        document.getElementById("mobilpark").disabled = true;
        document.getElementById("Asist").disabled = true;
        document.getElementById("teknomart").disabled = true;
        document.getElementById(id).disabled = false;
    }


    const submitUpdateSMS = (id, name) => {
        var apikey = document.getElementById("apiKey" + name);
        var sender = document.getElementById("sender" + name);
        var fname = name;
        if (listData.find(x => x.id == id) != null)
            fname = listData.find(x => x.id == id).name;
        var rec = {
            userName: document.getElementById("userName" + name).value,
            sifre: document.getElementById("sifre" + name).value,
            userCode: document.getElementById("userCode" + name).value,
            accountId: document.getElementById("accountId" + name).value,
            sender: sender != null ? sender.value : null,
            serviseAddress: document.getElementById("serviseAddress" + name).value,
            apiKey: apikey != null ? apikey.value : null,
            id: id,
            name: fname,
            active: true
        }

        document.getElementById("iletisimMakinasi").disabled = true;
        document.getElementById("mobilpark").disabled = true;
        document.getElementById("Asistel").disabled = true;
        document.getElementById("Asist").disabled = true;
        document.getElementById("Mobildev").disabled = true;
        document.getElementById("teknomart").disabled = true;
        document.getElementById("turacell").disabled = true;
        document.getElementById("TTMesaj").disabled = true;


        fetch("savesmssetting", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(value)

                });

            }
            else {
                showSuccessMessage("SMS Ayarları Güncellendi")
                fetchSMSSetting();
            }
        });
    }




    const submitUpdateEmail = () => {

        var rec = {
            userName: document.getElementById("euserName").value,
            password: document.getElementById("password").value,
            smtpPort: document.getElementById("smtpPort").value,
            smtpServer: document.getElementById("smtpServer").value,
            networkUserName: document.getElementById("networkUserName").value,
            tls: document.getElementById("tls").checked
        }
        fetch("saveemailsetting", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }


    const sendTestEmail = () => {
        var email = document.getElementById("testemail").value;
        fetch("sendtestemail", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(email)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.text().then(value => {
                    if (value.length <= 2)
                        document.getElementById("testemailresult").innerHTML = "Email Gönderildi.";
                    else
                        document.getElementById("testemailresult").innerHTML = value;
                });
            }
        });
    }


    const sendTestSMS = () => {
        var tel = document.getElementById("testtel").value;
        fetch("sendsms", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify("TEST|" + tel)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.text().then(value => {

                    if (value.length <= 2)
                        document.getElementById("testsmsresult").innerText = "SMS Gönderildi.";
                    else
                        document.getElementById("testsmsresult").innerText = value;
                });
            }
        });
    }


    const fetchSMSSetting = () => {
        fetch("getsmssetting", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    if (value.length == 0) return;

                    value.forEach(x => {
                        if (x.id == 1) {
                            document.getElementById("userNameAsist").value = x.userName;
                            document.getElementById("sifreAsist").value = x.sifre;
                            document.getElementById("userCodeAsist").value = x.userCode;
                            document.getElementById("accountIdAsist").value = x.accountId;
                            document.getElementById("senderAsist").value = x.sender;
                            document.getElementById("serviseAddressAsist").value = x.serviseAddress;
                            document.getElementById("activeAsist").checked = x.active;
                            document.getElementById("Asist").disabled = !x.active;
                        }
                        if (x.id == 2) {
                            document.getElementById("userNameMobil").value = x.userName;
                            document.getElementById("sifreMobil").value = x.sifre;
                            document.getElementById("userCodeMobil").value = x.userCode;
                            document.getElementById("accountIdMobil").value = x.accountId;
                            document.getElementById("senderMobil").value = x.sender;
                            document.getElementById("serviseAddressMobil").value = x.serviseAddress;
                            document.getElementById("activeMobil").checked = x.active;
                            document.getElementById("mobilpark").disabled = !x.active;
                        }

                        if (x.id == 3) {
                            document.getElementById("userNameAsistel").value = x.userName;
                            document.getElementById("sifreAsistel").value = x.sifre;
                            document.getElementById("userCodeAsistel").value = x.userCode;
                            document.getElementById("accountIdAsistel").value = x.accountId;
                            document.getElementById("senderAsistel").value = x.sender;
                            document.getElementById("serviseAddressAsistel").value = x.serviseAddress;
                            document.getElementById("activeAsistel").checked = x.active;
                            document.getElementById("Asistel").disabled = !x.active;
                        }

                        if (x.id == 4) {
                            document.getElementById("userNameMobildev").value = x.userName;
                            document.getElementById("sifreMobildev").value = x.sifre;
                            document.getElementById("userCodeMobildev").value = x.userCode;
                            document.getElementById("accountIdMobildev").value = x.accountId;
                            document.getElementById("senderMobildev").value = x.sender;
                            document.getElementById("serviseAddressMobildev").value = x.serviseAddress;
                            document.getElementById("activeMobildev").checked = x.active;
                            document.getElementById("Mobildev").disabled = !x.active;
                        }
                        if (x.id == 5) {
                            document.getElementById("userNameiletisimMakinasi").value = x.userName;
                            document.getElementById("sifreiletisimMakinasi").value = x.sifre;
                            document.getElementById("userCodeiletisimMakinasi").value = x.userCode;
                            document.getElementById("accountIdiletisimMakinasi").value = x.accountId;
                            document.getElementById("serviseAddressiletisimMakinasi").value = x.serviseAddress;
                            document.getElementById("apiKeyiletisimMakinasi").value = x.apiKey;
                            document.getElementById("activeiletisimMakinasi").checked = x.active;
                            document.getElementById("iletisimMakinasi").disabled = !x.active;
                        }
                        if (x.id == 6) {
                            document.getElementById("userNameteknomart").value = x.userName;
                            document.getElementById("sifreteknomart").value = x.sifre;
                            document.getElementById("userCodeteknomart").value = x.userCode;
                            document.getElementById("accountIdteknomart").value = x.accountId;
                            document.getElementById("serviseAddressteknomart").value = x.serviseAddress;
                            document.getElementById("activeTeknomart").checked = x.active;
                            document.getElementById("senderteknomart").value = x.sender;
                            document.getElementById("teknomart").disabled = !x.active;
                        }
                        if (x.id == 7) {
                            document.getElementById("userNameturacell").value = x.userName;
                            document.getElementById("sifreturacell").value = x.sifre;
                            document.getElementById("userCodeturacell").value = x.userCode;
                            document.getElementById("accountIdturacell").value = x.accountId;
                            document.getElementById("serviseAddressturacell").value = x.serviseAddress;
                            document.getElementById("activeturacell").checked = x.active;
                            document.getElementById("senderturacell").value = x.sender;
                            document.getElementById("turacell").disabled = !x.active;
                        }
                        if (x.id == 8) {
                            document.getElementById("userNameTTMesaj").value = x.userName;
                            document.getElementById("sifreTTMesaj").value = x.sifre;
                            document.getElementById("userCodeTTMesaj").value = x.userCode;
                            document.getElementById("accountIdTTMesaj").value = x.accountId;
                            document.getElementById("serviseAddressTTMesaj").value = x.serviseAddress;
                            document.getElementById("activeTTMesaj").checked = x.active;
                            document.getElementById("senderTTMesaj").value = x.sender;
                            document.getElementById("TTMesaj").disabled = !x.active;
                        }


                    })

                    setListData(value);

                })
            }
        });
    }

    const fetchEmailSetting = () => {

        fetch("getemailsetting", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }

            else {
                response.json().then(value => {
                    if (value.length == 0) return;
                    document.getElementById("euserName").value = value[0].userName;
                    document.getElementById("password").value = value[0].password;
                    document.getElementById("smtpPort").value = value[0].smtpPort;
                    document.getElementById("smtpServer").value = value[0].smtpServer;
                    document.getElementById("smtpServer").value = value[0].smtpServer;
                    document.getElementById("networkUserName").value = value[0].networkUserName;
                    document.getElementById("tls").checked = value[0].tls;



                })
            }
        });
    }

    useEffect(() => {
        fetchEmailSetting();
        fetchSMSSetting();
    }, [])



    return (<>
        <div className="rounded-1 mt-5 p-2 bg-light ">

            <h5>SMS Ayarları</h5>
            <div className="row rounded-3 mb-1">

                <div className="col-12" >
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="activeAsist" onMouseDown={() => changeAktifPanel("Asist")} />
                    <label className="form-check-label ps-1 " for="flexRadioDefault1">
                        Asist İletişim
                    </label>
                </div>
            </div>

            <fieldset className="row" id="Asist" disabled>

                <div className="row rounded-3 mb-1">

                    <div className="col-10" >
                        <input type="text" className="form-control form-control-sm" placeholder="Servis Adresi" id="serviseAddressAsist" />
                    </div>
                </div>
                <div className="row rounded-3">
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="userNameAsist" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="sifreAsist" />
                    </div>
                    <div className="col-2 " >
                        <input type="text" className="form-control form-control-sm" placeholder="User Code" id="userCodeAsist" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Account id" id="accountIdAsist" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Sender" id="senderAsist" />
                    </div>
                    <div className="col-2" >
                        <button onClick={() => submitUpdateSMS(1, "Asist")} className="btn btn-sm btn-primary">Güncelle</button>
                    </div>
                </div>
            </fieldset>

            <div className="row rounded-3 mb-1 mt-3">

                <div className="col-12" >
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="activeMobil" onMouseDown={() => changeAktifPanel("mobilpark")} />
                    <label class="form-check-label ps-1" for="flexRadioDefault1">
                        Mobil Park
                    </label>
                </div>
            </div>

            <fieldset className="row" id="mobilpark" disabled>

                <div className="row rounded-3 mb-1">

                    <div className="col-10" >
                        <input type="text" className="form-control form-control-sm" placeholder="Servis Adresi" id="serviseAddressMobil" />
                    </div>
                </div>
                <div className="row rounded-3">
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="userNameMobil" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="sifreMobil" />
                    </div>
                    <div className="col-2 " >
                        <input type="text" className="form-control form-control-sm" placeholder="User Code" id="userCodeMobil" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Account id" id="accountIdMobil" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Sender" id="senderMobil" />
                    </div>
                    <div className="col-2" >
                        <button onClick={() => submitUpdateSMS(2, "Mobil")} className="btn btn-sm btn-primary">Güncelle</button>
                    </div>
                </div>
            </fieldset>




            <div className="row rounded-3 mb-1 mt-3">

                <div className="col-12" >
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="activeAsistel" onMouseDown={() => changeAktifPanel("Asistel")} />
                    <label class="form-check-label ps-1" for="flexRadioDefault1">
                        Asistel
                    </label>
                </div>
            </div>

            <fieldset className="row" id="Asistel" disabled>

                <div className="row rounded-3 mb-1">

                    <div className="col-10" >
                        <input type="text" className="form-control form-control-sm" placeholder="Servis Adresi" id="serviseAddressAsistel" />
                    </div>
                </div>
                <div className="row rounded-3">
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="userNameAsistel" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="sifreAsistel" />
                    </div>
                    <div className="col-2 " >
                        <input type="text" className="form-control form-control-sm" placeholder="User Code" id="userCodeAsistel" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Account id" id="accountIdAsistel" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Sender" id="senderAsistel" />
                    </div>
                    <div className="col-2" >
                        <button onClick={() => submitUpdateSMS(3, "Asistel")} className="btn btn-sm btn-primary">Güncelle</button>
                    </div>
                </div>
            </fieldset>

            <div className="row rounded-3 mb-1 mt-3">

                <div className="col-12" >
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="activeMobildev" onMouseDown={() => changeAktifPanel("Mobildev")} />
                    <label class="form-check-label ps-1" for="flexRadioDefault1">
                        Mobildev
                    </label>
                </div>
            </div>

            <fieldset className="row" id="Mobildev" disabled>

                <div className="row rounded-3 mb-1">

                    <div className="col-10" >
                        <input type="text" className="form-control form-control-sm" placeholder="Servis Adresi" id="serviseAddressMobildev" />
                    </div>
                </div>
                <div className="row rounded-3">
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="userNameMobildev" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="sifreMobildev" />
                    </div>
                    <div className="col-2 " >
                        <input type="text" className="form-control form-control-sm" placeholder="User Code" id="userCodeMobildev" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Account id" id="accountIdMobildev" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Sender" id="senderMobildev" />
                    </div>
                    <div className="col-2" >
                        <button onClick={() => submitUpdateSMS(4, "Mobildev")} className="btn btn-sm btn-primary">Güncelle</button>
                    </div>
                </div>
            </fieldset>


            <div className="row rounded-3 mb-1 mt-3">

                <div className="col-12" >
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="activeiletisimMakinasi" onMouseDown={() => changeAktifPanel("iletisimMakinasi")} />
                    <label class="form-check-label ps-1" for="flexRadioDefault1">
                        İletisim Makinasi
                    </label>
                </div>
            </div>
            <fieldset className="row" id="iletisimMakinasi" disabled>

                <div className="row rounded-3 mb-1">

                    <div className="col-10" >
                        <input type="text" className="form-control form-control-sm" placeholder="Servis Adresi" id="serviseAddressiletisimMakinasi" />
                    </div>
                </div>
                <div className="row rounded-3">
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="userNameiletisimMakinasi" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="sifreiletisimMakinasi" />
                    </div>
                    <div className="col-2 " >
                        <input type="text" className="form-control form-control-sm" placeholder="Customer Code" id="userCodeiletisimMakinasi" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Vendor Code" id="accountIdiletisimMakinasi" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Api Key" id="apiKeyiletisimMakinasi" />
                    </div>
                    <div className="col-2" >
                        <button onClick={() => submitUpdateSMS(5, "iletisimMakinasi")} className="btn btn-sm btn-primary">Güncelle</button>
                    </div>
                </div>
            </fieldset>

            <div className="row rounded-3 mb-1 mt-3">

                <div className="col-12" >
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="activeTeknomart" onMouseDown={() => changeAktifPanel("teknomart")} />
                    <label class="form-check-label ps-1" for="flexRadioDefault1">
                        Teknomart
                    </label>
                </div>
            </div>
            <fieldset className="row" id="teknomart" disabled>

                <div className="row rounded-3 mb-1">

                    <div className="col-10" >
                        <input type="text" className="form-control form-control-sm" placeholder="Servis Adresi" id="serviseAddressteknomart" />
                    </div>
                </div>
                <div className="row rounded-3">
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="userNameteknomart" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="sifreteknomart" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Sender" id="senderteknomart" />
                    </div>
                    <div className="col-2 " >
                        <input type="text" hidden className="form-control form-control-sm" placeholder="Customer Code" id="userCodeteknomart" />
                    </div>
                    <div className="col-2" >
                        <input type="text" hidden className="form-control form-control-sm" placeholder="Vendor Code" id="accountIdteknomart" />
                    </div>

                    <div className="col-2" >
                        <button onClick={() => submitUpdateSMS(6, "teknomart")} className="btn btn-sm btn-primary">Güncelle</button>
                    </div>
                </div>
            </fieldset>



            <div className="row rounded-3 mb-1 mt-3">
                <div className="col-12" >
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="activeturacell" onMouseDown={() => changeAktifPanel("turacell")} />
                    <label class="form-check-label ps-1" for="flexRadioDefault1">
                        Turacell
                    </label>
                </div>
            </div>
            <fieldset className="row" id="turacell" disabled>
                <div className="row rounded-3 mb-1">
                    <div className="col-10" >
                        <input type="text" className="form-control form-control-sm" placeholder="Servis Adresi" id="serviseAddressturacell" />
                    </div>
                </div>
                <div className="row rounded-3">
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="userNameturacell" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="sifreturacell" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Originator" id="senderturacell" />
                    </div>
                    <div className="col-2 " >
                        <input type="text" className="form-control form-control-sm" placeholder="ChannelCode" id="userCodeturacell" />
                    </div>
                    <div className="col-2" >
                        <input type="text" hidden className="form-control form-control-sm" placeholder="Vendor Code" id="accountIdturacell" />
                    </div>

                    <div className="col-2" >
                        <button onClick={() => submitUpdateSMS(7, "turacell")} className="btn btn-sm btn-primary">Güncelle</button>
                    </div>
                </div>
            </fieldset>




            <div className="row rounded-3 mb-1 mt-3">
                <div className="col-12" >
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="activeTTMesaj" onMouseDown={() => changeAktifPanel("TTMesaj")} />
                    <label class="form-check-label ps-1" for="flexRadioDefault1">
                        TTMesaj
                    </label>
                </div>
            </div>
            <fieldset className="row" id="TTMesaj" disabled>
                <div className="row rounded-3 mb-1">
                    <div className="col-10" >
                        <input type="text" className="form-control form-control-sm" placeholder="Servis Adresi" id="serviseAddressTTMesaj" />
                    </div>
                </div>
                <div className="row rounded-3">
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="userNameTTMesaj" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="sifreTTMesaj" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Originator" id="senderTTMesaj" />
                    </div>
                    <div className="col-2 " >
                        <input type="text" className="form-control form-control-sm" placeholder="ChannelCode" id="userCodeTTMesaj" />
                    </div>
                    <div className="col-2" >
                        <input type="text" hidden className="form-control form-control-sm" placeholder="Vendor Code" id="accountIdTTMesaj" />
                    </div>

                    <div className="col-2" >
                        <button onClick={() => submitUpdateSMS(8, "TTMesaj")} className="btn btn-sm btn-primary">Güncelle</button>
                    </div>
                </div>
            </fieldset>




            <div className="row border-top mt-5 pt-2 mb-3">
                <div className="col-2" >
                    <input type="text" className="form-control form-control-sm" placeholder="TEL - 055******" id="testtel" />
                </div>
                <div className="col-2 " >
                    <button onClick={sendTestSMS} className="btn btn-sm btn-primary">Test SMS Gönder</button>
                </div>
                <div className="col-8" id="testsmsresult">
                </div>
            </div>

        </div>


        <div className="rounded-1 mt-5 p-2 bg-light ">
            <h5>Email Ayarları</h5>
            <div className="row ">
                <div className="col-3" >
                    <input type="text" className="form-control form-control-sm" placeholder="Email" id="euserName" />
                </div>
                <div className="col-3" >
                    <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="password" />
                </div>

                <div className="col-3" >
                    <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="networkUserName" />
                </div>

                <div className="col-3" >
                    <input type="number" className="form-control form-control-sm" placeholder="Port" id="smtpPort" />
                </div>

            </div>
            <div className="row mt-2">
                <div className="col-4" >
                    <input type="text" className="form-control form-control-sm" placeholder="Sunucu" id="smtpServer" />
                </div>
                <div className="col-2" >
                    <div class="form-check">
                        <input className="form-check-input" type="checkbox" id="tls" />
                        <label className="form-check-label" for="flexCheckChecked">
                            TLS
                        </label>
                    </div>
                </div>
                <div className="col-3" >
                    <button onClick={submitUpdateEmail} className="btn btn-sm btn-primary">Güncelle</button>
                </div>
            </div>

            <div className="row border-top mt-2 pt-2 mb-3">
                <div className="col-4" >
                    <input type="text" className="form-control form-control-sm" placeholder="Email" id="testemail" />
                </div>
                <div className="col-3 " >
                    <button onClick={sendTestEmail} className="btn btn-sm btn-primary">Test Email Gönder</button>
                </div>
                <div className="col-5" id="testemailresult">
                </div>
            </div>
        </div>
    </>
    );


}