import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom'
import { isEmpty, sendpost, showErrorMessage, useGlobalState, sendget } from '../GlobalState.js';
import Register1 from "./Register1.js";
import ForgotPassword from "./ForgotPassword.js";
import aes from 'crypto-js/aes';
import crypto from 'crypto-js';
import { ajaxFail } from '../GlobalState.js';
import { useSearchParams } from 'react-router-dom'
import VersionData from "../version.json";


export default function Login(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    var user_d = searchParams.get("tc");
    var verify = searchParams.get("code");
    const [activepannel, setActivepannel] = useState(null);
    

    if (isEmpty(user_d) == false && activepannel == null) {
        const Buffer = require("buffer").Buffer;
        var value = Buffer.from(user_d, 'base64').toString('utf-8').split("$");
        if (user_d != "direct")
            setActivepannel(<Register1 tc={value[0]} ad={value[1]} soyad={value[2]} verify={verify} changeComponent={setActivepannel} />)
        else
            setActivepannel(<Register1 tc={user_d}  changeComponent={setActivepannel} />)
    }





    const [token, setToken] = useGlobalState('token');



    useEffect(() => {
        sendget("getversion", "", (response) => {
            response.text().then(value => {
                var b = document.getElementById("backendversion");
                if (b)
                    b.innerHTML = "/"+value.replaceAll('"','');
            })
        }, () => {
            var b = document.getElementById("backendversion");
            if (b)
                b.innerHTML = "/Error";
        })
       
    }, []);



    const onSubmit = (e) => {
        e.preventDefault();

       // sendpost("comparedwg", null, "mu");
       // return;



        document.getElementById("buttonlabel").innerHTML = "Giriş Yapılıyor... "
        document.getElementById("buttonSpinner").hidden = false;
        var logindata = {
            UserName: document.getElementById("UserName").value,
            Password: document.getElementById("Password").value
        };

        //fetch("yapiruhsatkaydet", {
        //    method: 'POST',
        //    headers: {
        //        'Accept': 'application/json',
        //        'Content-Type': 'application/json'
        //    }
        //}).then(async response => {

        //});
        //return;

        fetch("submitlogin", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(logindata)
        }).then(async response => {
            document.getElementById("buttonlabel").innerHTML = "Devam Et"
            document.getElementById("buttonSpinner").hidden = true;

            if (!response.ok) {
                showErrorMessage("Kullanıcı adı veya şifre hatalı")

            }
            else {
                response.text().then(value => {
                    value = value.replaceAll('"', '');

                    const Buffer = require("buffer").Buffer;
                    value = Buffer.from(value, 'base64').toString('utf-8')
                    var k = value.substring(0, 16)
                    value = value.substring(16);
                    var c = crypto.AES.encrypt(JSON.stringify({ value }), k).toString();
                    setToken(k + c);
                })
            }
            document.getElementById("Password").value = "";
            document.getElementById("UserName").value = "";
        });

    }


    if (token != null && token.length > 0) {
        return <Navigate to="/" />
    }


    var login = <>
        <form className="row" method="POST" onSubmit={onSubmit}>
            <div className="row">
                <div className="col-12 col-lg-3"> </div>
                <div className="col-12 col-lg-6 text-center">

                    <div className="mb-4 mt-5">
                        <label htmlFor="UserName" className="form-label text-muted "><b>Kullanıcı Adı</b> veya<b> Mail Adresi</b></label>
                        <input type="text" className="form-control  bg-light" id="UserName" placeholder="" name="UserName"  />
                    </div>
                    <div className="">
                        <label htmlFor="Password" className="form-label text-muted"><b>Şifre</b></label>
                    </div>

                    <div className="mb-3">
                        <input type="password" className="form-control   bg-light" id="Password" placeholder="" name="Password" />
                    </div>

                </div>

            </div>
            <div className="row">
                <div className="col-md-12 col-lg-3"> </div>
                <div className="col-12 col-lg-6 text-center">
                    <button type="submit" className="btn btn-primary w-100 rounded-3  mt-3">
                        <span id="buttonlabel"> Giriş </span>
                        <span className="ms-1 spinner-border spinner-border-sm" role="status" aria-hidden="true" id="buttonSpinner" hidden></span>
                    </button>

                </div>
            </div>

            <div className="row">
                <div className="col-md-12 col-lg-3"> </div>
                <div className="col-12 col-lg-6 text-center ">
                    <a className="btn btn-sm btn-danger text-decoration-none w-100 rounded-3  mt-3" href="edevletlogin">  <img src={require('../Dashboard/edevletlogo.png').default} style={{ width: "28px", height: "28px" }} /> <b> e-Devlet Kapısı ile Giriş Yap</b></a>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 col-lg-6"> </div>
                <div className="col-12 col-lg-3 text-center">
                    <div className=" text-end mt-1">
                        <button className="btn btn-sm btn-link text-decoration-none " onClick={() => { setActivepannel(<ForgotPassword />) }}><b>Şifremi Unuttum </b></button>
                    </div>
                </div>

            </div>



            <div className="row">


                <div className="mt-3 text-center text-muted align-bottom ">
                    {/* <div className="pt-2"> <img src={require('../Dashboard/backg.png').default} className="img-fluid" /> </div>*/}
                    <a href="https://universe.web.tr/iletisim/" className="text-decoration-none" >Universe Hakkında Destek</a>

                </div>
            </div>
        </form ></>

    return <div className="row ">
        <div id="aesscript"></div>
        <div className="col-sm-12 col-md-4 m-0 ps-0"  >
            {/* <img src={require('../Dashboard/loginleft.jpg').default} style={{ width: "98%" }} />*/}
            <img src='./getfile?sec=loginleft.jpg' style={{ width: "98%" }} />
           
        </div>

        <div className="col-sm-12 col-md-8 mt-3 pt-3 mt-2 text-center" >
            <div className="text-center">
                <h3 className="font-weight-bold"><b>Universe'e Hoşgeldin</b></h3>
                {activepannel == null && isEmpty(user_d) && <><b><span className="text-secondary">Yeni misin?</span></b> <a className="btn btn-sm btn-link text-decoration-none text-danger" href="register"><b>Hemen E-Devlet Kapısı ile ÜYE OL</b> </a></>}
                {activepannel != null && <><b><span className="text-secondary">Kullanıcı hesabın varmı ?</span></b>
                    <a className="btn btn-sm btn-link text-decoration-none" href="/"><b>Giriş Yap</b> </a>
                </>}
            </div>
            {activepannel == null ? login : activepannel}

            <div className="" style={{ "margin-top": "10%" }} >
                <img src={require('../Header/100yıl.png').default} style={{ maxWidth: "120px" }} className="ms-auto me-auto mt-auto mb-0 "  />
            </div>
            <p className="mt-3" >{VersionData.version}<span id="backendversion"></span> </p>
        </div>

    </div>

}


