import React, { useState, useEffect } from 'react';
import { ajaxFail, useGlobalState } from '../GlobalState.js';
import $ from 'jquery';


export default function EImzaListem(props) {

    const [eimzalist, setEimzalist] = useState([]);
    const [sortDirection, setSortDirection] = useState({ "transactionUUID": 1 });
    const [userData, setuserData] = useGlobalState('userData');
    var sortField = null;

    var selectedImzaList = []
    useEffect(() => {
        getEimzaList(0);
    }, [])


    const myCompare = (a, b) => {
        if (sortField == null)
            sortField = "transactionUUID"
        var av = a[sortField];
        var bv = b[sortField];

        if (av == null && a.imza != null) {
            av = a.imza[sortField];
        }
        if (bv == null && b.imza != null) {
            bv = b.imza[sortField];
        }
        if (av == null && bv == null)
            return 0;
        if (av == null && bv != null)
            return -1 * sortDirection[sortField];
        if (av != null && bv == null)
            return 1 * sortDirection[sortField];

        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * sortDirection[sortField];

        if (av < bv) {
            return -1 * sortDirection[sortField];
        }
        if (av > bv) {
            return 1 * sortDirection[sortField];
        }
        return 0;
    }


    const sortList = (fildname) => {
        sortField = fildname;

        if (sortDirection[sortField] == null || sortDirection[sortField] == -1) {
            sortDirection[sortField] = 1;
        }
        else {
            sortDirection[sortField] = -1;
        }

        var temp = [...eimzalist]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setEimzalist(temp);
    }

    const fetchAuthers = (apprec) => {
        fetch("appauthorlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: apprec.id
        }).then(async response => {

            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    data = JSON.parse(data)
                    if (data != null)
                        apprec.müellifKullanıcıAdı = data.map(x => x.userName + " ").join();

                    var popup = window.open("/");
                    popup.appData = apprec;
                    popup.eimza = apprec.imza;
                });
            }
        });
    }


    const topluimzala = (apprec) => {
        var popup = window.open("/");
        popup.selectedImzaList = selectedImzaList;
        popup.userData = userData;
    }



    const getEimzaList = (all) => {
        var url = "getmyeimzalist?all=0";
        if (all != null)
            url = "getmyeimzalist?all=" + all;

        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }

            else {
                response.json().then(async data => {
                    if (data != null) {
                        data.forEach(r => { r.imza = JSON.parse(r.iadeNedeni); r.imzaid = r.imza.id; });
                    }
                    data.sort(myCompare)
                    setEimzalist(data);
                    var z = document.getElementById("eimzalistemcount");
                    if (z != null)
                        z.innerHTML = data.filter(r => r.imza.transactionUUID == " imzam bekleniyor").length;
                });
            }
        });
    }
    window.fetchEimzaListem = getEimzaList;

    const showAll = event => {
        if (event.target.checked == true) {
            getEimzaList(1);
        }
        else {
            getEimzaList(0);
        }
    }
    const hepsiniSec = event => {
        if (event.target.checked == true) {
            eimzalist.forEach(x => {
                var c = document.getElementById("check" + x.imza.userFileId)
                if (c) {
                    c.checked = true;
                    selectedImzaList.push(x);
                }
            })
        }
        else {
            selectedImzaList = [];
            eimzalist.forEach(x => {
                var c = document.getElementById("check" + x.imza.userFileId)
                if (c) {
                    c.checked = false;
                   
                }
            })
        }
        document.getElementById("topluimza").disabled = selectedImzaList.length == 0;
    }



    const filterTable = event => {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable tr").filter(function () {
            var keywords = value.split(" ");
            for (let index = 0; index < keywords.length; ++index)
                if ($(this).text().toLowerCase().indexOf(keywords[index]) == -1)
                    return $(this).toggle(false);
            return $(this).toggle(true);
        });
    }
    const addToImzaList = (event, rec) => {
        if (event.target.checked == true)
            selectedImzaList.push(rec);
        else
            selectedImzaList = selectedImzaList.filter(x => x.imza.userFileId != rec.imza.userFileId);
        document.getElementById("topluimza").disabled = selectedImzaList.length == 0;

    }


    return <div className="align-items-center justify-content-center" >
        <div className="row">
            <div className="col-4">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={showAll} />
                    <label class="form-check-label" for="flexSwitchCheckDefault">Süreci Tamamlanan İmzaları da Göster</label>
                </div>
            </div>
            <div className="col-4">
                <input className="form-control w-100" id="myInput" type="text" placeholder="Arama..." onKeyUp={filterTable} />
            </div>
            <div className="col-4 text-end">
                <button className="btn btn-sm btn-primary text-end " id="topluimza" onClick={() => { topluimzala(selectedImzaList); }}>Seçilenleri İmzala</button>
            </div>
        </div>
        <div className="overflow-auto" style={{ maxHeight: 600 }}>

            <table className='table table-vdstriped table-sm' aria-labelledby="tabelLabel">
                <thead>
                    <tr >
                        <th>
                            <input class="form-check-input" type="checkbox" onClick={hepsiniSec} />
                        </th>
                        <th>
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("imzaid")} > Eimza-Id </button>
                            </div>
                        </th>

                        <th>
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("id")} > Id </button>
                            </div>
                        </th>
                        <th>
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("ada")} > Ada/Parsel </button>
                            </div>
                        </th>
                        <th>
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("arsaSahibi")} > Taşınmaz Sahibi </button>
                            </div>
                        </th>
                        <th>
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("temp")} > Eimzayı Açan </button>
                            </div>
                        </th>

                        <th>
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("fikra")} > Kullanıcı Tipi </button>
                            </div>
                        </th>

                        <th>
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("basvuruTipi")} > Başvuru Tipi </button>
                            </div>
                        </th>

                        {/*<th>*/}
                        {/*    <div>*/}
                        {/*        <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("basvuruTarihi")} > Başvuru Tarihi </button>*/}
                        {/*    </div>*/}
                        {/*</th>*/}

                        <th>
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("tarih")} > Açılma Tarihi </button>
                            </div>
                        </th>

                        <th>
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("surecname")} > İsmi </button>
                            </div>
                        </th>

                        <th>
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("transactionUUID")} > Durumu </button>
                            </div>
                        </th>
                        <th>
                            <div>
                                <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("downloadAction")} > İmza Tarihi </button>
                            </div>
                        </th>

                    </tr>
                </thead>


                <tbody id="myTable">
                    {eimzalist.map(apprec => <tr >
                        <td >{
                            apprec.imza.transactionUUID == " imzam bekleniyor" &&
                            <input class="form-check-input" type="checkbox" onClick={(e) => addToImzaList(e, apprec)} id={"check" + apprec.imza.userFileId} />
                        }

                        </td>
                        <td >
                            {apprec.imzaid}
                        </td>
                        <td >
                            {apprec.id}
                        </td>
                        <td >
                            {apprec.ada}
                        </td>
                        <td >
                            {apprec.arsaSahibi}
                        </td>
                        <td >
                            {apprec.temp}
                        </td>
                        {/*<td >*/}
                        {/*    {apprec.basvuruYapan}*/}
                        {/*</td>*/}
                        <td >
                            {apprec.imza.fikra}
                        </td>
                        <td >
                            {apprec.basvuruTipi}
                        </td>
                        <td >
                            {apprec.imza.tarih}
                        </td>
                        <td >
                            {apprec.imza.surecname}
                        </td>
                        <td className={apprec.imza.transactionUUID == "imzalandı" ? "text-success" : apprec.imza.transactionUUID == " imzam bekleniyor" ? "text-primary fw-bold" : apprec.imza.transactionUUID == "sırası bekleniyor" ? "text-warning" : ""}>
                            {apprec.imza.transactionUUID}
                        </td>
                        <td >
                            {apprec.imza.downloadAction}
                        </td>
                        <td style={{ "overflow": "hidden", "white-space": "nowrap" }}>
                            <a href="#" onClick={() => { fetchAuthers(apprec); }} className=" text-decoration-none "><i className="fa fa-edit align-middle" style={{ "font-size": "24px" }}></i></a>
                        </td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
    </div>







}


