import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import BasvuruAlaniDetail from './BasvuruAlaniDetail.js';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, showConfirmMessage, showErrorMessage, showSuccessMessage,removeTurkcLow, isEmpty } from '../GlobalState.js';

export default function BasvuruAlanlariSetting(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);
    const [basvuruTurleri, setbasvuruTurleri] = useState([]);
    const [roles, setRoles] = useState([]);
    const [sortDirection, setSortDirection] = useState({});



    useEffect(() => {
        fetchRoles();
        getdenetimlist();
    }, [])


    var sortField = null;
    const myCompare = (a, b) => {
        var av = a[sortField];
        var bv = b[sortField];
        var ret = 1;
        if (sortDirection[sortField] == "ZA")
            ret = -1;

        if (av == null && bv != null)
            return -1 * ret;
        if (av != null && bv == null)
            return 1 * ret;
        if (av == null && bv == null)
            return 0;

        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * ret

        if (av < bv) {
            return -1 * ret;
        }
        if (av > bv) {
            return 1 * ret;
        }
        return 0;
    }
    const sortList = (fildname) => {
        sortField = fildname;
        if (sortDirection[sortField] == null || sortDirection[sortField] == "ZA") {
            sortDirection[sortField] = "AZ";
        }
        else {
            sortDirection[sortField] = "ZA";
        }

        var temp = [...listData]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setListData(temp);
    }


    const fetchRoles = () => {
        fetch("getrolelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setRoles(value)
                })
            }
        });

    }



    const removeRecord = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removeAppField", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));
    }



    const fetchList = () => {
        fetch("appfieldlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const saveAll = () => {

        fetch("saveallappsetting", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(listData)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncenlleme Başarılı");
            }
        });

    }

    

    const getdenetimlist = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setbasvuruTurleri(value);
                    fetchList();
                })
            }
        });
    }




    const filterColumn = (index) => {
        var cl = ["id", "basvuruTipi", "name", "type", "initValue"];
        $("#myTable tr").filter(function () {

            for (var c = 0; c < cl.length; c++) {
                var keywordElem = document.getElementById(cl[c]);
                var td = $(this).children().eq(c);
                if (keywordElem != null && isEmpty(keywordElem.value) == false) {
                    var keyword = keywordElem.value;
                    var pass = true;
                    if (keyword.startsWith("-")) {
                        pass = false
                        keyword = keyword.substr(1);
                    }
                    if (keyword.length == 0) break;
                    td.attr('style', 'background: #fff8dd !important');
                    var t1 = removeTurkcLow(td.text());
                    var t2 = removeTurkcLow(keyword);
                    if (keyword == "0" && td.html().includes("checkbox") && !td.html().includes("checked"))
                        return $(this).toggle(true);
                    if (keyword == "1" && td.html().includes("checked"))
                        return $(this).toggle(true);
                    if ((t1.indexOf(t2) == -1) == pass)
                        return $(this).toggle(false);
                }
                else
                    td.removeAttr('style');
            }
            return $(this).toggle(true);
        });
    }


    

    const filterTable = (event) => {
        var textbox = document.getElementById("mySearchInput")
        if (textbox == null)
            return;
        var value = textbox.value;

        $("#myTable tr").filter(function () {
            var rowtext = $(this).text();
            var t1 = removeTurkcLow(rowtext);
            var t2 = removeTurkcLow(value);
            if (t1.indexOf(t2) == -1)
                return $(this).toggle(false);

            return $(this).toggle(true);
        });
        
    }


    const renderTable = (tabledata) => {
        var mytable =
            <div className=" table-responsive mainpagescroll mt-1 border-top" style={{ "height": "85vh" }}>
                <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                    <thead className="text-center bg-white " style={{ "position": "sticky", "top": 0, "z-index": "3" }}>
                        <th >
                            <button className="btn btn-link btn-sm text-decoration-none m-0 p-0" onClick={() => sortList("id")}>Id</button>
                            
                            <input type="text" className="form-control form-control-sm mb-1" id="id" placeholder="" name="id" onChange={() => filterColumn()} />
                        </th>
                        <th >
                            <button className="btn btn-link btn-sm text-decoration-none m-0 p-0" onClick={() => sortList("basvuruTipi")}>Başvuru Türü</button>
                            <input type="text" className="form-control form-control-sm mb-1" id="basvuruTipi" placeholder="" name="basvuruTipi" onChange={() => filterColumn()} />
                        </th>
                        <th >
                            <button className="btn btn-link btn-sm text-decoration-none m-0 p-0" onClick={() => sortList("name")}>Soru</button>
                            
                            <input type="text" className="form-control form-control-sm mb-1" id="name" placeholder="" name="name" onChange={() => filterColumn()} />
                        </th>

                        <th >
                            <button className="btn btn-link btn-sm text-decoration-none m-0 p-0" onClick={() => sortList("type")}> Soru Türü</button>
                           
                            <input type="text" className="form-control form-control-sm mb-1" id="type" placeholder="" name="type" onChange={() => filterColumn()} />
                        </th>

                        <th >
                            <button className="btn btn-link btn-sm text-decoration-none m-0 p-0" onClick={() => sortList("initValue")}>Varsayılan Değer</button>
                            
                            <input type="text" className="form-control form-control-sm mb-1" id="initValue" placeholder="" name="initValue" onChange={() => filterColumn()} />
                        </th>

                        <th ></th>
                    </thead>
                    <tbody id="myTable">
                        {tabledata.map(apprec =>
                            <tr key={apprec.id} role="button" className="v-hover" onClick={() => {
                                window.changeComponent(<BasvuruAlaniDetail key={apprec.id} appRec={apprec} basvuruTurleri={basvuruTurleri} roles={ roles} />) }} >
                                <td >{apprec.id}</td>
                                <td >
                                    {apprec.basvuruTipi}
                                   
                                </td>
                                <td>
                                    {apprec.name}
                                </td>
                                <td>
                                    {apprec.type=="text"?"Yazı":
                                    apprec.type=="select"?"Çoktan Seçmeli":
                                    apprec.type=="checkbox"?"Checkbox":
                                    apprec.type=="number"?"Sayı":
                                    apprec.type=="select multiple"?"Çoklu Seçim":
                                    apprec.type=="date"?"Tarih":
                                    apprec.type=="textarea"?"Buyuk Yazı":
                                    apprec.type}
                                  
                                     
                                </td>

                                <td>
                                    {apprec.initValue} 
                                 </td>
                               

                                <td>
                                    <button onClick={(event) => { event.stopPropagation(); removeRecord(apprec.id) }} className="btn btn-link btn-sm text-decoration-none text-danger p-0 m-0 shadow-none">
                                        <span class="material-icons"> delete    </span>
                                    </button>
                                   {/* <button onClick={() => submitUpdateRecord} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;*/}
                                </td>
                            </tr>
                        )}
                      
                        {/*<tr>*/}
                            
                        {/*    <td colspan="5" className="text-end"> <button onClick={() => saveAll()} className="btn btn-primary btn-sm m-1" id="saveallbutton">Tümünü Kaydet</button></td>*/}
                        {/*</tr>*/}
                    </tbody>
                </table>
            </div>
        return (mytable);
    }








    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div >
            <div className="row mb-2 mt-2">
                <div className="col-4">
                    <h5>Başvuru Alanları</h5>
                </div>
                <div className="col-4">
                    <input className="form-control form-control-sm" id="mySearchInput" type="text" placeholder="Arama" onKeyUp={filterTable} />
                </div>
                <div className="col-0 col-md-4 text-end">
                    <button onClick={() => window.changeComponent(<BasvuruAlaniDetail appRec={new Object()}   basvuruTurleri={basvuruTurleri} roles={roles} />)} className=" btn btn-sm btn-primary">Yeni Soru Oluştur </button>
                </div>
            </div>



            <div className="row">
                <div className="col-12">
                    {listTable}
                </div>
            </div>


        </div>

    );


}