import React, { useState, useEffect } from 'react';
import TarifeSetting from './TarifeSetting';
import { ajaxFail, showConfirmMessage, showSuccessMessage, sendpost, sendget, isEmpty, showErrorMessage } from '../GlobalState.js';

export default function TarifeDetail(props) {
    var postdata = {};
    const [tarifeCetveli, setListData] = useState([]);
    const [tarife, setTarife] = useState(props.tarife);
    var changeComponent = props.changeComponent;

    useEffect(() => {
        fetchList();
    }, [])




    const [formulaParts, setFormulaParts] = useState([]);
    const [selectedPart, setSelectedPart] = useState(null);
  
    const [finalFormula, setFinalFormula] = useState("");
    const [selectedOperator, setSelectedOperator] = useState("+");
    const [showIfModal, setShowIfModal] = useState(false);

    var conditionLeft = "", conditionRight = "", ifTrueValue = "", ifFalseValue = "", conditionOperator = "";
    //const [conditionLeft, setConditionLeft] = useState("");
   // const [conditionOperator, setConditionOperator] = useState("");
   // const [conditionRight, setConditionRight] = useState("");
   // const [ifTrueValue, setIfTrueValue] = useState("");
   // const [ifFalseValue, setIfFalseValue] = useState("");
    const [lastElementType, setLastElementType] = useState("O");


    const operators = ["+", "-", "*", "/", "if"];
    const conditionOperators = ["=", ">", "<", ">=", "<=", "!="];

    const handleAddKey = (key) => {
       
        var c = document.getElementById("newFormul").value;
        document.getElementById("newFormul").value= c + key
       
    };

    const resetCombos = () => {
        document.getElementById("tarifecetveliselect").selectedIndex = -1;
        document.getElementById("soruselect").selectedIndex = -1;
        document.getElementById("birlestirmeselect").selectedIndex = -1;
        document.getElementById("sabitsayi").value = "";
    };



    const handleAddOperator = (operator) => {
        if (operator === "if") {
           
            setShowIfModal(true); // Open modal to define the if statement
        } else {

           
            var c = document.getElementById("newFormul").value;
            resetCombos();
            var c = document.getElementById("newFormul").value;
            document.getElementById("newFormul").value = c + " " + operator + " ";
           
            
        }
    };

    const handleAddConstant = (constant) => {
      

        var c = document.getElementById("newFormul").value;
        document.getElementById("newFormul").value = c +  constant ;
        resetCombos();
    };

    const handleSavePart = () => {
        var currentPart=document.getElementById("newFormul").value;
        if (currentPart.trim() !== "") {
            setFormulaParts((prev) => [...prev, currentPart]);
            document.getElementById("newFormul").value = "";
        }
    };

    const handleAddToFinalFormula = () => {

        var part = null;
        if (selectedPart == null) {
            showErrorMessage("Bir Formül Seçiniz");
            return;
        }
        part = selectedPart;

        const formulaUpdate = finalFormula
            ? `(${finalFormula}) ${selectedOperator} (${part})`
            : part;
        setFinalFormula(formulaUpdate);
    };

    const handleSaveIfStatement = () => {
        const condition = conditionLeft + " " + conditionOperator + " " + conditionRight;
        const ifStatement = 'if(' + condition + ' , ' + ifTrueValue + ' , ' + ifFalseValue + ')';
        // setFormulaParts((prev) => [...prev, ifStatement]);
        resetCombos();
        var c=document.getElementById("newFormul").value;
        document.getElementById("newFormul").value= c + ifStatement;
        
        //setCurrentPart("");
        setShowIfModal(false);
        resetIfModal();
       
    };

    const resetIfModal = () => {
       // setConditionLeft("");
       // setConditionOperator("");
       // setConditionRight("");
       // setIfTrueValue("");
        // setIfFalseValue("");
        conditionLeft = "";
        conditionRight = "";
        ifTrueValue = "";
        ifFalseValue = "";
        conditionOperator = "";
    };

    const renderOptions = () => {
        return [
            ...tarifeCetveli.map(({ name }) => name),
            ...formulaParts,
            ...(isEmpty(tarife.sorular) ? [] : JSON.parse(tarife.sorular).map(x =>x.tip=="text"?"'"+x.name+"'":x.name)),
            "Sabit Değer",
        ];
    };

    const addSoru = () => {
        var soruadi = document.getElementById("soru").value;
        var sorutipi = document.getElementById("sorutip").value;
        var sorudegeri = document.getElementById("options").value;
        if (isEmpty(soruadi) || isEmpty(sorutipi)) {
            showErrorMessage("Soru Adı ve Soru Teipi Seçilmelidir");
            return;
        }
        soruadi = soruadi.trim();
        if (/^[a-zA-Z0-9_]+$/.test(soruadi) == false) {
            showErrorMessage("Soru Adı sadece harf veya rakam içerebilir");
            return;
        }

        var newsoru = { name: soruadi, tip: sorutipi, options: sorudegeri }
        if (isEmpty(tarife.sorular)) {
            tarife.sorular = JSON.stringify([newsoru])
        }
        else {
            var s = JSON.parse(tarife.sorular);
            s.push(newsoru);
            tarife.sorular = JSON.stringify(s);
        }
        save(tarife)

    };

    const soruSil = (idx) => {
        if (isEmpty(tarife.sorular)) {
            return;
        }
        else {
            var s = JSON.parse(tarife.sorular);
            s.splice(idx, 1);
            tarife.sorular = JSON.stringify(s);
        }
        save(tarife)

    };




    const renderDropdown = (key, setValue, label) => (
        <div className="row mb-1">
            <div className="col-3">
                {label}
            </div>
            <div className="col-6">
                <select
                   
                    onChange={(e) => {
                        if (e.target.value == "Sabit Değer")
                            document.getElementById("id" + key).hidden = false;
                        setValue(e.target.value)
                    }
                    }
                    className="form-select form-select-sm shadow-none"
                >
                    <option value="">Seç</option>
                    {renderOptions().map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>

            <div className="col-3 ps-0" id={"id" + key} hidden>
                <input className="form-control form-control-sm"
                    type="text"
                    placeholder=""
                    onChange={(e) => {
                        if (isEmpty(e.target.value)==false && /^[a-zA-Z0-9_]+$/.test(e.target.value) == false) {
                            showErrorMessage("Sabit değer sadece harf veya rakam içerebilir");
                            
                        }
                        else
                          setValue(e.target.value)
                    }}

                    />
                </div>
            
        </div>
    );



    const renderSorularTable = () => {
        var sorular = [];
        if (isEmpty(tarife.sorular) == false) {
            sorular = JSON.parse(tarife.sorular);
            if (sorular.map == null) {
                showErrorMessage("Sorular Listesi Hatalı " + tarife.sorular);
                sorular = [];
                tarife.sorular = null;
            }
        }

        var mytable = <div className="border rounded-1 p-1 mb-3">

            <div className="row p-2">
                <div className="col-3">
                    <input type="text" className="form-control form-control-sm" id="soru" placeholder="Soru" name="soru" />
                </div>
                <div className="col-3">
                    <select className="form-select form-select-sm" id="sorutip" placeholder="Soru Tipi" name="sorutip" >
                        <option value=""></option>
                        <option value="text">Yazı</option>
                        <option value="number">Sayı</option>
                        <option value="select">Çoktan Seçmeli</option>

                    </select>
                </div>
                <div className="col-3">
                    <input type="text" className="form-control form-control-sm" id="options" placeholder="Varsayılan Değer" name="options" />

                </div>

                <div className="col-3 text-end">
                    <button className="btn btn-sm btn-primary" onClick={addSoru}>Ekle</button>
                </div>
            </div>


            <div className="table-responsive mainpagescroll mt-3 border-top">
                <table className='table table-vdstriped table-sm ' aria-labelledby="tabelLabel">
                    <thead className="">

                        <th className="text-start">Soru</th>
                        <th className="text-start">Soru Tipi</th>
                        <th className="text-start">Varsayılan Değer</th>

                    </thead>
                    <tbody >
                        {sorular.map((apprec, idx) =>
                            <tr className="mt-0 mb-0 pt-0 pb-0">
                                <td>{apprec.name} </td>
                                <td>
                                    {apprec.tip}
                                </td>
                                <td>{apprec.options}
                                </td>
                                <td>
                                    <button className="btn btn-sm btn-link text-decoration-none text-danger" onClick={(e) => soruSil(idx)}><i className="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        )}

                    </tbody>
                </table>
            </div>
        </div>
        return (mytable);
    }







    const fetchList = () => {
        sendget("tarifecetvelilist", "spinner1", (response) => {
            response.json().then(value => {
                setListData(value)
            })
        })
    }





    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }




    const save = (rec, confirm) => {
        if (rec == null)
            rec = postdata;
        if (isEmpty(rec.name)) {
            showErrorMessage("Tüm Bilgileri Doldurunuz", "");
            return;
        }
        sendpost("savetarife", rec, "spinner1", (response) => {
            response.json().then(value => {
                if (confirm)
                    showSuccessMessage("Tarife Kaydedildi")
                var newtarife = value.find(x => x.id == props.tarife.id);
                if (newtarife) {
                    setTarife(newtarife);
                }
            })
        })


    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value.trim();
    }

    return (
        <div className="border  rounded border-light">
            <div className="row mb-2">

                <div className="col-2">{tarife.id} - {tarife.tarih}
                    <input type="text" id="tarifeName" className="form-control form-control-sm shadow-none" defaultValue={tarife.name} />
                </div>
                <div className="col-7 text-primary fw-bold">
                    <br />
                    <strong>Mevcut Formul</strong> : {tarife.formul}

                </div>
                <div className="col-1 text-end ">
                    <span class="ms-1 spinner-border spinner-border-sm" id="spinner1" hidden></span>
                </div>
                <div className="col-2 text-end ">
                    <button className="btn btn-success btn-sm me-1" onClick={() => {
                        if (isEmpty(finalFormula) == false)
                            tarife.formul = finalFormula;

                        var name = document.getElementById("tarifeName").value;
                        if (isEmpty(name) == false)
                            tarife.name = name;
                        save(tarife, true);
                    }}>
                        Kaydet
                    </button>
                    <button className="btn btn-sm btn-primary" onClick={() => changeComponent(<TarifeSetting changeComponent={changeComponent} />)}>Geri Dön</button>
                </div>
            </div>

            {renderSorularTable()}


            <div className="bg-light border rounded-2 p-2 mt-5">
                <div className="row">
                    <div className="col-12 ">

                        <strong>Formul Paneli</strong>
                    </div>
                </div>
                <div className="row">

                    <div className="col-3">
                        <div className="">
                            Tarife Cetveli
                        </div>
                        <div className="">
                            <select id="tarifecetveliselect" onChange={(e) => handleAddKey(e.target.value)} className="form-select shadow-none form-select-sm">
                                <option value="">
                                </option>
                                {tarifeCetveli.map(({ name }) => (
                                    <option value={name}>
                                        {name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="">
                            Tarife Soruları
                        </div>
                        <div className="">
                            {isEmpty(tarife.sorular) ? "Soru Bulunmamaktadır" :
                                <select id="soruselect"  onChange={(e) => handleAddKey(e.target.value)} className="form-select shadow-none form-select-sm">
                                    <option value="" >
                                    </option>
                                    {JSON.parse(tarife.sorular).map(x => (
                                        <option value={x.tip == "text" ? "'" + x.name + "'" : x.name}>
                                            {x.tip=="text"?"'"+x.name+"'":x.name}
                                        </option>
                                    ))}
                                </select>}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="">
                            Sabit Değer
                        </div>
                        <div className="input-group ">
                            <input 
                                type="number"
                                className="form-control form-control-sm shadow-none"
                                placeholder="Saysal değer"
                                id="sabitsayi"
                            />
                            <button className="btn btn-link p-0 ms-1" onClick={() => handleAddConstant(document.getElementById("sabitsayi").value)}   >
                                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="">
                            İşlem
                        </div>

                        <div className="btn-group">
                            {operators.map((operator) => (
                                <button
                                    key={operator}
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => handleAddOperator(operator)}
                                >
                                    {operator}
                                </button>
                            ))}
                        </div>
                    </div>

                </div>
                <div className="row mt-1">
                    <div className="col-11 pe-0">
                        <div className="mb-2 text-primary fw-bold">
                            Üretilecek Formül: <input type="text" id="newFormul" className="form-control form-control-sm shadow-none"  />
                        </div>
                    </div>
                    <div className="col-1 text-end">
                        <br />
                        <button className="btn btn-primary btn-sm  shadow-none" onClick={handleSavePart}>
                            Ekle
                        </button>
                    </div>
                </div>
                <div className="border rounded-1 ms-1 p-1">
                    <div className="row">
                        <div className="col-12">

                            <div className="mb-2 fw-bold">
                                Eklenen Geçici Formüller
                            </div>

                        </div>
                    </div>

                    {formulaParts.map((part, index) => (
                        <div className={"row  m-1 " + (index % 2 == 1 ? "" : " bg-light")} >
                            <div className="col-12">
                                <div class="form-check ">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                        onClick={() => setSelectedPart(part)}
                                    />
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        {part}
                                    </label>
                                </div>
                            </div>
                        </div>


                    ))}

                    <div className="row  mt-2">
                        <div className="col-2">
                            <select id="birlestirmeselect" 
                                className="form-select form-select-sm"
                                value={selectedOperator}
                                onChange={(e) => setSelectedOperator(e.target.value)}
                            >
                                <option value=""></option>
                                {operators.filter(x => x != "if").map((operator) => (
                                    <option key={operator} value={operator}>
                                        {operator}
                                    </option>
                                ))}
                            </select>

                        </div>
                        <div className="col-3">

                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => handleAddToFinalFormula()}
                            >
                                Son Formüle Ekle
                            </button>

                            <button className="btn btn-warning btn-sm ms-2" onClick={() => setFormulaParts([])}>
                                Tümünü Sil
                            </button>
                        </div>
                    </div>
                </div>


                <div className="mb-1 mt-2 ms-1 p-1 border">
                    <span className="fw-bold">Son Formül</span>

                    <button className="btn btn-warning btn-sm ms-2" onClick={() => setFinalFormula("")}>
                        Temizle
                    </button>
                    <div className=" p-2">{finalFormula}</div>

                </div>

            </div>






            {showIfModal && (
                <div
                    className="modal fade show d-block"
                    tabIndex="-1"
                    style={{ background: "rgba(0,0,0,0.5)" }}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Koşul Ayarları</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setShowIfModal(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                {renderDropdown("conditionLeft", (v) => conditionLeft=v, "Sol Değer")}
                                <div className="row">
                                    <div className="col-3">İşlem</div>
                                    <div className="col-6">
                                        <select
                                            className="form-select form-select-sm mb-1"
                                            
                                            onChange={(e) => conditionOperator = e.target.value}>
                                            <option value="">Select</option>
                                            {conditionOperators.map((operator) => (
                                                <option key={operator} value={operator}>
                                                    {operator}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {renderDropdown("conditionRight", (v) => conditionRight=v, "Sağ Değer")}
                                {renderDropdown("ifTrueValue", (v) => ifTrueValue = v, <span className="text-success">ise</span>)}
                                {renderDropdown("ifFalseValue", (v) => ifFalseValue = v, <span className="text-danger">Değilse</span>)}
                            </div>
                            <div className="modal-footer">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleSaveIfStatement}
                                >
                                    Kaydet
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => setShowIfModal(false)}
                                >
                                    Vazgeç
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

















        </div >


    );


}