import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, showConfirmMessage, showSuccessMessage, sendpost, sendget, isEmpty, showErrorMessage } from '../GlobalState.js';
import TarifeDetail from './TarifeDetail.js';

export default function TarifeSetting(props) {
    var postdata = {};
    const [listData, setListData] = useState([]);
    var changeComponent = props.changeComponent;
    useEffect(() => {
        fetchList();
    }, [])





    const fetchList = () => {
        sendget("tarifelist", "spinner1", (response) => {
            response.json().then(value => {
                setListData(value)
            })
        })
    }


    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive mainpagescroll" style={{ "height": "85vh" }}>
                <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light" >
                        <th width="35px" className="text-start">id</th>
                        <th className="text-start">Ad</th>
                        {/*<th>Nitelik</th>*/}
                        {/*<th>Değer</th>*/}
                        <th className="text-start">Tarih</th>
                        <th></th>
                    </thead>
                    <tbody id="myTable">
                        {tabledata.map(apprec =>
                            <tr key={apprec.id}>
                                <td>{apprec.id}</td>
                                <td >{apprec.name} </td>
                                {/*<td><input type="text" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} name="nitelik" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.nitelik} /></td>*/}
                                {/*<td><input type="number" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} name="deger" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.deger} /></td>*/}
                                <td className="text-start">{apprec.tarih}</td>

                                <td className="text-end">
                                    {/*<button onClick={() => { save(apprec); }} className="btn btn-link btn-sm text-decoration-none  shadow-none"><i class="fa fa-save" title="Güncelle"></i></button>*/}
                                    <button onClick={() => { changeComponent(<TarifeDetail tarife={apprec} changeComponent={changeComponent} />) }} className="btn btn-link btn-sm text-decoration-none  shadow-none"><i class="fa fa-edit" title="Güncelle"></i></button>

                                    <button onClick={() => { sil(apprec); }} className="btn btn-link btn-sm text-decoration-none  shadow-none ps-0"><i class="fa fa-trash-o" title="Sil"></i></button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        return (mytable);
    }

    const sil = (rec) => {
        showConfirmMessage(rec.id + " numaralı kayıt silinsin mı?", "", "Evet", "Hayır", () => {


            sendpost("removetarife", rec, "spinner1", (response) => {
                response.json().then(value => {
                    setListData(value)
                })
            })
        })

    }



    const save = (rec) => {
        if (rec == null)
            rec = postdata;
        if (isEmpty(rec.name) ) {
            showErrorMessage("Tarife İsmini Doldurunuz", "");
            return;
        }
        sendpost("savetarife", rec, "spinner1", (response) => {
            response.json().then(value => {
                setListData(value)
            })
        })


    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value.trim();
    }

    return (
        <div className="border  rounded border-light">
            <div className="row">
                <div className="col-9">
                    <h5>Tarifeler</h5>
                </div>
                <div className="col-3 text-end ">
                    <span class="ms-1 spinner-border spinner-border-sm" id="spinner1" hidden></span>
                </div>
            </div>


            <div className="row border rounded-2 m-1 p-2">
                <div className="col-md-4 col-12">
                    <input type="text" className="form-control form-control-sm" id="name" placeholder="Yeni Tarife Adı" name="name" onChange={handleInputChange} key={listData} />
                </div>
                <div className="col-md-4 col-12">
                    {/*<input type="text" className="form-control form-control-sm" id="sira" placeholder="Nitelik" name="nitelik" onChange={handleInputChange} key={listData} />*/}
                </div>
                <div className="col-md-3 col-12">
                    {/*<input type="number" className="form-control form-control-sm  " laceholder="Değer" name="deger" onChange={handleInputChange} key={listData} />*/}
                </div>
                <div className="col-md-1  col-12 text-end">
                    <button onClick={() => save(null)} className="btn btn-sm btn-link text-decoration-none shadow-none"><i className="fa fa-plus-circle align-middle" style={{ "font-size": "20px" }}></i> </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {renderTable(listData)}
                </div>
            </div>
        </div>

    );


}