import { createGlobalState } from 'react-hooks-global-state';
import * as UserPermConstants from './User/UserPermConstants';
import Swal from 'sweetalert2';

window.Swal = Swal;
export const chartColors = ['#E63E3E', '#D7A8DB', '#8E81DC', '#507EEA', '#0057C2', '#2F95C1', '#2E4C6D', '#396EB0', '#E69080', '#2E4C6D', '#396EB0', '#DADDFC',]
export const chartTextColors = ['#E5BC00', '#01E044', '#00A5DB', '#EAE100', '#3DC100', '#00B8DB', '#7008DB', '#DB2800', '#59A3DC']

export const myGlobalState = {
    role: "",
    userName: "",
    userData: null,
    loginfromedevlet: false,
    token: "",
    globalData: null,
    archivedAppList: [],
    archivedAppDate: " "
};
var myglobal = createGlobalState(myGlobalState);

export const { useGlobalState } = myglobal


export const capitalizeWords = (str) => {
    if (str == null || str.length == 0 || str == "")
        return str;
    // split the string into words
    const words = str.split(' ');

    // iterate over each word
    const capitalizedWords = words.map((word) => {
        // handle Turkish special characters
        const specialChars = { ' i': ' İ', ' ı': ' I', ' ğ': ' Ğ' };
        word = word.replace(/([ i I ı ğ])/g, (char) => specialChars[char] || char);

        // capitalize the first letter of the word
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // join the capitalized words back into a string
    return capitalizedWords.join(' ');
}


export const changeTurkceToEnglis = (s) => {
    if (s == null) return "";
    return s.replaceAll("ş", "s").replaceAll("Ş", "S").replaceAll("ı", "i")
        .replaceAll("İ", "I").replaceAll("ğ", "g").replaceAll("Ğ", "G").replaceAll("ü", "u").replaceAll("Ü", "U")
        .replaceAll("ç", "c").replaceAll("Ç", "C").replaceAll("Ö", "O").replaceAll("ö", "o")
}

export const ajaxFail = (response, showAlert) => {
    console.log(response.statusText)
    response.text().then(value => {
        console.log(value)
        if (showAlert == true)
            showErrorMessage(value);

    })
    if (response.status == 401) {
        logout();
    }
}

export const cutString = (s, n) => {
    if (s == null)
        return "";
    if (s.length <= n)
        return s;
    return s.substr(0, n) + "...";
}

export const isEmpty = (x) => {
    return x == null || x.length == 0
}



const passFild = (rec, colname, appfieldlist) => {
    if (colname == null || rec == null || rec.basvuruTipi == null)
        return true;
    var field = appfieldlist.find(x => x.name == colname);
    if (field.basvuruTipi == null || field.basvuruTipi.length == 0)
        return true;
    if (field.basvuruTipi.includes(rec.basvuruTipi))
        return true;
    return false;
}


export const getBolean = (v) => {
    try {
        if (v == null)
            return false;
        if (v == true || String(v).toLowerCase() == "true") {
            return true;
        }
        return false;
    }
    catch (ex) {
        return false;
    }
    return false;
}



export const passFilter = (rec, filter, username, userKabulListPerms, appfieldlist) => {
    try {

        if (filter == null || filter.length == 0) return true;
        var result = false;
        var conds = filter.split(",");
        if (conds == null) return true;

        var dynamicSoru = rec.appDataObject;

        for (var i = 0; i < conds.length; i++) {
            var currentCondition = conds[i].trim();
            var and = currentCondition.includes("AND") || conds.length == 1;
            and = and || (i == 0 && conds.length > 1 && conds[1].trim().includes("AND"));
            currentCondition = currentCondition.replaceAll("AND", "");
            currentCondition = currentCondition.replaceAll("OR", "");
            if (currentCondition.includes('>')) {
                var c = currentCondition.split(">");
                var colname = c[0].trim();


                var value = c[1].trim().split(";");
                if (rec[colname] != null) {
                    if (typeof rec[colname] == 'string' || rec[colname] instanceof String) {
                        if (!value.some(v => rec[colname].localeCompare(v) >= 0)) {
                            if (and == true)
                                return false;
                        }
                        else result = true;
                    }
                    else if (!value.some(v => rec[colname] > v)) {
                        if (and == true) return false;
                    }
                    else result = true;
                }
                else {
                    if (dynamicSoru != null) {
                        if (!passFild(rec, colname, appfieldlist))
                            return false;

                        if (dynamicSoru[colname] == null || !value.some(v => parseInt(dynamicSoru[colname]) > parseInt(v))) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }
                    else
                        if (and == true) return false;
                }
            }
            if (currentCondition.includes('<')) {
                var c = currentCondition.split("<");
                var colname = c[0].trim();

                var value = c[1].trim().split(";");
                if (rec[colname] != null) {

                    if (typeof rec[colname] == 'string' || rec[colname] instanceof String) {
                        if (!value.some(v => rec[colname].localeCompare(v) <= 0)) {
                            if (and == true)
                                return false;
                        }
                        else result = true;
                    }
                    else if (!value.some(v => parseInt(rec[colname]) < parseInt(v))) {
                        if (and == true) return false;
                    }
                    else result = true;
                }
                else {
                    if (dynamicSoru != null) {
                        if (!passFild(rec, colname, appfieldlist))
                            return false;
                        if (dynamicSoru[colname] == null || !value.some(v => parseInt(dynamicSoru[colname].value) < parseInt(v))) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }
                    else
                        if (and == true) return false;
                }

            }
            if (currentCondition.includes('=') == true && currentCondition.includes("!=") == false) {
                var c = currentCondition.split("=");
                var colname = c[0].trim();

                var value = "";

                if (colname == "kontrolor" || colname == "müellifKullanıcıAdı" || colname == "basvuruYapan") value = [username];  //if knorolor value is empty set it to username
                if (colname == "basvuruTipi") {
                    value = userKabulListPerms   //if basvuruTipi value is empty set it to userkabullist
                    if (value == null || value.length == 0)
                        value = [rec[colname]];
                }
                if (c.length > 1 && c[1] != null && c[1].length > 0) {
                    value = c[1].trim().split(";");
                }



                if (rec[colname] != null) { //kontrolor field may have more than one kontrolor user name
                    if (!value.some(v => (rec[colname] == v
                        || ((colname == "kontrolor" || colname == "müellifKullanıcıAdı") && rec[colname].includes(v))))) {
                        if (and == true) return false;
                    }
                    else result = true;
                }
                else {
                    if (dynamicSoru != null) {
                        if (!passFild(rec, colname, appfieldlist))
                            return false;
                        if (value.some(v => v == false) && dynamicSoru[colname] == null) {
                            result = true;
                        }
                        else if (dynamicSoru[colname] == null || !value.some(v => dynamicSoru[colname] == v || dynamicSoru[colname].value == v || String(dynamicSoru[colname].value).toLowerCase() == String(v).toLowerCase())) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }
                    else
                        if (and == true) return false;
                }
            }
            if (currentCondition.includes('!=')) {
                var c = currentCondition.split("!=");
                var colname = c[0].trim();

                var value = "";
                if (colname == "kontrolor" || colname == "müellifKullanıcıAdı" || colname == "basvuruYapan") value = [username];
                if (c.length > 1 && c[1] != null && c[1].length > 0) {
                    value = c[1].trim().split(";");
                }
                if (rec[colname] != null) {
                    if (!value.some(v => rec[colname] != v)) {
                        if (and == true) return false;
                    }
                    else result = true;
                }
                else {
                    if (dynamicSoru != null) {
                        if (!passFild(rec, colname, appfieldlist))
                            return false;
                        if (dynamicSoru[colname] == null || !value.some(v => parseInt(dynamicSoru[colname].value) == parseInt(v))) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }
                    else
                        if (and == true) return false;
                }
            }
            if (currentCondition.includes('ISNOTNULL')) {
                var c = currentCondition.split("ISNOTNULL");
                var colname = c[0].trim();


                if (rec[colname] == null) {
                    if (dynamicSoru != null) {
                        if (dynamicSoru[colname] == null) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }
                    else
                        if (and == true) return false;
                }
            }
            if (currentCondition.includes('ISNULL')) {
                var c = currentCondition.split("ISNULL");
                var colname = c[0].trim();
                if (!passFild(rec, colname, appfieldlist))
                    return false;
                if (isEmpty(rec[colname])) {
                    if (isEmpty(dynamicSoru) == false) {
                        if (!passFild(rec, colname, appfieldlist))
                            return false;
                        if (isEmpty(dynamicSoru[colname]) == false) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }
                    else result = true;
                }
                else return false;
            }
            if (currentCondition.includes('LIKE') && !currentCondition.includes('NOTLIKE')) {
                var c = currentCondition.split("LIKE");
                var colname = c[0].trim();

                var value = c[1].trim().split(";");

                if (rec[colname] != null) {
                    if (!value.some(v => rec[colname].toString().includes(v))) {
                        if (and == true) return false;
                    }
                    else result = true;
                }
                else {
                    if (dynamicSoru != null) {
                        if (!passFild(rec, colname, appfieldlist))
                            return false;
                        if (dynamicSoru[colname] == null || !value.some(v => dynamicSoru[colname].value.toString().includes(v))) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }
                    else
                        if (and == true) return false;
                }
            }

            if (currentCondition.includes('NOTLIKE')) {
                var c = currentCondition.split("NOTLIKE");
                var colname = c[0].trim();

                var value = c[1].trim().split(";");
                if (rec[colname] != null) {
                    if (value.some(v => rec[colname].toString().includes(v))) {
                        if (and == true) return false;
                    }
                    else result = true;
                }
                else {
                    if (dynamicSoru != null) {
                        if (!passFild(rec, colname, appfieldlist))
                            return false;
                        if (dynamicSoru[colname] != null && value.some(v => dynamicSoru[colname].value.toString().includes(v))) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }

                }
            }


        }

        return result;
    }
    catch (ex) {

    }
    return false;
}



//-----------------------------------------------------------------------



export const getappdata = (id, callback) => {
    fetch("getapp", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(id)
    }).then(async response => {
        if (!response.ok) {
            ajaxFail(response);
        }
        else {
            response.json().then(async app => {
                callback(app);
            });

        }
    });
}



export const checkSpecialChars = (id, callback) => {
    fetch("getapp", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(id)
    }).then(async response => {
        if (!response.ok) {
            ajaxFail(response);
        }
        else {
            response.json().then(async app => {
                callback(app);
            });

        }
    });
}

export const removeTurkce = (s) => {
    if (s == null)
        return "";
    return s.replaceAll('ı', 'i').replaceAll('ç', 'c').replaceAll('ş', 's').replaceAll('ü', 'u').replaceAll('ö', 'o')
        .replaceAll('ğ', 'g').replaceAll("İ", "I").replaceAll("Ğ", "G")
        .replaceAll('Ö', 'O').replaceAll("Ü", "U").replaceAll("Ç", "C").replaceAll("Ş", "S");
}

export const removeTurkcLow = (s) => {
    if (s == null)
        return "";
    return s.replaceAll('ı', 'i').replaceAll('ç', 'c').replaceAll('ş', 's').replaceAll('ü', 'u').replaceAll('ö', 'o')
        .replaceAll('ğ', 'g').replaceAll("İ", "i").replaceAll("I", "i").replaceAll("Ğ", "g")
        .replaceAll('Ö', 'o').replaceAll("Ü", "u").replaceAll("Ç", "c").replaceAll("Ş", "s").toLowerCase();
}

export const toUpperCaseTurkce = (s) => {
    if (s == null)
        return "";
    return s.replaceAll('i', 'İ').replaceAll('ç', 'Ç').replaceAll('ş', 'Ş').replaceAll('ü', 'Ü').replaceAll('ö', 'Ö')
        .replaceAll('ğ', 'Ğ').replaceAll("ı", "I").toUpperCase();
}



const BusinessDays = (dDate1, dDate2) => { // input given as Date objects
    var iWeeks, iDateDiff, iAdjust = 0;

    if (dDate2.toDateString() == dDate1.toDateString())
        return 0;

    if (dDate2 < dDate1) {
        var t = dDate1;
        dDate1 = dDate2;
        dDate2 = t;
    }
    var iWeekday1 = dDate1.getDay(); // day of week
    var iWeekday2 = dDate2.getDay();
    iWeekday1 = (iWeekday1 == 0) ? 7 : iWeekday1; // change Sunday from 0 to 7
    iWeekday2 = (iWeekday2 == 0) ? 7 : iWeekday2;
    if ((iWeekday1 > 5) && (iWeekday2 > 5)) iAdjust = 1; // adjustment if both days on weekend
    iWeekday1 = (iWeekday1 > 5) ? 5 : iWeekday1; // only count weekdays
    iWeekday2 = (iWeekday2 > 5) ? 5 : iWeekday2;

    // calculate differnece in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
    iWeeks = Math.floor((dDate2.getTime() - dDate1.getTime()) / 604800000)

    if (iWeekday1 < iWeekday2) { //Equal to makes it reduce 5 days
        iDateDiff = (iWeeks * 5) + (iWeekday2 - iWeekday1)
    } else {
        iDateDiff = ((iWeeks + 1) * 5) - (iWeekday1 - iWeekday2)
    }

    iDateDiff -= iAdjust // take into account both days on weekend

    return iDateDiff; // add 1 because dates are inclusive
}

export const calcBusinessDays = (dDate1, dDate2) => { // input given as Date objects
    return BusinessDays(dDate1, dDate2);
}



export const getDenetimSure = (rec, apptypelist, appData, message) => {

    if (rec == null || apptypelist.length == 0)
        return "";
    const surebg = ["#c9f7f5", "#e1f0ff", "#fff4de", "#ffe2e5"]
    const suretxt = ["text-info", "text-primary", "text-warning", "text-danger"]

    var denetimid = rec.denetimID;
    var havaleDate = rec.mappingDate;


    if (appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Kontrol_Ediliyor
        && appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Kontrol_Sirada_Bekliyor) return "";

    var d = apptypelist.find(x => x.id == denetimid);
    if (d == null) return "";

    d = d.deadline;
    if (d == null) return "";

    var deliverdate = havaleDate.split(" ")[0].replaceAll(".", "-") + " 12:00:00"
    havaleDate = new Date(havaleDate.split(" ")[0].replaceAll(".", "-") + " 12:00:00");

    if (isEmpty(rec.deliverDate) == false)
        deliverdate = rec.deliverDate.split(" ")[0].replaceAll(".", "-") + " 12:00:00";
    var h = new Date(deliverdate);
    if (h < havaleDate)
        h = havaleDate;
    if (isEmpty(appData.sonDuzeltmeTarihi) == false && appData.sonDuzeltmeTarihi.includes(" ")) {
        var sonduzeltme = new Date(appData.sonDuzeltmeTarihi.split(" ")[0].replaceAll(".", "-") + " 12:00:00");
        if (h < sonduzeltme)
            h = sonduzeltme;
    }
    var nw = new Date(Date.now());
    var diffDays = d - calcBusinessDays(nw, h)
    if (isNaN(diffDays)) {
        return "dd:" + diffDays + "  d:" + rec.deliverDate + " h:" + h.toDateString() + "  now:" + nw.toDateString();
    }
    var i = diffDays > d * 2 / 3 ? 0 : diffDays > d / 3 ? 1 : diffDays > 0 ? 2 : 3;
    return <div className={" p-2 text-center fw-bold rounded-2 " + suretxt[i]} style={{ "background-color": surebg[i], "border-radius": 3 }}>{message} {diffDays}</div>
}



export const fvisible = (name, sabitsorulistData, applicationdata) => {
    if (sabitsorulistData == null) return "";
    var r = sabitsorulistData.filter(x => x.name == name && (x.basvuruTipi == null || x.basvuruTipi.includes(applicationdata.basvuruTipi)));
    if (r.length == 0) return true;
    return r[0].visible == false || r[0].visible == null;
}
export const fdisabled = (name, sabitsorulistData, applicationdata) => {
    if (sabitsorulistData == null) return "";
    var r = sabitsorulistData.filter(x => x.name == name && (x.basvuruTipi == null || x.basvuruTipi.includes(applicationdata.basvuruTipi)));
    if (r.length == 0) return false;
    return r[0].enable == true;
}
export const fzorunlu = (name, sabitsorulistData, applicationdata) => {
    if (sabitsorulistData == null) return "";
    var r = sabitsorulistData.filter(x => x.name == name && (x.basvuruTipi == null || x.basvuruTipi.includes(applicationdata.basvuruTipi)));
    if (r.length == 0) return "*";
    if (r[0].zorunlu == true)
        return ""
    return "*";
}



export const showErrorMessage = (title, text) => {
    window.Swal.fire({
        title: title,
        text: text,
        confirmButtonText: "Tamam",
        confirmButtonColor: "#0d6efd",
        icon: 'error',
    }).then((result) => {
    })
}

export const showSuccessMessage = (title, text, action) => {
    window.Swal.fire({
        title: title,
        text: text,
        confirmButtonText: "Tamam",
        confirmButtonColor: "#0d6efd",
        icon: 'success',
    }).then((result) => {
        if (action)
            action();
    })
}



export const showConfirmMessage = (title, text, confirmButton, rejectButton, action) => {
    window.Swal.fire({
        title: title,
        text: text,
        showDenyButton: true, confirmButtonText: confirmButton, confirmButtonColor: "#dc3545", denyButtonText: rejectButton, denyButtonColor: "#0d6efd",
        icon: 'question',
    }).then((result) => {
        if (result.isConfirmed) {
            action();
        }
    })
}

export const showInfoMessage = (title, text, confirmButton, rejectButton, action) => {
    window.Swal.fire({
        title: title,
        text: text,
        showDenyButton: rejectButton != null, confirmButtonText: confirmButton, confirmButtonColor: "#dc3545", denyButtonText: rejectButton, denyButtonColor: "#0d6efd",
        icon: 'info',
    }).then((result) => {
        if (result.isConfirmed && action != null) {
            action();
        }
    })
}


export const sortByKey = (array, key) => {
    return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        if (x == null && y == null) return 0;
        if (x == null) return 1;
        if (y == null) return -1;
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}



export const setVisible = (id, vis) => {
    var elem = document.getElementById(id);
    if (elem != null)
        elem.hidden = !vis;
}


export const sendpost = (url, body, spinnerid, successCalBack, failCallback) => {
    setVisible(spinnerid, true);
    fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: body != null ? JSON.stringify(body) : ""
    }).then(async response => {
        setVisible(spinnerid, false);
        if (!response.ok) {
            if (failCallback != null)
                failCallback(response);
            else
                response.text().then(x => showErrorMessage(response.status + " - " + response.statusText, x));
        }
        else {
            if (successCalBack != null)
                successCalBack(response);
        }
    });
}


export const sendget = (url, spinnerid, successCalBack, failCallback) => {
    try {
        setVisible(spinnerid, true);
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then(async response => {
            setVisible(spinnerid, false);
            if (!response.ok) {
                if (failCallback != null)
                    failCallback(response);
                else {
                    if (response.status == 401)
                        window.location.href = "/";
                    else
                        response.text().then(x => showErrorMessage(response.status + " - " + response.statusText, x));
                }
            }
            else {
                if (successCalBack != null)
                    successCalBack(response);
            }
        });
    } catch (exception) {
        showErrorMessage(exception, "")
    }
}


//const swalWithBootstrapButtons = window.Swal.mixin({
//    customClass: {
//        confirmButton: 'ms-2 btn btn-success',
//        cancelButton: 'btn btn-danger'
//    },
//    buttonsStyling: false
//})

//swalWithBootstrapButtons.fire({
//    title: 'Emin misin',
//    text: "Bu işlem geri döndürülemez",
//    icon: 'warning',
//    showCancelButton: true,
//    confirmButtonText: 'Evet, Sil',
//    cancelButtonText: 'Hayır, İptal Et',
//    reverseButtons: true
//}).then((result) => {
//    if (result.isConfirmed) {
//        swalWithBootstrapButtons.fire(
//            'Onaylandı',
//            'Kayıt silme işlemi yapıldı',
//            'success'
//        )
//    }
//})

export const exportExcel = (tableId, downloadlink) => {
    var table = document.getElementById(tableId);
    var rows = Array.prototype.slice.call(table.rows).filter(x => x.style.display != "none");
    var thead = table.querySelector('thead');
    var csv = [];

    if (thead) {
        var headerRow = [];
        var headers = thead.querySelectorAll('th');

        for (var i = 0; i < headers.length - 1; i++) {
            var headerText = headers[i].innerText.trim();
            headerText = headerText.replaceAll(/"/g, '""');
            headerText = headerText.replaceAll(",", " ");
            headerText = headerText.replaceAll("\n", " ");
            headerRow.push(headerText);
        }

        csv.push(headerRow.join(","));
    }


    for (var i = 0; i < rows.length; i++) {

        var row = [], cols = rows[i].cells;

        for (var j = 0; j < cols.length - 1; j++) {
            var cellText = cols[j].innerText;
            cellText = cellText.replaceAll(/"/g, '""');
            cellText = cellText.replaceAll(",", " ");
            cellText = cellText.replaceAll("\n", " ");
            row.push(cellText);
        }

        csv.push(row.join(","));
    }
    //let csvContent = "data:text/csv;charset=utf-8,\uFEFF";

    let csvContent = csv.join("\r\n");

    // var encodedUri = encodeURI(csvContent);
    // window.open(encodedUri);
    var bom = "\uFEFF";
    var blob = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });
    var a = document.getElementById("downloadlink")
    a.href = URL.createObjectURL(blob);
    console.log(a.href)
}


export const jaccardSimilarity = (a, b) => {
    const setA = new Set(a);
    const setB = new Set(b);

    const intersection = new Set([...setA].filter(x => setB.has(x)));
    const union = new Set([...setA, ...setB]);

    return intersection.size / Math.max(a.length, b.length);
}

export const levenshtein = (a, b) => {
    const matrix = [];
    let i, j;

    for (i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    for (j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    for (i = 1; i <= b.length; i++) {
        for (j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1, // substitution
                    matrix[i][j - 1] + 1,     // insertion
                    matrix[i - 1][j] + 1      // deletion
                );
            }
        }
    }

    return 1 - (matrix[b.length][a.length] / Math.max(a.length, b.length));
}



export const logout = () => {
    document.cookie.split("; ").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    window.localStorage.setItem('Token', '');
    sendget("logout", "", (resp) => {
        window.location.href = "/";
    });



}

