import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SideBar from '../Header/SideBar.js';
import SideBarComponent from '../Header/SideBarComponent.js';
import Application from "./Application.js"


import $ from 'jquery';

export default class SharedApplicationList extends Component {
    sortField = "";
    constructor(props) {
        super(props);
        this.state = {
            status: "init", applist: [], colnames: ["id", "basvuruTarihi", "basvuruTipi", "ada", "mahalle", "mevki", "basvuruYapan"]
        };
        this.getApplicationList = this.getApplicationList.bind(this);
        this.refreshList = this.refreshList.bind(this)
       
        this.filterTable = this.filterTable.bind(this)
        this.handleSablonChange = this.handleSablonChange.bind(this)
        this.renderToList = this.renderToList.bind(this)
        this.changeComponent = props.changeComponent;
        this.filter = null;
        this.mysablons = null;
        this.view = props.view;
    }

    refreshList(data) {
        this.setState({
            status: "init", applist: data
        })
    }
    componentDidMount() {
        this.getApplicationList();
    }

    myCompare = (a, b) => {
        if (a[this.sortField] < b[this.sortField]) {
            return -1;
        }
        if (a[this.sortField] > b[this.sortField]) {
            return 1;
        }
        return 0;
    }

    myCompareBasvuruTipi = (a, b) => {
        var s1 = a[this.sortField];
        var s2 = b[this.sortField];
        if (s1 == null) return -1;
        if (s2 == null) return 1;

        var sa = s1.replaceAll('"name":', "").replaceAll(']', "").replaceAll('[', "").replaceAll('{', "").replaceAll('}', "").replaceAll('"', "");
        var sb = s2.replaceAll('"name":', "").replaceAll(']', "").replaceAll('[', "").replaceAll('{', "").replaceAll('}', "").replaceAll('"', "");

        if (sa < sb) {
            return -1;
        }
        if (sa > sb) {
            return 1;
        }
        return 0;
    }


    sortList = (fildname) => {
        this.sortField = fildname;
        var temp = this.state.applist;
        if (fildname.toUpperCase() == "BASVURUTIPI")
            temp.sort(this.myCompareBasvuruTipi);
        else
            temp.sort(this.myCompare);
        this.setState({ applist: temp, status: "done" });
    }





    showApplication(id) {
        this.setState({ applist: this.state.applist, status: "appdetails", appid: id });
    }


    getApplicationList() {
        fetch("getmysharedapp", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            var refreshButton = document.getElementById("refreshButton");
            if (refreshButton != null && refreshButton.classList.contains("fa-refresh") == false) {
                refreshButton.classList.remove("spinner-grow", "spinner-grow-sm")
                refreshButton.classList.add("fa", "fa-refresh")
            }
            if (!response.ok) {
                const error = response.statusText;
                console.log(error);
                this.setState({ status: response.statusText })
            }
            else {
                response.json().then(async data => {
                    this.setState({ applist: data, status: "done" });


                });
            }
        });
    }
    showApplication(id) {
        this.setState({ applist: this.state.applist, status: "appdetails", appid: id });
    }

    filterTable(event) {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable tr").filter(function () {
            var keywords = value.split(" ");
            for (let index = 0; index < keywords.length; ++index)
                if ($(this).text().toLowerCase().indexOf(keywords[index]) == -1)
                    return $(this).toggle(false);
            return $(this).toggle(true);
        });
    }
    passFilter(rec, filter) {
        if (filter == null || filter.length == 0) return true;

        var conds = filter.split(",");
        if (conds == null) return true;
        for (var i = 0; i < conds.length; i++) {
            if (conds[i].includes('>')) {
                var c = conds[i].split(">");
                if (rec[c[0]] != null && !(parseInt(rec[c[0]]) > parseInt(c[1]))) return false;
            }
            if (conds[i].includes('<')) {
                var c = conds[i].split("<");
                if (rec[c[0]] != null && !(parseInt(rec[c[0]]) < parseInt(c[1]))) return false;
            }
            if (conds[i].includes('=')) {
                var c = conds[i].split("=");
                if (rec[c[0]] != null && !(rec[c[0]] == c[1]))
                    return false;
            }

        }

        return true;
    }


    filterColumn = (event) => {
        const target = event.target;
        const value = target.value.toLowerCase();
        var cl = this.state.colnames;
        $("#myTable tr").filter(function () {
            for (var c = 0; c < cl.length; c++) {
                if (document.getElementById(cl[c]).value != null && document.getElementById(cl[c]).value.length > 0)
                    if ($(this).children().eq(c).text().toLowerCase().indexOf(document.getElementById(cl[c]).value) == -1)
                        return $(this).toggle(false);
            }
            return $(this).toggle(true);
        });
    }


    handleSablonChange() {
        var sab = document.getElementById("sablonSelect").value;
        var data = this.mysablons[sab];
        if (data.filter != null)
            this.filter = data.filter;
        if (data.colNames != null && data.colNames.length > 0) {
            var d = data.colNames.replaceAll('"', "");
            if (d.length > 0)
                this.setState({ ...this.state, colnames: d.split(",") });
        }
    }

    renderToList(tabledata) {
        var mytable =
            <div className="mt-1">
                <div className="mt-1 row bg-light rounded">
                    <div className="col-3 text-end pt-2">
                    </div>
                    <div className="col-6  justify-content-center p-2">
                        <input className="form-control form-control-sm " id="myInput" type="text" placeholder="Arama..." onKeyUp={this.filterTable} />
                    </div>

                    <div className="col-3 text-end pt-2">
                        <a onClick={this.getApplicationList} href="#" className="text-primary"> <i className="fa fa-refresh" id="refreshButton"></i></a>
                    </div>
                </div>
                <table className='table mt-3 table-striped  table-sm' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            {this.state.colnames.map(c =>
                                <th> <div><button className="btn btn-link btn-sm text-decoration-none" onClick={() => this.sortList(c)}> {c.toUpperCase()}</button></div>
                                    <div>
                                        <input type="text" className="form-control form-control-sm" id={c} placeholder={c} name={c} onChange={this.filterColumn} />
                                    </div></th>
                            )}
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {tabledata.map(apprec =>
                            this.passFilter(apprec, this.filter) == false ? "" :
                                <tr key={apprec.id}>
                                    {this.state.colnames.map(c => {
                                        if (c == "basvuruTipi" && apprec[c] != null) {
                                            return <td>{apprec[c].replaceAll('"name":', "").replaceAll(']', "").replaceAll('[', "").replaceAll('{', "").replaceAll('}', "").replaceAll('"', "")}</td>
                                        }
                                        if (apprec[c] != null)
                                            return <td name={c}>{apprec[c]}</td>

                                        var appDData = JSON.parse(apprec.appData);
                                        if (appDData!=null && appDData[c] != null)
                                            return <td name={c}>{appDData[c]}</td>
                                        return <td></td>
                                    })}
                                    <td>
                                        {apprec.removed != true && <a href="#" onClick={() => { this.changeComponent(<Application key={apprec.id} appData={apprec} changeComponent={this.changeComponent} backButton={<SharedApplicationList changeComponent={this.changeComponent} />} />) }} className=" text-decoration-none "><i className="fa fa-edit align-middle" style={{ "font-size": "24px" }}></i></a>}
                                    </td>
                                </tr>
                        )}
                    </tbody>
                </table>
            </div>
        return (mytable);
    }




    render() {
        var tabledata =  <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>

        if (this.state.status != "init")
            tabledata =           <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center text-danger" >Kayıt Yok</div>

        if (this.state.applist.length > 0) {
            if (this.view == "card")
                tabledata = this.renderToCards(this.state.applist);
            else
                tabledata = this.renderToList(this.state.applist);
        }



        return <div>

            <div  >
                <div className="  align-items-center justify-content-center  p-1" >
                    {tabledata}
                </div>
            </div>
        </div>

    }

};
