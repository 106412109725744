import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, showConfirmMessage, useGlobalState, sendpost, sendget, isEmpty, showErrorMessage } from '../GlobalState.js';
import TarifeMapping from './TarifeMapping.js';
import * as UserPermConstants from '../User/UserPermConstants';
export default function TarifeMappingList(props) {
    var postdata = {};
    const [role, setRole] = useGlobalState('role');
    const appData = props.appData;
    const [tarifeList, setTarifeList] = useState([]);
    const [tarifeCetveli, setTarifeCetveli] = useState([]);
    const [mappedTarifeList, setMappedTarifeList] = useState([]);
    var pageTotal = {}

    var changeComponent = props.changeComponent;
    useEffect(() => {

        fetchTarifeCetveliList();
    }, [])




    const setTotal = (c, t) => {
        pageTotal[c] = t;
        var total = 0;
        for (var i in pageTotal) {
            total += pageTotal[i];
        }
        document.getElementById("sayfatoplami").innerHTML = "Genel Toplam: " + total + " TL"

    }



    mappedTarifeList.forEach(x => x.tarife = tarifeList.find(t => x.tarifeid == t.id));

    const fetchTarifeCetveliList = () => {
        sendget("tarifecetvelilist", "spinner1", (response) => {
            response.json().then(value => {
                setTarifeCetveli(value)
                fetchList();
            })
        })
    }

    const fetchList = () => {
        sendget("tarifelist", "spinner1", (response) => {
            response.json().then(value => {
                setTarifeList(value)
                fetchMappedTarifeList(value);
            })
        })
    }
    const fetchMappedTarifeList = (tarifelist) => {
        sendget("tarifemappinglist?appid=" + appData.id, "spinner1", (response) => {
            response.json().then(value => {
                value.forEach(x => x.tarife = tarifelist.find(t => x.tarifeid == t.id));
                setMappedTarifeList(value)
            })
        })
    }


    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }


    const sil = (rec) => {
        showConfirmMessage(rec.id + " numaralı kayıt silinsin mı?", "", "Evet", "Hayır", () => {


            sendpost("removetarife", rec, "spinner1", (response) => {
                response.json().then(value => {
                    setTarifeList(value)
                })
            })
        })

    }



    const addNewTarif = () => {
        var tarifeid = document.getElementById("tarifelist").value;
        var tarife = tarifeList.find(x => x.id == tarifeid);
        if (tarife == null) {
            showErrorMessage("Tarife Bulunamadı " + tarifeid);
            return;
        }
        var newmapping = { appid: appData.id, tarifeid: tarifeid }

        sendpost("savetarifemapping", newmapping, "spinner1", (response) => {
            response.json().then(value => {
                if (value.length == 1 && value[0].id < 0) {
                    showErrorMessage(value[0].mapper);
                }
                else {

                    setMappedTarifeList(value)
                }

            })
        })


    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value.trim();
    }

    return (
        <div className="border  rounded border-light">
            <div className="row">
                <div className="col-7">
                    <h5>Tarifeler</h5>
                </div>
                <div className="col-1 text-end ">
                    <span class="spinner-border spinner-border-sm" id="spinner1" hidden></span>
                </div>
                <div className="col-4 text-end ">
                    <span class="text-primary fw-bold" id="sayfatoplami"></span>
                </div>
            </div>

            <div className="row border bg-light rounded-2 m-1 p-2 mb-3" hidden={role.indexOf(UserPermConstants.Tarife_Duzenleme) == -1}>
                <div className="col-2">
                    Tarife Ekle
                </div>
                <div className="col-5">
                    <select className="form-select form-select-sm" id="tarifelist">

                        {tarifeList.map(apprec =>
                            <option value={apprec.id}>

                                {apprec.name}
                            </option>
                        )}
                    </select>
                </div>
                <div className="col-1">
                    <button onClick={addNewTarif} className="btn btn-link btn-sm text-decoration-none  shadow-none ps-0">
                        <i className="fa fa-plus-circle align-middle" style={{ "font-size": "25px" }}></i>
                    </button>

                </div>
            </div>
            <fieldset disabled={role.indexOf(UserPermConstants.Tarife_Duzenleme) == -1 }>
                {mappedTarifeList.map(x =>
                    <div className="mb-5 border rounded-3 p-1">
                        <TarifeMapping tarifeMapping={x} key={x.id} tarifeCetveli={tarifeCetveli} setTotal={setTotal} setMappedTarifeList={setMappedTarifeList} />
                    </div>

                )}
            </fieldset>
        </div>

    );


}