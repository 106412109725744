import React, { useState, useEffect } from 'react';
import { ajaxFail, sendget, showSuccessMessage, useGlobalState, showErrorMessage } from '../GlobalState.js';

import CommunicationSetting from './CommunicationSetting.js';
import * as UserPermConstants from '../User/UserPermConstants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";

export default function GeneralSettingPanel(props) {
    var [globalData, setGlobalData] = useGlobalState('globalData');
    const [role, setRole] = useGlobalState('role');
    const [userName, setUserName] = useGlobalState('userName');
    const [duplicateList, setDuplicateList] = useState([]);

    useEffect(() => {
        fetchGeneralData();
    }, [])


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        globalData[target.name] = value;
        if (value == "true" || value == "false")
            globalData[target.name] = value == "true";
    }

    const fetchGeneralData = () => {
        fetch("getgeneraldata", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {

                    setGlobalData(data);

                });
            }
        });
    }

    const fetchduplicate = () => {
        sendget("getduplicatedirs", "dupspin", (response) => {
            response.json().then(async data => {

                setDuplicateList(data);

            });
        })
    }

    const SendFiles = (event, name) => {
        document.getElementById("sp" + name).hidden = false;
        var selectedFile = { file: event.target.files[0] }
        var formData = new FormData();
        formData.append("file", selectedFile.file);
        formData.append("caption", selectedFile.file.name);
        formData.append("userName", name);
        axios.post(
            "submitgeneralfile",
            formData,
            {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            }
        ).then(res => {
            document.getElementById("sp" + name).hidden = true;

            setGlobalData(res.data);
        })
            .catch(err => { showErrorMessage(err); })
    }





    const setGeneralData = () => {
        fetch("setgeneraldata", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(globalData)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    showSuccessMessage("Güncelleme Başarılı")
                    setGlobalData(data);

                });
            }

        });
    }

    return (
        <div >
            <div className="bg-light m-2 p-2 rounded-3">
                <div className="row ">
                    <div className="col-3">
                        Belediye Adı
                    </div>
                    <div className="col-9">
                        <input type="text" className="form-control form-control-sm" placeholder="" name="belediyeName" id="belediyeName" defaultValue={globalData.belediyeName} onChange={handleInputChange} />
                    </div>
                </div>

                {role.indexOf(UserPermConstants.Pop_Up_Yonetimi) > -1 && <><div className="row mt-2 pe-3">
                    <div className="col-3">

                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="showPopup" name="showPopup" defaultChecked={globalData.showPopup} onChange={handleInputChange} onMouseUp={(e) => {
                                document.getElementById("popupMessage").hidden = e.target.checked;
                                document.getElementById("popupMessageFileDiv").hidden = e.target.checked;

                            }} />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Pop Up Mesajı</label>
                        </div>
                    </div>
                    <div className="col-9 ps-3" id="popupMessage" style={{ "resize": "vertical", "overflow-y": "scroll", "height": "120px" }} hidden={globalData.showPopup != true}>
                         <ReactQuill className="bg-white h-100" name="popupMessage" theme="snow" onChange={(e) => globalData.popupMessage = e} defaultValue={globalData.popupMessage} />
                    </div>
                </div>
                    <div className="row" hidden={globalData.showPopup != true} id="popupMessageFileDiv">
                        <div className="col-3">
                        </div>
                        <div className="col-9 ps-3" >
                            <label for="popupMessageFile" class="form-label"><i aria-hidden="true" title="Dosya Yükle" className="fa fa-upload text-primary" style={{ cursor: "pointer" }}></i></label>
                            <input class="form-control form-control-sm" hidden type="file" id="popupMessageFile" onChange={(e) => SendFiles(e, "popupMessageFile")} />
                            <span class="spinner-border  spinner-border-sm" hidden id="sppopupMessageFile" ></span>
                            <a className="text-decoration-none" href='getgeneralfile?id=popupMessageFile' download={globalData.popupMessageFile}> {globalData.popupMessageFile} </a>

                        </div>
                    </div></>}

                <div className="row mt-3">
                    <div className="col-3">
                        Yasal Uyarı
                    </div>
                    <div className="col-9">
                        <textarea class="form-control" rows="3" name="yasalUyari" id="yasalUyari" onChange={handleInputChange} defaultValue={globalData.yasalUyari}></textarea>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-3">
                        Başvuru Taahhutnamesi
                    </div>
                    <div className="col-9">
                        <textarea class="form-control" rows="3" name="kvkk" id="kvkk" onChange={handleInputChange} defaultValue={globalData.kvkk}></textarea>
                    </div>
                </div>



                <div className="row mt-2">
                    <div className="col-3">
                        Google Harita Ayarları
                    </div>

                    <div className="col-9">
                        <div className="row rounded-3">
                            <div className="col-12 col-sm-4" >
                                <input type="Number" className="form-control form-control-sm" placeholder="Enlem" name="genlem" id="genlem" onChange={handleInputChange} defaultValue={globalData.genlem} />
                            </div>
                            <div className="col-12 col-sm-4" >
                                <input type="Number" className="form-control form-control-sm" placeholder="Boylem" name="gboylem" id="gboylem" onChange={handleInputChange} defaultValue={globalData.gboylem} />
                            </div>
                            <div className="col-12 col-sm-4" >
                                <input type="Number" className="form-control form-control-sm" placeholder="Zoom" name="gzoom" id="gzoom" onChange={handleInputChange} defaultValue={globalData.gzoom} />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="col-3">
                        Ödeme Linki
                    </div>

                    <div className="col-9">
                        <div className="row rounded-3">
                            <div className="col-12" >
                                <textarea class="form-control" rows="1" name="paymentLink" id="paymentLink" placeholder="Ödeme Linki" onChange={handleInputChange} defaultValue={globalData.paymentLink}></textarea>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="col-3">
                        Üyelik KVKK
                    </div>

                    <div className="col-9">
                        <div className="row rounded-3">
                            <div className="col-12" >
                                <textarea class="form-control" rows="1" name="registerKVKK" id="registerKVKK" placeholder=" Üyelik KVKK" onChange={handleInputChange} defaultValue={globalData.registerKVKK}></textarea>
                                <label for="registerKVKKFile" class="form-label"><i aria-hidden="true" title="Dosya Yükle" className="fa fa-upload text-primary" style={{ cursor: "pointer" }}></i></label>
                                <input class="form-control form-control-sm" hidden type="file" id="registerKVKKFile" onChange={(e) => SendFiles(e, "registerKVKKFile")} />
                                <span class="spinner-border  spinner-border-sm" hidden id="spregisterKVKKFile" ></span>
                                <a className="text-decoration-none" href='getgeneralfile?id=registerKVKKFile' download={globalData.registerKVKKFile}> {globalData.registerKVKKFile} </a>


                            </div>
                        </div>
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="col-3">
                        Üyelik Sözleşme
                    </div>

                    <div className="col-9">
                        <div className="row rounded-3">
                            <div className="col-12" >
                                <textarea class="form-control" rows="1" name="registerAgrement" id="registerAgrement" placeholder=" Üyelik Sözleşme" onChange={handleInputChange} defaultValue={globalData.registerAgrement}></textarea>
                                <label for="registerAgrementFile" class="form-label"><i aria-hidden="true" title="Dosya Yükle" className="fa fa-upload text-primary" style={{ cursor: "pointer" }}></i></label>
                                <input class="form-control form-control-sm" hidden type="file" id="registerAgrementFile" onChange={(e) => SendFiles(e, "registerAgrementFile")} />
                                <span class="spinner-border  spinner-border-sm" hidden id="spregisterAgrementFile" ></span>
                                <a className="text-decoration-none" href='getgeneralfile?id=registerAgrementFile' download={globalData.registerAgrementFile}> {globalData.registerAgrementFile} </a>

                            </div>

                        </div>
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="col-3">
                        Başvuru KVKK
                    </div>

                    <div className="col-9">
                        <div className="row rounded-3">
                            <div className="col-12" >
                                <textarea class="form-control" rows="1" name="applyKVKK" id="applyKVKK" placeholder="Başvuru KVKK" onChange={handleInputChange} defaultValue={globalData.applyKVKK}></textarea>
                                <label for="applyKVKKFile" class="form-label"><i aria-hidden="true" title="Dosya Yükle" className="fa fa-upload text-primary" style={{ cursor: "pointer" }}></i></label>
                                <input class="form-control form-control-sm" hidden type="file" id="applyKVKKFile" onChange={(e) => SendFiles(e, "applyKVKKFile")} />
                                <span class="spinner-border  spinner-border-sm" hidden id="spapplyKVKKFile" ></span>
                                <a className="text-decoration-none" href='getgeneralfile?id=applyKVKKFile' download={globalData.applyKVKKFile}> {globalData.applyKVKKFile} </a>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="col-3">
                        Başvuru Sözleşme
                    </div>

                    <div className="col-9">
                        <div className="row rounded-3">
                            <div className="col-12" >
                                <textarea class="form-control" rows="1" name="applyAgrement" id="applyAgrement" placeholder="Başvuru Sözleşme" onChange={handleInputChange} defaultValue={globalData.applyAgrement}></textarea>
                                <label for="applyAgrementFile" class="form-label"><i aria-hidden="true" title="Dosya Yükle" className="fa fa-upload text-primary" style={{ cursor: "pointer" }}></i></label>
                                <input class="form-control form-control-sm" hidden type="file" id="applyAgrementFile" onChange={(e) => SendFiles(e, "applyAgrementFile")} />
                                <span class="spinner-border  spinner-border-sm" hidden id="spapplyAgrementFile" ></span>
                                <a className="text-decoration-none" href='getgeneralfile?id=applyAgrementFile' download={globalData.applyAgrementFile}> {globalData.applyAgrementFile} </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-3">
                        Düzeltme Mesajlarını
                    </div>

                    <div className="col-9">
                        <div className="row rounded-3">
                            <div className="col-12" >
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="showRevisionNoteAfterSend" id="inlineRadio1" value={"false"} onChange={handleInputChange} defaultChecked={globalData.showRevisionNoteAfterSend != true} />
                                    <label class="form-check-label" for="inlineRadio1">Hemen Göster</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="showRevisionNoteAfterSend" id="inlineRadio2" value={"true"} onChange={handleInputChange} defaultChecked={globalData.showRevisionNoteAfterSend == true} />
                                    <label class="form-check-label" for="inlineRadio2">Kontrol Tamamlanınca Göster</label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-12">
                        <button onClick={setGeneralData} className="btn btn-sm btn-primary">Güncelle</button>
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-12">
                    <CommunicationSetting />
                </div>
            </div>


            {(userName == "a" || userName == "admin") && <div>
                <button onClick={fetchduplicate} className="btn btn-sm btn-primary mb-2">Arşivde Duplicate Klasorleri Bul</button>
                <div class="spinner-border spinner-border-sm" hidden id="dupspin"></div>
                <div className="mt-1" style={{ maxHeight: '500px', overflowY: 'auto', border: '1px solid #ccc', padding: '5px' }}>
                    {duplicateList.map(x => <div>{x}</div>)}
                </div>
            </div>}

        </div>
    );


}


