import React, { useState, useEffect } from 'react';
import { useGlobalState, ajaxFail } from '../GlobalState.js';


export default function Statistic(props) {

    const [role, setRole] = useGlobalState('role');
    const [sureslist, setSurecList] = useState([]);

    const drawChart = (dataarray, target, ftitle) => {
        var data = window.google.visualization.arrayToDataTable(dataarray);
        var options = {
            chartArea: {
                // leave room for y-axis labels
                width: '90%'
            },
            height: "300",
            width: '100%',
            title: ftitle,
            legend: { position: "bottom" },
            titleTextStyle: {
                color: '#0098db',
                fontSize: 16
                //bold: true
            },
 is3D: true,


        };

        var chart = new window.google.visualization.PieChart(document.getElementById(target));
        chart.draw(data, options);

    }

    useEffect(() => {
            const script = document.createElement("script");
            script.src = "https://www.gstatic.com/charts/loader.js";
            script.async = true;
            document.getElementById("myscript").appendChild(script);
            script.onload = () => {
                window.google.charts.load("current", { packages: ['corechart'] });
                window.google.charts.setOnLoadCallback(sureclist);
            }
        

    }, [])

    const sureclist = () => {
        fetch("sureclist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                response.json().then(async data => {
                    setSurecList(data);
                    populateProjectData(data);
                });
            }
        });
    }

    const populateProjectData = (sureclist) => {
        fetch("countprojectbytype?ctype=1", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                response.json().then(async data => {
                    var dataset = JSON.parse(data);

                    data = JSON.parse(dataset[0]);
                    var dataarray1 = [];
                    var colors = ['#2E4C6D', '#396EB0', '#DADDFC', '#FC997C', '#66806A', '#7E8BD9', '#558D97', '#B4C6A6', '#FFC286', '#FFF1AF'];
                    dataarray1.push(['Durum', 'Toplam', { role: "style" }]);
                    var cidx = 0;
                    for (var i in data) {
                        dataarray1.push([i, data[i], colors[cidx++]]);
                    }
                    drawChart(dataarray1, 'columnchart_material', 'Başvuru Durumları');




                    data = JSON.parse(dataset[1]);
                    var dataarray2 = [];
                    var colors = ['#2E4C6D', '#396EB0', '#DADDFC', '#FC997C', '#66806A', '#7E8BD9', '#558D97', '#B4C6A6', '#FFC286', '#FFF1AF'];
                    dataarray2.push(['Durum', 'Toplam', { role: "style" }]);
                    cidx = 0;
                    for (var i in data) {
                        dataarray2.push([i, data[i], colors[cidx++]]);
                    }


                    drawChart(dataarray2, 'kullanıcilarpanel', 'Başvuru Tipleri');






                    data = JSON.parse(dataset[2]);
                   /* var dataarray3 = [];
                    var colors = ['#2E4C6D', '#396EB0', '#DADDFC', '#FC997C', '#66806A', '#7E8BD9', '#558D97', '#B4C6A6', '#FFC286', '#FFF1AF'];
                    dataarray3.push(['Durum', 'Toplam', { role: "style" }]);
                    for (var i = 0; i < data.length; i++) {
                        dataarray3.push([data[i].userName, data[i].Toplam, colors[i]]);
                    }
                    drawChart(dataarray3, 'kullanıcilarpanel', 'Görev Durumları')*/
                    if (document.getElementById("aktifbasvuru"))
                        document.getElementById("aktifbasvuru").innerHTML = " Aktif: " + data.Aktif
                    if (document.getElementById("tamamlananbasvuru"))
                        document.getElementById("tamamlananbasvuru").innerHTML = " Tamamlanan: " + data.Tamamlanan
                    if (document.getElementById("toplambasvuru"))
                        document.getElementById("toplambasvuru").innerHTML = " Toplam: " + data.Toplam




                });
            }
        });
    }

    

    //appData.basvuruDurumu != "Kontrol Ediliyor"
    return (
        <div  >
            <div id="myscript"></div>
            <div className="row">
                <div className="col-6 p-0 m-0">
                    <div id="columnchart_material"></div>
                </div>
                <div className="col-6 p-0 m-0">
                    <div id="kullanıcilarpanel"></div>
                </div>
            </div>
        </div>
    );
}





