import React, { useState, useEffect } from 'react';
import { ajaxFail, showConfirmMessage, showSuccessMessage, showErrorMessage, isEmpty } from '../GlobalState.js';


export default function UserTypePanel(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const removeRecord = (id) => {

        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "","Evet","Hayır",()=>

        fetch("removeusertype", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                fetchList();
            }
        }) )
    }



    const submitUpdateRecord = (rec) => {

        fetch("updateusertype", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }
    const fetchList = () => {

        fetch("getusertypelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Add = () => {

        if (isEmpty(document.getElementById("basvuruTuru").value)) {
            showErrorMessage("Lütfen kullanıcı tipi ismini giriniz");
            return;
        }

        if (isEmpty(document.getElementById("sira").value)) {
            showErrorMessage("Lütfen imzacı sırasını giriniz");
            return;
        }  
        var apptype = {
            appType: document.getElementById("basvuruTuru").value,
            gizli: document.getElementById("gizli").checked,
            sira: document.getElementById("sira").value
        }
        fetch("addusertype", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(apptype)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                document.getElementById("basvuruTuru").value = "";
                document.getElementById("sira").value = "";
                document.getElementById("gizli").checked = false;
                fetchList();
            }
        });

    }

    const myCompare = (a, b) => {
        var av = a[sortField];
        var bv = b[sortField];
        var ret = 1;
        if (sortDirection[sortField] == "ZA")
            ret = -1;

        if (av == null && bv != null)
            return -1 * ret;
        if (av != null && bv == null)
            return 1 * ret;
        if (av == null && bv == null)
            return 0;

        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * ret

        if (av < bv) {
            return -1 * ret;
        }
        if (av > bv) {
            return 1 * ret;
        }
        return 0;
    }

    const [sortDirection, setSortDirection] = useState({});
    var sortField = "";
    const sortList = (fildname) => {
        sortField = fildname;
        if (sortDirection[sortField] == null || sortDirection[sortField] == "ZA") {
            sortDirection[sortField] = "AZ";
        }
        else {
            sortDirection[sortField] = "ZA";
        }

        var temp = [...listData]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setListData(temp);
    }


    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive  mainpagescroll" style={{ "height": "85vh" }}>
            <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light">

                        <th className="text-start"><button className="btn btn-sm btn-link text-decoration-none shadow-none" onClick={() => sortList("id")}> No</button></th>
                        <th className="text-start"  ><button className="btn btn-sm btn-link text-decoration-none shadow-none" onClick={() => sortList("appType")}> Kullanıcı Tipi</button></th>
                        <th className="text-start"><button className="btn btn-sm btn-link text-decoration-none shadow-none" onClick={() => sortList("gizli")}> Gizli</button></th>
                        <th className="text-start"><button className="btn btn-sm btn-link text-decoration-none shadow-none" onClick={() => sortList("sira")}> Sıra</button></th>
                        <th className="text-start"><button className="btn btn-sm btn-link text-decoration-none shadow-none" onClick={() => sortList("regDate")}> Kayıt Tarihi</button></th>
                        <th className="text-start"><button className="btn btn-sm btn-link text-decoration-none shadow-none" onClick={() => sortList("userName")}> Ekleyen</button></th>
                            <th className="text-start"></th>
                   
                </thead>
                <tbody id="myTable">
                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            <td>{apprec.id}</td>
                            <td>
                                <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Tipi" name="appType"  defaultValue={apprec.appType} onChange={(e) => updateRecord(apprec, e)} />
                            </td>
                            <td className="text-center">
                                <input type="checkbox" className="form-check-input"  name="gizli" defaultChecked={apprec.gizli} onChange={(e) => updateRecord(apprec, e)} />
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm" placeholder="Sıra" name="sira" defaultValue={apprec.sira} onChange={(e) => updateRecord(apprec, e)} />
                            </td>
                            <td>{apprec.regDate}</td>
                            <td>{apprec.userName}</td>

                            <td>
                                <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                                <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                            </td>
                        </tr>
                    )}
                    <tr className="bg-white">
                        <td></td>
                        <td>
                            <input type="text" className="form-control form-control-sm" id="name" placeholder="Yeni Kullanıcı Tipi" id="basvuruTuru" name="name" defaultValue="" />
                        </td>
                        <td className="text-center">
                            <input type="checkbox" className="form-check-input" name="gizli" id="gizli" defaultChecked={false}  />
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm" placeholder="Sıra" name="sira" id="sira"/>
                            </td>
                        <td></td>
                        <td></td>
                        <td>  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                    </tr>
                </tbody>
            </table>
            </div>
        return (mytable);
    }





    useEffect(() => {
        fetchList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div>

            <h5 className="ps-1">Kullanıcı Tipleri</h5>
            <div className="row">
                <div className="col-12">
                    {listTable}
                </div>
            </div>


           
        </div>

    );


}