import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import SablonMappingPanel from './SablonMappingPanel';
import { ajaxFail, isEmpty, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';


export default function EkranSetting(props) {

    const [listData, setListData] = useState(null);
    const [useroptions, setOptions] = useState([]);
    const [postdata, setPostData] = useState({});

    var options = ['ada', "arsaSahibi", 'arsaSahibiTel', 'basvuruDurumu', "basvuruSahibi", 'basvuruTarihi', 'basvuruTipi',
        'basvuruYapan', "boylam", "enlem", 'id', 'idariMahalle', "kontrolor", "tümKontrolorler", 'mahalle', 'mevki', "müellif", "m2", "no",
        "sokak", "iadeNedeni", "sonDuzeltmeTarihi"
        , "kontrolBaslamaTarihi", "kontrolBitmeTarihi", "kontrolSiraTarihi", "onayVerilmeTarihi",
        , "surecTamamlanmaTarihi", "eimzaAcilmaTrihi", "iadeTarihi", "taslakOlusturmaTarihi", "kabulEdilmeTarihi",
        "sonDuzeltmeTalebiTarihi", "Kontrol Durumu", "müellifKullanıcıAdı", "duzeltmeSayi"];

    options = options.concat(useroptions);
    const colorset = ["#f0f7ff", "#FFFFFF", "#FFFFFF", "#f0f7ff"];

    options = options.sort(function (a, b) {
        if (a == null || b == null)
            return 0;
        return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    var sablonlist = [];

    const multiselectRef = React.createRef();

    const removeSablon = (recid) => {
        showConfirmMessage(recid + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removesablon", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(recid)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    fetchSablonList();
                }
            }));
    }

    const updatesablon = (recid) => {
        fetch("updatesablon", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(recid)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    showSuccessMessage("Güncelleme Başarılı.")
                    //setListData(value);
                })
            }
        });
    }







    const fetchSablonList = () => {
        fetch("sablonlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                    fetchFieldList();
                })
            }
        });
    }


    const fetchFieldList = () => {
        fetch("appfieldlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    var op = [];
                    for (var i = 0; i < value.length; i++)
                        op.push(value[i].name);
                    setOptions(op);

                })
            }
        });
    }
    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }


    const renderTable = (tabledata) => {
        var mytable =
            <div className="row ms-2 me-2 pb-3">
                {tabledata.map((apprec, idx) =>
                    <div className={"col-md-6 col-12  "}>
                        <div className="border rounded-3  mt-3 p-0 text-center border-1" style={{ "background-color": colorset[idx % colorset.length] }}>
                            <div className="d-inline float-start">
                                <button onClick={() => removeSablon(apprec.name)} className="btn btn-secondary btn-sm  text-decoration-none ">Sil</button>
                            </div>
                            <div className="d-inline ">
                                <button onClick={() => setPostData(apprec)} className="btn btn-link btn-sm text-decoration-none ">{apprec.name}</button>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        return (mytable);
    }



    const Add = () => {
        postdata.name = document.getElementById("SablonAdi").value;
        var ft = document.getElementById("filter").value;
        if (document.getElementById("forListView").checked == false && document.getElementById("forTableView").checked == false) {
            showErrorMessage("Lütfen Liste veya Kart görünümünden en az birini seçiniz.", "");
            return;
        }


        postdata.forListView = document.getElementById("forListView").checked;
        postdata.forTableView = document.getElementById("forTableView").checked;
        var sort = document.getElementById("sort").value;
        postdata.sort = sort;
        if (sort.length > 0 && sort.endsWith(","))
            postdata.sort = sort.substring(0, sort.length - 1);

        postdata.filter = ft;
        if (ft.length > 0 && ft.endsWith(","))
            postdata.filter = ft.substring(0, ft.length - 1);
        if (isEmpty(postdata.filter) == false) {
            postdata.filter = postdata.filter.trim();
            if (postdata.filter.toLowerCase().startsWith(","))
                postdata.filter = postdata.filter.substring(1);
            if (postdata.filter.toLowerCase().startsWith("or"))
                postdata.filter = postdata.filter.substring(2);
            if (postdata.filter.toLowerCase().startsWith("and"))
                postdata.filter = postdata.filter.substring(3);
           
        }
              postdata.filter = postdata.filter.trim();

       
        if (isEmpty(postdata.name)) {
            showErrorMessage("Ekran ismi boş olamaz");
            return;
        }
        postdata.colNames = multiselectRef.current.getSelectedItems().join(",");
        if (isEmpty(postdata.colNames)) {
            showErrorMessage("Kolon listesi boş olamaz");
            return;
        }


        fetch("savesablon", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage(postdata.name + " İsimli Ekran Kaydedilmiştir")
                multiselectRef.current.resetSelectedValues();
                document.getElementById("SablonAdi").value = "";
                document.getElementById("filter").value = "";
                fetchSablonList();
                setPostData({})
            }
        });

    }


    const AddFilter = () => {
        //if (document.getElementById('filtervalue').checkValidity() == false) {
        //    alert("Sadece harf veya rakam girilebilir");
        //    return;
        //}    
        var andor = document.getElementById("andor").value;
        if (document.getElementById("andor").disabled == true)
            andor = "";
        var oper = document.getElementById("oper").value;
        var filtervalue = document.getElementById("filtervalue").value;
        var filterColSelect = document.getElementById("filterColSelect").value;
        var myfilter = andor + " " + filterColSelect + " " + oper + " " + filtervalue + ",";
        document.getElementById("filter").value += myfilter;
        document.getElementById("andor").disabled = false;


    }
    const ClearFilter = () => {
        document.getElementById("filter").value = "";
        document.getElementById("andor").disabled = true;
    }
    const clearSort = () => {
        document.getElementById("sort").value = "";
    }

    const AddArtan = () => {
        var filterColSelect = document.getElementById("siraColSelect").value;

        var v = document.getElementById("sort").value;
        if (isEmpty(v) == false && v.endsWith(",") == false)
            v += ","
        v += filterColSelect + ",";
        document.getElementById("sort").value = v;

    }
    const AddAzalan = () => {
        var filterColSelect = document.getElementById("siraColSelect").value;
        var v = document.getElementById("sort").value;
        if (isEmpty(v) == false && v.endsWith(",") == false)
            v += ","
        v += filterColSelect + ",";
        document.getElementById("sort").value = v + "-" + filterColSelect + ",";
    }


    useEffect(() => {
        fetchSablonList();

    }, [])


    const onSelect = (selectedList, selectedItem) => {
        var clist = selectedList.map(rec => "<option value='" + rec + "'>" + rec + "</option>");
        // document.getElementById("filterColSelect").innerHTML = clist;
        document.getElementById("filterColSelect").selectedIndex = -1;
        document.getElementById("siraColSelect").innerHTML = clist;

    }

    const onRemove = (selectedList, removedItem) => {
        var clist = selectedList.map(rec => "<option value='" + rec + "'>" + rec + "</option>");
        // document.getElementById("filterColSelect").innerHTML = clist;
        document.getElementById("filterColSelect").selectedIndex = -1;
        document.getElementById("siraColSelect").innerHTML = clist;
    }

    return (
        <div className="p-2 rounded-3" style={{ "background-color": "#EDEDF7", "min-height": "100vh" }}>
            <div className="row mt-3 p-3 d-flex flex-wrap pe-4" key={postdata.name}>
                <div className="col-12 col-md-6 d-flex flex-column">
                    <div className=" bg-white rounded-3" >
                        <div className="p-3 fw-bold border-bottom mb-3" >
                            Yeni Ekran Oluştur
                        </div>
                        <div className="bg-light m-4  p-3 rounded-3 textvmuted fw-bold align-middle"  >
                            <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                            Bu alanda, Liste Görünümü ve Kart Görünümünde kullanılmak üzere ekranlar oluşturabilirsiniz. Oluşturduğunuz ekranlarda hangi kolonların, hangi filtre ile ve hangi sıralama ile görüntüleneceğini belirleyebilirsiniz.
                        </div>


                        {isEmpty(postdata.name) != true && < div className="row ms-2 me-2   mb-4 pb-4 border-bottom">
                            <div className="col-12 align-middle   text-center " >
                                <span className="text-success fw-bold"> {postdata.name} </span> ekranını düzenliyorsunuz
                                <button onClick={() => {
                                    setPostData({})


                                }} className="ms-2 btn btn-sm btn-secondary text-decoration-none">Vazgeç</button>
                            </div>
                        </div>}


                        <div className="m-4   pt-3  border-top">
                            Ekran Adı <span className="text-danger">*</span>
                            <input type="text" className="form-control form-control-sm " id="SablonAdi" placeholder="" name="Name" defaultValue={postdata.name} />
                            <span className="textvmuted small">Oluşturacağınız ekrana bir isim veriniz</span>
                        </div>

                        <div className="m-4  ">
                            Kolonlar <span className="text-danger">*</span>
                            <Multiselect className=""
                                options={options}
                                emptyRecordMsg="Başka Başvuru Tipi Yok"
                                ref={multiselectRef}
                                isObject={false}
                                onSelect={onSelect} // Function will trigger on select event
                                onRemove={onRemove} // Function will trigger on remove event
                                selectedValues={postdata.colNames != null ? postdata.colNames.split(",") : []}
                                placeholder=""
                            />
                            <span className="textvmuted small">Ekranda hangi kolonların(bilgilerin) görüntüleneceğini seçin</span>
                        </div>


                        <div className="m-4 mb-3">
                            Ekran Tipi <span className="text-danger">*</span>
                            <div className="row" >
                                <div className="col-md-2 col-12">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input big-checkbox me-1" type="checkbox" value="" id="forListView" name="forListView" defaultChecked={postdata.forListView} />
                                        <label class="form-check-label pt-1" for="flexCheckDefault">
                                            List
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-2 col-12">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input big-checkbox me-1" type="checkbox" value="" id="forTableView" name="forTableView" defaultChecked={postdata.forTableView} />
                                        <label class="form-check-label pt-1" for="flexCheckChecked">
                                            Kart
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-8 col-12">
                                    <span className="textvmuted small">Oluşturduğunuz ekranın Liste Görünümü ve Kart Görünümünden hangilerinde yer alacağını seçin</span>
                                </div>
                            </div>
                        </div>

  
                        <div className="row mt-5 m-3 mt-1 border-top ">
                            <div className="col-md-11 col-12">
                                Uygulanacak Filtre
                                <input type="text" className="form-control form-control-sm" id="filter" placeholder="Filtre" name="filter" defaultValue={postdata.filter} />

                            </div>
                            <div className="col-md-1 col-12 text-end">
                                <br/>
                                <button onClick={ClearFilter} className="ms-1 btn btn-sm btn-secondary">Sil</button>

                            </div>

                        </div>



                        <div className="row m-3 mt-1">
                            <div className="col-md-4 col-12 border-top mt-4">
                            </div>
                            <div className="col-md-4 col-12">
                                Filtre Ekleme Yöntemi
                                <select className="form-select form-select-sm " id="andor" name="andor" placeholder="Filtre" disabled={isEmpty(postdata.filter)}>
                                    <option value="AND"> AND </option>
                                    <option value="OR"> OR </option>
                                </select>

                            </div>
                            <div className="col-md-4 col-12 border-top mt-4">
                            </div>

                        </div>



                        <div className="m-3">
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    Filtrelenecek Alan
                                    <select className="form-select form-select-sm " id="filterColSelect" name="filterColSelect">
                                        {options.map(rec => <option value={rec}> {rec} </option>)}
                                    </select>
                                </div>
                                <div className="col-md-2 col-12 ps-0">
                                    Operator
                                    <select className="form-select form-select-sm " id="oper" name="oper" placeholder="Filtre">
                                        <option value="=">{"="} </option>
                                        <option value="!="> {"!="} </option>
                                        <option value=">"> {">"} </option>
                                        <option value="<"> {"<"} </option>
                                        <option value="LIKE"> {"LIKE"} </option>
                                        <option value="NOTLIKE"> {"NOTLIKE"} </option>
                                        <option value="ISNULL"> {"ISNULL"} </option>
                                        <option value="ISNOTNULL"> {"ISNOTNULL"} </option>

                                    </select>
                                </div>
                                <div className="col-md-4 col-12 ps-0">
                                    Filtre Değeri
                                    <input type="text" className="form-control form-control-sm" id="filtervalue" placeholder="Filtre Değeri" name="filter" pattern="[a-zA-Z0-9ÜüŞşÇçÖöĞğİı ]+" />

                                </div>
                                <div className="col-md-2 p-0 col-12 "><br />
                                    <button onClick={AddFilter} className="btn btn-sm btn-primary">Ekle</button>

                                </div>
                            </div>
                        </div>



                        <div className="m-3 mt-5">

                            <div className="row ">
                                <div className="col-md-6 col-12">
                                    Sıralama Uygulanacak  Alan
                                    <select className="form-select form-select-sm" id="siraColSelect" name="siraColSelect" >
                                        {isEmpty(postdata.colNames) == false && postdata.colNames.split(",").map(x => <option value={x} >{x}</option>)}
                                    </select>
                                </div>
                                <div className="col-md-6 col-12 text-end"><br />
                                    <button onClick={AddArtan} className="btn btn-sm btn-primary">Artan</button>
                                    <button onClick={AddAzalan} className="ms-1 btn btn-sm btn-info">Azalan</button>
                                    <button onClick={clearSort} className="ms-1 btn btn-sm btn-secondary">Sil</button>
                                </div>
                            </div>
                            <div className=" mt-1">
                                Uygulanacak Sıralama
                                <input type="text" className="form-control form-control-sm" id="sort" placeholder="Sıralama" name="sort" disabled defaultValue={postdata.sort} />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 text-center pb-2">
                                <button onClick={Add} className="btn  btn-primary m-1">Ekranı Kaydet</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*----------------------------------------------------------------------------------------------------*/}
                <div className="col-12 col-md-6 d-flex flex-column bg-white rounded-3">
                    <div className=" " >
                        <div className="p-3 fw-bold border-bottom" >
                            Oluşturulan Ekranlar
                        </div>
                        <div className="bg-light m-3  p-3 mb-2 rounded-3 textvmuted fw-bold align-middle"  >
                            <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                            Oluşturduğunuz ekranları silebilir veya özelliklerini değiştirebilirsiniz. Ekran isminin üzerine tıkladıktan sonra “Yeni Ekran Oluşturma” alanından özelliklerini düzenleyebilirsiniz.                       </div>
                        <div className="">
                            {listData != null ? renderTable(listData) : ""}
                        </div>

                    </div>
                </div>
                {/*----------------------------------------------------------------------------------------------------*/}
            </div >
            <div className="row p-3 ">
                <div className="col-12 ">
                    <div className="bg-white rounded-3" >
                        <div className="p-3 fw-bold border-bottom" >
                            Ekran Yetkilendirme
                        </div>


                        <div className="mt-1">
                            {listData != null ? <SablonMappingPanel sablons={listData} /> : ""}
                        </div>

                    </div>
                </div>
            </div>

        </div >



    );


}
