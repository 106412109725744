import React, { Component, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import $ from 'jquery';
import Register from "./Register.js";
import { useGlobalState, logout, removeTurkcLow, showErrorMessage, isEmpty } from '../GlobalState.js';
import SideBarComponent from '../Header/SideBarComponent.js';
import UserWorkLoad from './UserWorkLoad.js';
import * as UserPermConstants from '../User/UserPermConstants';
import UserDetails from './UserDetails.js';


export default function UserList(props) {

    const [role, setRole] = useGlobalState('role');
    const [userlist, setUserlist] = useState([]);
    const [denetimPermList, setDenetimPermList] = useState([]);
    const [sortDirection, setSortDirection] = useState({});
    const [roleList, setroleList] = useState([]);
    const [activepannel, setActivepannel] = useState(<div className="w-100 mt-4 mb-4 d-flex align-items-center justify-content-center"  ><div className="spinner-border" id="loadingSpinner"></div></div>);
    useEffect(() => {
        fetchRoles()
    }, [])


    const filterTable = (event) => {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable tr").filter(function () {
            var keywords = value.split(" ");
            for (let index = 0; index < keywords.length; ++index)
                if (removeTurkcLow($(this).text()).indexOf(removeTurkcLow(keywords[index])) == -1)
                    return $(this).toggle(false);
            return $(this).toggle(true);
        });
        document.getElementById("usercount").innerHTML = $("#myTable tr:visible").length + " kullanıcı";
    }

    var sortField = "";

    const myCompare = (a, b) => {
        var av = a[sortField];
        var bv = b[sortField];
        var ret = 1;
        if (sortDirection[sortField] == "ZA")
            ret = -1;

        if (av == null && bv != null)
            return -1 * ret;
        if (av != null && bv == null)
            return 1 * ret;
        if (av == null && bv == null)
            return 0;

        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * ret

        if (av < bv) {
            return -1 * ret;
        }
        if (av > bv) {
            return 1 * ret;
        }
        return 0;
    }

    const sortList = (fildname) => {
        sortField = fildname;
        if (sortDirection[sortField] == null || sortDirection[sortField] == "ZA") {
            sortDirection[sortField] = "AZ";
        }
        else {
            sortDirection[sortField] = "ZA";
        }

        var temp = [...userlist]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setUserlist(temp);
    }



    const fetchRoles = () => {
        fetch("getrolelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText)
                })
            }
            else {
                response.json().then(value => {
                    setroleList(value);
                    getdenetimperms()
                })
            }
        });

    }

    const getdenetimperms = () => {
        fetch("getdenetimperms", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify("")
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText)
                })
            }

            else {
                response.json().then(value => {
                    setDenetimPermList(value);
                    getUserList();
                })

            }
        });
    }

    const deleteUser = (username) => {
        if (window.confirm("Kullanıcıyı Silmek Beklenmeyen Hatalara Sebep Olabilir. Silmek Istediğinzden Emin misiniz?") == false)
            return;
        fetch("deleteuser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(username)
        }).then(async response => {
            if (!response.ok) {
                if (response.status == 401) {
                    logout();
                }
                else showErrorMessage(response.statusText);
            }
            else {
                response.json().then(async data => {
                    setUserlist(data);

                });
            }
        });
    }

    const getUserList = () => {
        fetch("userlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                showErrorMessage(response.statusText);
                if (response.status == 401) {
                    logout();
                }
            }
            else {
                if (document.getElementById("loadingSpinner") != null)
                    document.getElementById("loadingSpinner").hidden = true;
                response.json().then(async data => {
                    setUserlist(data);


                });

            }
        });
    }


    if (userlist != null)
        for (var u of userlist) {
            u.yetkiler = denetimPermList.filter(r => r.creatorUserName == u.userName).map(r => r.name).join();
        }



    const showPanel = (name) => {
        setActivepannel(name);
    }

    const getRoleName = (rec) => {
        if (rec.role == null)
            return "";
        var role = roleList.find(r => r.id == rec.role);
        if (role != null)
            return role.name;
    }

    const filterColumn = (event) => {
        const target = event.target;
        var cl = ["userName", "name", "family", "telefon", "tc", "email", "id", "role", "yetkiler", "edveletConfirmed"]
        $("#myTable tr").filter(function () {
            for (var c = 0; c < cl.length; c++) {
                var value = document.getElementById(cl[c]).value
                if (isEmpty(value) || value == "-")
                    continue;
                var res = true;
                if (value.startsWith("-")) {
                    res = false;
                    value = value.substr(1);
                }
                if (res == true) {
                    if (removeTurkcLow($(this).children().eq(c).text()).indexOf(removeTurkcLow(value)) == -1)
                        return $(this).toggle(false);
                }
                else {
                    if (removeTurkcLow($(this).children().eq(c).text()).indexOf(removeTurkcLow(value)) != -1)
                        return $(this).toggle(false);
                }

            }
            return $(this).toggle(true);
        });
        document.getElementById("usercount").innerHTML = $("#myTable tr:visible").length + " kullanıcı";

    }


    const renderAppTable = (tabledata) => {
        if (tabledata == null) return <div className="w-100 mt-4 mb-4 d-flex align-items-center justify-content-center"  ><div className="spinner-border" id="loadingSpinner"></div></div>;
        var mytable =
            <div>
                <div className="mt-2 p-2  w-100  d-flex align-items-center justify-content-center rounded-2 bg-light">
                    <input className="form-control w-50" type="text" placeholder="Arama..." onKeyUp={filterTable} />
                </div>
                <div className=" w-100  d-flex align-items-center justify-content-center rounded-2 bg-light" id="usercount">
                    {tabledata.length} kullanıcı
                </div>
                <div className="table-responsive mainpagescroll" style={{ "height": "82vh" }}>
                    <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                        <thead className="text-center" style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light">
                            <tr>
                                {/* <th></th>*/}
                                <th>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("userName")}>Kullanıcı Adı </button>
                                    <div>
                                        <input type="text" className="form-control form-control-sm" id="userName" name="userName" onChange={filterColumn} />
                                    </div>
                                </th>
                                <th>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("name")}>Adı </button>
                                    <div>
                                        <input type="text" className="form-control form-control-sm" id="name" name="name" onChange={filterColumn} />
                                    </div>
                                </th>
                                <th>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("family")}>Soyadı </button>
                                    <div>
                                        <input type="text" className="form-control form-control-sm" id="family" name="family" onChange={filterColumn} />
                                    </div>
                                </th>
                                <th>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("tel")}>Telefon </button>
                                    <div>
                                        <input type="text" className="form-control form-control-sm" id="telefon" name="telefon" onChange={filterColumn} />
                                    </div>
                                </th>
                                <th>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("tc")}>TC </button>
                                    <div>
                                        <input type="text" className="form-control form-control-sm" id="tc" name="tc" onChange={filterColumn} />
                                    </div>
                                </th>
                                <th>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("email")}>Email </button>
                                    <div>
                                        <input type="text" className="form-control form-control-sm" id="email" name="email" onChange={filterColumn} />
                                    </div>
                                </th>
                                <th>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("id")}>Kodu </button>
                                    <div>
                                        <input type="text" className="form-control form-control-sm" id="id" name="id" onChange={filterColumn} />
                                    </div>
                                </th>
                                <th>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("role")}>Rol</button>
                                    <div>
                                        <input type="text" className="form-control form-control-sm" id="role" name="role" onChange={filterColumn} />
                                    </div>
                                </th>
                                <th>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("yetkiler")}>Yetkiler </button>
                                    <div>
                                        <input type="text" className="form-control form-control-sm" id="yetkiler" name="yetkiler" onChange={filterColumn} />
                                    </div>
                                </th>
                                <th>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("regDate")}>Kayıt Tarihi </button>
                                    <div>
                                        <input type="text" className="form-control form-control-sm" id="edveletConfirmed" name="edveletConfirmed" onChange={filterColumn} />
                                    </div>
                                </th>

                                <th> </th>
                            </tr>
                        </thead>
                        <tbody id="myTable">
                            {tabledata.map(apprec =>
                                <tr >
                                    {/*<td width="32px"><img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + apprec.userName} /></td>*/}
                                    <td>{apprec.userName}</td>
                                    <td>{apprec.name}</td>
                                    <td>{apprec.family}</td>
                                    <td>{apprec.tel}</td>
                                    <td>{apprec.tc}</td>
                                    <td>{apprec.email}</td>
                                    <td>{apprec.id}</td>
                                    <td>{getRoleName(apprec)}</td>
                                    <td>{apprec.yetkiler}</td>
                                    <td>{apprec.regDate}</td>
                                    <td width="64px">
                                        <a href="#" onClick={() => { props.changeComponent(<UserDetails rec={apprec} changeComponent={props.changeComponent} backButton={<UserList changeComponent={props.changeComponent} />} />) }} className=" text-decoration-none material-icons align-middle" style={{ "font-size": "20px" }}>edit</a>
                                        &nbsp;&nbsp;
                                        {role.indexOf(UserPermConstants.Kullanici_Silme) > -1 ? <a href="/#" onClick={() => { deleteUser(apprec.userName) }} className="text-decoration-none material-icons align-middle" style={{ "font-size": "20px" }}>delete</a> : ""}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        return (mytable);
    }


    if (role.indexOf(UserPermConstants.Kullanici_Listesini_Gorme_Profil_Bilgilerini) === -1) {
        return <Navigate to='/' />
    }

    var leftpanelitems = [
        { name: "Kullanıcılar", target: <span>{renderAppTable(userlist)}</span>, icon: "fa fa-users" },
        { name: "İş Yuku", target: <UserWorkLoad />, icon: "fa fa-bar-chart" }]

    if (role.indexOf(UserPermConstants.Yeni_Kullanici_Kaydetme) > -1) {
        leftpanelitems.push({ name: "Yeni Kullanıcı", target: <Register />, icon: "fa fa-user-plus" });
    }



    return <div >

        <div  >
            {renderAppTable(userlist)}

        </div>
    </div>



}
