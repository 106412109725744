import React, { useEffect} from 'react';
import { Link } from "react-router-dom";
import Home from '../Dashboard/Home.js';
import { useGlobalState,logout } from '../GlobalState.js';
import UserDetails from '../User/UserDetails.js';

function NavBarProfileComponent(props) {
    const [role, setRole] = useGlobalState('role');
    const [username, setUser] = useGlobalState('userName');

    var profilepic = <img src="/getuserpic" id="profileimage" alt="Avatar Logo" style={{ width: "32px" }} className="rounded-pill p-0 m-0" />

    return (
        <div className="d-flex dropdown me-3">
            <Link to="/" className="nav-link p-0 m-0" href="/#" role="button" data-bs-toggle="dropdown">
                <span className="text-decoration-none me-3"> {username}</span>{profilepic}
            </Link>
            <ul className="dropdown-menu dropdown-menu-end shadow border">
                {<li>

                    <a href="#" className="dropdown-item" onClick={() => { props.changeComponent(<UserDetails changeComponent={props.changeComponent} backButton={<Home changeComponent={props.changeComponent} />} />) }}> Profil</a>

                </li>}
               

                <li><a href="/#" onClick={(e) => {
                    //document.cookie.split("; ").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                    //window.location.href = "/";
                    //window.localStorage.setItem('Role', null);
                    logout();
                }} className="dropdown-item" >Çık</a></li>
            </ul>

        </div>

    );
}
export default NavBarProfileComponent;