import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js'
import { ajaxFail, isEmpty, showErrorMessage } from '../GlobalState.js';
import $ from 'jquery';
import Register2 from "./Register2.js";
import * as UserPermConstants from '../User/UserPermConstants';
import { useGlobalState, capitalizeWords } from '../GlobalState.js';

export default function Register1(props) {
    var userdata = props.userdata != null ? props.userdata : {};
    var newuser = props.userdata != null ? false : true;
    const tc = props.tc;
    const ad = props.ad;
    const soyad = props.soyad;

    const [captcha, setCaptcha] = useState("");

    if (isEmpty(userdata.tc) && tc != "direct") {
        userdata.tc = tc;
        userdata.name = ad;
        userdata.family = soyad;
    }

    useEffect(() => {
        if (newuser == true)
            fetchgetcaptcha()
        fetchGeneralData();

    }, [])



    var [globalData, setGlobalData] = useGlobalState('globalData');
    const fetchGeneralData = () => {
        fetch("getgeneraldata", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setGlobalData(data);
                });
            }
        });
    }

    const fetchgetcaptcha = () => {
        fetch("getcaptcha", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.text().then(async data => {
                    setCaptcha(data.replaceAll('"', ""));
                    const script = document.createElement("script");
                    script.src = "https://www.google.com/recaptcha/api.js";
                    script.async = true;
                    script.defer = true;
                    document.getElementById("myscript").appendChild(script);
                });
            }
        });
    }

    $('#Name,#Family').on('keypress', function (event) {
        var regex = new RegExp("^[a-zA-Z \.]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    });


    $('#UserName').on('keypress', function (event) {
        var regex = new RegExp("^[a-zA-Z0-9_\.]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    });

    $('#TC').on('keypress', function (event) {
        var regex = new RegExp("^[0-9]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    });

    const hasNumber = (myString) => {
        return /\d/.test(myString);
    }
    const hasChar = (myString) => {
        return /[a-zA-Z]/g.test(myString);
    }



    const [role, setRole] = useGlobalState('role');
    const zorunlo = role.indexOf(UserPermConstants.Yeni_Kullanici_Kaydinda_Zorunlu_Alanlari_Bos_Birakabilme) == -1;


    const handleInputChange = (event) => {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        //if (target.id == "Password2" || target.id == "Password") {
        //    var password2 = document.getElementById("Password2");
        //    var password = document.getElementById("Password");
        //    var passwordrow = document.getElementById("passwordrow");
        //    var devambutton = document.getElementById("devambutton");
        //    if (password2.value != password.value) {

        //        passwordrow.classList.add("text-danger", "fw-bold");
        //        devambutton.disabled = true;
        //    }
        //    else {
        //        passwordrow.classList.remove("text-danger", "fw-bold");
        //        devambutton.disabled = false;
        //    }
        //}
        if (isEmpty(value) == false)
            value = value.trim();

        userdata[target.name] = value;
    }



    const onSubmitSave = (e) => {
        if (userdata.password == null || userdata.password.length < 8) {
            showErrorMessage("Şifre en az 8 karakter, 1 harf ve 1 rakam içermelidr");
            return;
        }
        if (hasNumber(userdata.password) == false || hasChar(userdata.password) == false) {
            showErrorMessage("Şifre en az 8 karakter, 1 harf ve 1 rakam içermelidr");
            return;
        }
        if (document.getElementById("Password").value != document.getElementById("Password2").value) {
            showErrorMessage("Girilen şifreler aynı değil");
            return;
        }

        e.preventDefault();

        userdata.bildirimTercihleri = "EİmzaSira";  //initlaly all users have this activated
        fetch("updateuser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                props.changeComponent(<Register2 userdata={userdata} changeComponent={props.changeComponent} />)

            }
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        var check1 = document.getElementById("registerAgrement");
        var check2 = document.getElementById("registerKVKK");
        if (check1 != null && check1.checked == false) {
            showErrorMessage("Zorunlu metinler kabul edilmelidir.");
            return;
        }
        if (check2 != null && check2.checked == false) {
            showErrorMessage("Zorunlu metinler kabul edilmelidir.");
            return;
        }


        if (userdata.password == null || userdata.password.length < 8) {
            showErrorMessage("Şifre en az 8 karakter, 1 harf ve 1 rakam içermelidr");
            return;
        }
        if (hasNumber(userdata.password) == false || hasChar(userdata.password) == false) {
            showErrorMessage("Şifre en az 8 karakter, 1 harf ve 1 rakam içermelidr");
            return;
        }
        if (document.getElementById("Password").value != document.getElementById("Password2").value) {
            showErrorMessage("Girilen şifreler aynı değil");
            return;
        }

        userdata.name = capitalizeWords(userdata.name);
        userdata.family = capitalizeWords(userdata.family);

        if (newuser == false) {
            onSubmitSave(e);
            return;
        }

        if (newuser == true && window.grecaptcha.getResponse() == "") {
            showErrorMessage("Ben robot değilim testi başarısız !");
            return false;
        }
        userdata.dynamicData = props.verify;
        userdata.bildirimTercihleri = "EİmzaSira";  //initlaly all users have this activated

        fetch("submituser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userdata)
        }).then(async response => {
            if (!response.ok) {

                response.text().then(async text => {
                    showErrorMessage(text);

                })
            }
            else
                response.json().then(async id => {
                    userdata["id"] = id;
                    userdata["dynamicData"] = "";
                    props.changeComponent(<Register2 userdata={userdata} changeComponent={props.changeComponent} />)

                })
        });
    }






    return <div className="mt-3">
        <div id="myscript"></div>
        <div className="row" >
            <div className="ms-2 me-2 p-0 col-md-3 col-12 text-primary border-primary fw-bold border-bottom border-3 " > 1. Kişisel Bilgiler
            </div>
            <div className="ms-3 me-2 p-0 col-md-3 col-12   border-bottom border-3 " >2. Mesleki Bilgiler
            </div>
            <div className="ms-3 me-2 p-0 col-md-3 col-12   border-bottom border-3 " > 3. Diğer
            </div>
        </div>
        <div className="row m-3" >
            <div className="col-12" >
                <div className=" p-1" >
                    <form action="submitapplication" className="mt-3" method="POST" onSubmit={onSubmit}>

                        <div>
                            <div className="mt-1 row mb-2" >
                                <div className="col-12 col-md-5">
                                    <label className="form-label">TC (şirketlerde yetkilinin TC'si yazılmalıdır) </label>
                                    <input type="text" className="form-control" pattern="[0-9]{1,20}" maxLength="15" id="TC" placeholder="TC" name="tc" onChange={handleInputChange} defaultValue={userdata.tc} disabled={tc != "direct" && role.indexOf(UserPermConstants.Kullanici_Kayit_Formu) == -1} />
                                </div>
                                <div className="col-12 col-md-5">
                                    <label className="form-label">Doğum Tarihi </label>
                                    <input type="date" max="2999-12-31" className="form-control" id="birthdate" placeholder="Doğum Tarihi" name="birthdate" required={zorunlo} onChange={handleInputChange} defaultValue={userdata.birthdate} />
                                </div>

                            </div>

                            <div className="mt-1 row  mb-2" >
                                <div className="col-12 col-md-5">
                                    <label htmlFor="Name" className="form-label m-0">Adı</label>
                                    <input type="text" className="form-control" id="Name" pattern="[a-zA-Z0-9]+$" placeholder="Name" name="name" required={zorunlo} onChange={handleInputChange} defaultValue={userdata.name} disabled={tc != "direct" && role.indexOf(UserPermConstants.Kullanici_Kayit_Formu) == -1} />

                                </div>
                                <div className="col-12 col-md-5">
                                    <label htmlFor="Family" className="form-label m-0">Soyadı</label>
                                    <input type="text" className="form-control" id="Family" pattern="[a-zA-Z0-9]+$" placeholder="Family" name="family" required={zorunlo} onChange={handleInputChange} defaultValue={userdata.family} disabled={tc != "direct" && role.indexOf(UserPermConstants.Kullanici_Kayit_Formu) == -1} />
                                </div>
                            </div>

                            <div className="mt-1 row  mb-2" >
                                <div className="col-12 col-md-5">
                                    <label htmlFor="Tel" className="form-label m-0">GSM No (Başında 0 olmadan)</label>
                                    <input type="text" pattern="[5-5]{1}[0-9]{2}[0-9]{7}" maxLength="10" placeholder="5__ ___ ____" className="form-control" id="Tel" name="tel" required={zorunlo} onChange={handleInputChange} defaultValue={userdata.tel} />

                                </div>
                                <div className="col-12 col-md-5">
                                    <label htmlFor="Tel" className="form-label m-0">Email</label>
                                    <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$" maxLength="50" className="form-control" id="email" placeholder="email" name="email" required={zorunlo} onChange={handleInputChange} defaultValue={userdata.email} />
                                </div>
                            </div>


                            <div className="mt-1 row  mb-2" >
                                <div className="col-12 col-md-5">
                                    <label htmlFor="UserName" className="form-label m-0">Kullanıcı Adı</label>
                                    <input disabled={newuser == false} type="text" className="form-control" id="UserName" maxLength="50" placeholder="" name="userName" onChange={handleInputChange} required defaultValue={userdata.userName} />
                                </div>

                                <div className="col-12 col-md-6 pt-4">
                                    <label className="form-label me-3">İletişim Yöntemi</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name="sendMessage" id="sendMessage" defaultChecked={userdata.sendMessage} onChange={handleInputChange} defaultValue={userdata.sendMessage} />
                                        <label className="form-check-label m-0" for="sendMessage">
                                            Mesajlarım
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name="sendEmail" id="sendEmail" defaultChecked={userdata.sendEmail} onChange={handleInputChange} defaultValue={userdata.sendEmail} />
                                        <label className="form-check-label m-0" for="sendEmail">
                                            Email
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name="sendSMS" id="sendSMS" defaultChecked={userdata.sendSMS} onChange={handleInputChange} defaultValue={userdata.sendSMS} />
                                        <label className="form-check-label m-0" for="sendSMS">
                                            SMS
                                        </label>
                                    </div>

                                </div>





                            </div>

                            <div className=" row  mb-2" id="passwordrow" >
                                <div className="col-12 col-md-5">
                                    Şifre
                                    <input disabled={newuser == false} type="password" maxLength="30" className="form-control" id="Password" placeholder="Şifre en az 8 karakter, 1 harf ve 1 rakam içermelidr" name="password" required onChange={handleInputChange} defaultValue={userdata.password} />
                                </div>
                                <div className="col-12 col-md-5">
                                    Şifre - Tekrar
                                    <input disabled={newuser == false} type="password" maxLength="30" className="form-control" id="Password2" placeholder="Şifre en az 8 karakter, 1 harf ve 1 rakam içermelidr" name="password" required onChange={handleInputChange} defaultValue={userdata.password} />
                                </div>

                            </div>
                            
                                {globalData != null && isEmpty(globalData.registerKVKK) == false && <div className=" row"  >
                                    <div className="col-12 text-start">
                                        <input class="form-check-input" type="checkbox" id="registerKVKK" name="registerKVKK" />
                                        <label class="form-check-label" dangerouslySetInnerHTML={{ __html: globalData.registerKVKK }}></label>
                                    </div>
                            </div>}
                            {globalData != null && isEmpty(globalData.registerKVKKFile) == false && < div className=" row">
                                <div className="col-12  text-start">
                                    <a className="text-decoration-none" href='getgeneralfile?id=registerKVKKFile' download={globalData.registerKVKKFile}> {globalData.registerKVKKFile} </a>

                                </div>
                            </div>
                            }

                                {globalData != null && isEmpty(globalData.registerAgrement) == false && < div className=" row mt-3">
                                    <div className="col-12  text-start">
                                        <input class="form-check-input me-1" type="checkbox" id="registerAgrement" name="registerAgrement" />
                                        <label class="form-check-label" dangerouslySetInnerHTML={{ __html: globalData.registerAgrement }}></label>
                                    </div>
                                </div>}
                                {globalData != null && isEmpty(globalData.registerAgrementFile) == false && < div className=" row">
                                    <div className="col-12  text-start">
                                        <a className="text-decoration-none" href='getgeneralfile?id=registerAgrementFile' download={globalData.registerAgrementFile}> {globalData.registerAgrementFile} </a>

                                    </div>
                                </div>
                                }
                            



                            {/*<div hidden={newuser==false} class="g-recaptcha" data-sitekey="6Lf5yFMlAAAAAAzb7zJD0yiCssN5r238OzYfFq9E"></div>*/}
                            <div className="mt-3 pt-2" hidden={newuser == false} class="g-recaptcha" data-sitekey={captcha}></div>


                            <div className="row mt-3 border-top pt-2" >
                                <div className="col-12 col-md-12 text-end">
                                    <button type="submit" name="actionSave" value="Save" className="btn btn-sm btn-primary mb-2 " id="devambutton">Devam</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    </div >
}



