import React, { useState, useEffect } from 'react';
import SettingUserFile from './SettingUserFile.js';
import { ajaxFail, showConfirmMessage, showSuccessMessage } from '../GlobalState.js';

export default function RegisterSettingPanel(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }
    

    const removeRecord = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removeuserfield", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response)
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));
    }

    

    const submitUpdateRecord = (rec) => {

        fetch("updateuserfield", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }


    

    const fetchList = () => {

        fetch("userfieldlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }



    const Add = () => {
        fetch("adduserfield", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                document.getElementById("name").value = "";
                document.getElementById("type").value = "";
                document.getElementById("reqired").checked = false;
                document.getElementById("active").checked = false;
                fetchList();

            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }


    const renderTable = (tabledata) => {
        var mytable =

            <table className='table table-striped   table-sm' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Soru Adı</th>
                        <th>Soru Türü</th>
                        <th>Varsayılan Değer</th>

                        <th>Zorunlu</th>
                        <th>Aktif</th>
                        <th>Kayıt Tarihi</th>
                        <th>Ekleyen</th>
                        <th>Ayarlar</th>
                    </tr>
                </thead>
                <tbody id="myTable">
                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            <td>{apprec.id}</td>
                            <td>
                                <input type="text" className="form-control form-control-sm" placeholder="Soru Adı" name="name" defaultValue={apprec.name} onChange={(e) => updateRecord(apprec, e)} />
                            </td>
                            <td>
                                <select className="form-select form-select-sm" name="type" placeholder="Soru Türü" defaultValue={apprec.type} onChange={(e) => updateRecord(apprec, e)} >
                                    <option value="text">Yazı</option>
                                    <option value="select">Çoktan Seçmeli</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="number">Sayı</option>
                                </select>
                            </td>
                            <td>
                                <input type="text" className="form-control form-control-sm" placeholder="Varsayılan Değer" name="initValue" defaultValue={apprec.initValue} onChange={(e) => updateRecord(apprec, e)} />
                            </td>
                            <td>
                                <input type="checkbox" className="form-check-input" name="reqired" defaultChecked={apprec.reqired} onChange={(e) => updateRecord(apprec, e)} />
                            </td>
                            <td>
                                <input type="checkbox" className="form-check-input" name="active" defaultChecked={apprec.active} onChange={(e) => updateRecord(apprec, e)} />
                            </td>
                            <td>{apprec.crerateTime}</td>
                            <td>{apprec.userName}</td>

                            <td>
                                <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                                <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                            </td>
                        </tr>
                    )}
                    <tr className="bg-white">
                        <td></td>
                        <td>
                            <input type="text" className="form-control form-control-sm" id="name" placeholder="Soru Adı" name="name" onChange={handleInputChange} defaultValue="" />
                        </td>
                        <td>
                            <select className="form-select form-select-sm" id="type" name="type" placeholder="Soru Türü" defaultValue="" onChange={handleInputChange} >
                                <option value="text">Yazı</option>
                                <option value="select">Çoktan Seçmeli</option>
                                <option value="checkbox">Checkbox</option>
                                <option value="number">Sayı</option>
                            </select>

                        </td>
                        <td>
                            <input type="text" className="form-control form-control-sm" placeholder="Varsayılan Değer" name="initValue" defaultValue="" onChange={handleInputChange} />
                        </td>
                        <td>
                            <input type="checkbox" className="form-check-input" id="reqired" name="reqired" onChange={handleInputChange} />
                        </td>
                        <td>
                            <input type="checkbox" className="form-check-input" id="active" name="active" onChange={handleInputChange} />
                        </td>
                        <td></td>
                        <td></td>
                        <td>  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                    </tr>
                </tbody>
            </table>

        return (mytable);
    }





    useEffect(() => {
        fetchList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className="  rounded mt-2">

            <h5>Kullanıcı Kayıt Formu</h5>
            <div className="row">
                <div className="col-12">
                    {listTable}
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    <SettingUserFile />
                </div>
            </div>
        </div>

    );


}