import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, showConfirmMessage, useGlobalState, sendpost, sendget, isEmpty, showErrorMessage } from '../GlobalState.js';
import * as UserPermConstants from '../User/UserPermConstants';

export default function TarifeMappingList(props) {
    var postdata = {};
    const tarifeMapping = props.tarifeMapping;
    const tarifeCetveli = props.tarifeCetveli;
    var sorular = [];
    const [role, setRole] = useGlobalState('role');

    const [listData, setListData] = useState([]);
    const [mappedTarifeList, setMappedTarifeList] = useState([]);
    var changeComponent = props.changeComponent;
    useEffect(() => {
        fetchList();

    }, [])
    if (tarifeMapping.tarife == null)
        tarifeMapping.tarife = {}

        

    if (tarifeMapping.tarife!=null&& isEmpty(tarifeMapping.tarife.sorular) == false) {
        sorular = JSON.parse(tarifeMapping.tarife.sorular);
        if (sorular.map == null) {
            showErrorMessage("Sorular Listesi Hatalı " + tarifeMapping.tarife.sorular);
            sorular = [];
        }
    }


    const fetchList = () => {
        sendget("tariferowlist?mappingid=" + tarifeMapping.id, "spinner1", (response) => {
            response.json().then(value => {
                /*value.forEach(x => x.cevaplar = JSON.parse(x.data))

                if (isEmpty(tarifeMapping.tarife.formul)==false)
                    for (var t of tarifeCetveli)
                        if (tarifeMapping.tarife.formul.includes(t.name))
                        value.forEach(x => x.cevaplar[t.name] = t.deger);*/
                setListData(value)
            })
        })
    }


    


    const tarifemappingsil = (rec) => {
        showConfirmMessage(rec.id + " numaralı kayıt silinsin mı?", "Eklenen tüm alt kayıtlar da silinecek", "Evet", "Hayır", () => {


            sendpost("removetarifemapping", rec, "spinner1", (response) => {
                response.json().then(value => {
                    props.setMappedTarifeList(value)
                })
            })
        })
    }

        const sil = (rec) => {
            showConfirmMessage(rec.id + " numaralı kayıt silinsin mı?", "", "Evet", "Hayır", () => {


                sendpost("removetariferow", rec, "spinner1", (response) => {
                    response.json().then(value => {
                        setListData(value)
                    })
                })
            })

        }



        const addNewTarifeRow = () => {

            var rec = { mappingid: tarifeMapping.id }
            rec.data = JSON.stringify(postdata);

            sendpost("savetariferow", rec, "spinner1", (response) => {
                response.json().then(value => {
                    if (value.length == 1 && value[0].id < 0) {
                        showErrorMessage(value[0].mapper);
                    }
                    else {
                        setListData(value);
                        var allSelectElements = document.querySelectorAll('select');
                        allSelectElements.forEach(select => {
                            select.selectedIndex = -1;
                        });
                        allSelectElements = document.querySelectorAll('input');
                        allSelectElements.forEach(select => {
                            select.value="";
                        });
                    }
                })
            })


        }

        const handleInputChange = (event) => {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            postdata[target.name] = value.trim();
        }

        var total = 0;
        listData.forEach(x => total +=  Number(x.result));
    props.setTotal("T" + tarifeMapping.id,total)


        return (
            <div className="border  rounded border-light">
                <div className="row">
                    <div className="col-9 fw-bold text-primary">
                        {tarifeMapping.id} - {tarifeMapping.tarife.name}
                        <button hidden={role.indexOf(UserPermConstants.Tarife_Duzenleme) == -1}  className="btn  fw-bold btn-sm shadow-none ms-2 text-warning" type="button" data-bs-toggle="collapse" data-bs-target={"#formul" + tarifeMapping.id} aria-expanded="false" aria-controls={"formul" + tarifeMapping.id}>
                           F
                        </button>
                       

                        
                    </div>
                    <div className="col-1 text-end ">
                        <span class="ms-1 spinner-border spinner-border-sm" id="spinner1" hidden></span>
                    </div>
                    <div className="col-2 text-end ">
                        <button hidden={role.indexOf(UserPermConstants.Tarife_Duzenleme) == -1}  onClick={() => tarifemappingsil(tarifeMapping)} className="btn btn-link btn-sm text-decoration-none text-danger shadow-none ps-0 text-end">
                            <i className="fa fa-trash" style={{ "font-size": "15px" }}></i>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="collapse" id={"formul" + tarifeMapping.id}>
                           ({tarifeMapping.tarife.formul})
                        </div>
                    </div>
                </div>
                <div className="row" hidden={role.indexOf(UserPermConstants.Tarife_Duzenleme) == -1}>
                    {sorular.map(soru => <div className="col-2">
                        {soru.name} {
                            soru.tip == "select" ?
                                <select  className="form-select form-select-sm" onChange={handleInputChange} name={soru.name}>
                                    <option ></option>
                                    {soru.options.split(",").map(x => <option value={x}>{x}</option>)}
                                </select>
                                : <input className="form-control form-control-sm" type={soru.tip} name={soru.name} onChange={handleInputChange}></input>
                        }
                    </div>)}
                    <div className="col-2">
                        <br />
                        <button onClick={addNewTarifeRow} className="btn btn-link btn-sm text-decoration-none  shadow-none ps-0">
                            <i className="fa fa-plus-circle align-middle" style={{ "font-size": "25px" }}></i>
                        </button>
                    </div>
                </div>


                <div className="">
                    {listData.map(apprec => <div className="row mt-2 border-top m-1 pt-1">
                        <div className="col-1 ">
                            {apprec.id}
                          
                        </div>
                        <div className="col-1 ">
                        
                            <button hidden={role.indexOf(UserPermConstants.Tarife_Duzenleme) == -1}  className="btn p-0  fw-bold btn-sm shadow-none ms-2 text-warning" type="button" data-bs-toggle="collapse" data-bs-target={"#formulrow" + apprec.id} aria-expanded="false" aria-controls={"#formulrow" + apprec.id}>
                                F
                            </button>
                        </div>
                        <div className="col-2 ">
                            {apprec.tarih}
                        </div>
                        {/*{Object.keys(apprec.cevaplar).map(key => */}
                        {/*    <div className="col-2">*/}
                        {/*        {key} : {apprec.cevaplar[key] }*/}

                        {/*    </div>*/}
                        {/*) }*/}
                        <div className="col-6">
                            {apprec.data.replaceAll('"', '').replaceAll(",", " , ").replaceAll("{", "").replaceAll("}", "")}

                        </div>
                        <div className="col-2 text-end">
                            {apprec.result}
                            <button onClick={() => sil(apprec)} hidden={ role.indexOf(UserPermConstants.Tarife_Duzenleme) == -1  }
                                className="ms-2 p-0 btn btn-link btn-sm text-decoration-none text-danger shadow-none ps-0 text-end">
                                <i className="fa fa-trash" style={{ "font-size": "15px" }}></i>
                            </button>
                        </div>

                        
                        <div className="col-12 collapse" id={"formulrow" + apprec.id}>
                            {apprec.formul}
                           
                        </div>
                    </div>
                    )}

                </div>
                <div className="text-success text-end fw-bold">
                    Toplam:  {total} TL

                </div>
            </div>


        );
    

}