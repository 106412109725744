import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, isEmpty, sendpost, showErrorMessage, showSuccessMessage, levenshtein, capitalizeWords } from '../GlobalState.js';
import { typeOf } from 'react-is';
import BasvuruTipleriList from './BasvuruTipleriList.js';
import HavaleSablonSetting from './HavaleSablonSetting.js';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
import $ from 'jquery';

export default function BasvuruTipleriDetail(props) {
    var postdata = {};
    var listData = props.basvuruTipleri;
    var appTypeGroups = props.appTypeGroups;
    const multiselectRef = React.createRef();
    const [basvuruTipi, setbasvuruTipi] = useState({ ...props.basvuruTipi });
    const [basvuruTipiAdimlari, setBasvuruTipiAdimlari] = useState(props.basvuruTipiAdimlari.filter(x => x.basvuruTipi == basvuruTipi.id));
    const multiselectRefNew = React.createRef();
    const [showIsAdimlari, setShowIsAdimlari] = useState(false);

    useEffect(() => {
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
        })
        $("#basvuruTipiPaneli").on("click", function (e) {
            const windowElement = document.getElementById('floatingWindow');
            windowElement.style.display = 'none';
        });
    }, [])


    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trim();
        const name = target.name;
        rec[name] = value;
        if ((name == "deadline" || name == "precondition") && value == '')
            rec[name] = null;
    }

    const addBasvuruTipiisAdimi = (item) => {
        var rec = {};
        rec.basvuruTipi = basvuruTipi.id;
        rec.isAdimi = item.id;
        sendpost("savebasvurutipisadimi", rec, "adresspiner", (response) => {
            response.json().then(value => {
                setBasvuruTipiAdimlari(value)
            })
        })
    }

    const removeBasvuruTipiisAdimi = (rec) => {
        var rec2 = basvuruTipiAdimlari.find(b => b.isAdimi == rec.id);
        if (rec2 == null) {
            showErrorMessage("İş Adımı Silinemedi");
            return;
        }
        sendpost("removebasvurutipisadimi", rec2.id, "adresspiner", (response) => {
            response.json().then(value => {
                setBasvuruTipiAdimlari(value)
            })

        })
    }



    const showWindow = (target) => {
        const windowElement = document.getElementById('floatingWindow');
        var inputText = target.value.toLowerCase();
        const matchedItems = props.basvuruTipleri
            .map(item => ({
                item,
                similarity: levenshtein(inputText.toLowerCase(), item.name.toLowerCase())
            }))
            .filter(({ similarity }) => similarity > 0.1) // Only keep items with some similarity
            .sort((a, b) => b.similarity - a.similarity);

        if (matchedItems.length > 0 && matchedItems[0].similarity == 1)
            document.getElementById('kaydetbut').disabled = true;
        else
            document.getElementById('kaydetbut').disabled = false;

        if (matchedItems.length > 0 && inputText !== '') {
            windowElement.innerHTML = "<div class='text-danger border-bottom border-primary '>Daha önce eklediğiniz benzer kayıtlar</div>" +
                matchedItems.map(item =>
                    `<div class='border-bottom fw-bold text-dark' style='opacity:${0.2 + item.similarity}'>
                    <button class='btn btn-link btn-sm text-decoration-none shadow-none' 
                            onclick="window.similarItemSelect(${item.item.id},'${item.item.name}')">
                        ${item.item.name}
                    </button>
                </div>`).join('');
            const rect = target.getBoundingClientRect();
            windowElement.style.left = rect.left + 3 + 'px';
            windowElement.style.top = rect.bottom + 5 + 'px';
            windowElement.style.display = 'block';
        } else {
            windowElement.style.display = 'none';
        }
    }

    window.similarItemSelect = (id, name) => {
        /* showConfirmMessage(name + " kaydının bilgileri yüklensin mı?", "", "Evet", "Hayır", () => {
             document.getElementById('kaudetbut').disabled = false;
             window.changeComponent(<BasvuruTipleriDetail basvuruTipi={props.basvuruTipleri.find(x => x.id == id)} basvuruTipleri={props.basvuruTipleri} />);
         })*/
    }

    const savedenetim = (rec) => {
        var r = rec;

        if (r == null) {
            postdata.hide = showIsAdimlari;
            r = postdata;
        }
        if (isEmpty(rec.name)) {
            showErrorMessage("Başvuru Tipi Adı Boş Olamaz !");
            return;
        }
        rec.name = capitalizeWords(rec.name);
        if (document.getElementById("basvuruTipiName"))
            document.getElementById("basvuruTipiName").value = rec.name;


        fetch("savedenetim", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(r)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                if (rec.id != null) {
                    showSuccessMessage("Başvuru Tipi Güncellendi. ")
                }
                else {
                    showSuccessMessage("Başvuru Tipi Eklendi ", "", () => {
                        response.json().then(value => {
                            setbasvuruTipi(value)
                        })
                    })
                }
                //else {

                //    if (document.getElementById("DenetimAdi")) document.getElementById("DenetimAdi").value = "";
                //    if (document.getElementById("active")) document.getElementById("active").checked = false;
                //    if (document.getElementById("onaysureci")) document.getElementById("onaysureci").checked = false;
                //    if (document.getElementById("ruhsatsureci")) document.getElementById("ruhsatsureci").checked = false;
                //    if (document.getElementById("hide")) document.getElementById("hide").checked = false;
                //    if (document.getElementById("eksiksiz")) document.getElementById("eksiksiz").checked = false;
                //    if (document.getElementById("deadline")) document.getElementById("deadline").value = "";
                //    if (document.getElementById("duzeltmeDeadline")) document.getElementById("duzeltmeDeadline").value = "";
                //    if (document.getElementById("duzeltmeSayi")) document.getElementById("duzeltmeSayi").value = "";
                //    if (document.getElementById("hizliArsiv")) document.getElementById("hizliArsiv").checked = false;
                //    if (document.getElementById("grup")) document.getElementById("grup").selectedIndex = -1;
                //    if (multiselectRefNew.current) multiselectRefNew.current.resetSelectedValues();
                //    postdata = {};

                //}
            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        if (target.type === 'text' && isEmpty(value) == false)
            value = value.trim();

        postdata[target.name] = value;

        if (target.name == "deadline" && value == '')
            postdata[target.name] = null;

    }






    return (
        <div className="p-2 rounded-3" style={{ "background-color": "#EDEDF7", "min-height": "100vh" }} id="basvuruTipiPaneli">
            <div className="row " >
                <div className="col-12 col-md-6 " >

                    <span className="me-3" style={{ "font-size": "14px", "font-weight": "600", "color": "#4b5675" }}> {basvuruTipi.userName} </span>
                    <span className="me-3" style={{ "font-size": "14px", "font-weight": "600", "color": "#99a1b7" }}>{basvuruTipi.crerateTime} </span>
                    <span style={{ "font-size": "14px", "font-weight": "600", "color": "#99a1b7" }}> Id: {basvuruTipi.id} </span>

                </div>
                <div className="col-12 col-md-6 text-end  pt-1" >
                    <button id="kaydetbut" onClick={() => {
                        savedenetim(basvuruTipi)
                    }
                    } className="btn btn-primary btn-sm text-decoration-none me-2" > Kaydet </button>
                    <button onClick={() => window.changeComponent(<BasvuruTipleriList />)} className="btn btn-secondary btn-sm text-decoration-none">Geri Dön</button >
                </div>
            </div>

            <div className="row mt-1 p-3" >
                <div className="col-12 col-md-6" >
                    <div className=" bg-white rounded-3" >
                        <div className="p-2 fw-bold border-bottom" >
                            Genel Özellikler

                        </div>
                        <div className="p-3 pt-2" >
                            Başvuru Tipi <span className="text-danger" >* </span>
                            <input type="text" autocomplete="off" className="form-control form-control-sm" pattern="[a-zA-Z0-9ÜüŞşÇçÖöĞğİı\(\)_\- ]+" placeholder="Başvuru Tipi" name="name" id="basvuruTipiName" key={basvuruTipi.name} defaultValue={basvuruTipi.name} onChange={(e) => { updateRecord(basvuruTipi, e); showWindow(e.target); }} />
                            <div id="floatingWindow" class="floating-window"></div>
                        </div>


                        <div className="p-3 pt-1" >
                            Başvuru Grubu
                            <select className="form-select form-select-sm" name="grup" required={true} onChange={(e) => updateRecord(basvuruTipi, e)}>
                                <option></option>
                                {appTypeGroups.map(x => <option value={x.id} selected={x.id == basvuruTipi.grup}>{x.name}</option>)}
                            </select>
                        </div>




                        <div className="p-3 pt-1 row" >
                            <div class="col-4" >
                                <label class="form-check-label" for="inlineCheckbox1" >  Aktif </label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="active" defaultChecked={basvuruTipi.active} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-7  textvmuted small" style={{ "text-align": "justify" }}>
                                <button type="button" className="btn btn-secondary bg-secondary btn-sm fw-bold pt-0 pb-0 mt-0 "
                                    id="mypopover1" data-bs-toggle="popover" data-bs-trigger="focus"
                                    title="AÇIKLAMA" data-bs-content=" Başvurunun yayında olup olmayacagini belirler.">?</button>


                            </div>
                        </div>

                        <div className="p-3 pt-1 row" >
                            <div class="col-4" >
                                <label class="form-check-label" for="inlineCheckbox1" > Gizli </label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="hide" defaultChecked={basvuruTipi.hide} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-7  textvmuted" style={{ "text-align": "justify" }}>
                                <button type="button" className="btn btn-secondary bg-secondary btn-sm fw-bold pt-0 pb-0 mt-0 "
                                    id="mypopover0" data-bs-toggle="popover" data-bs-trigger="focus"
                                    title="AÇIKLAMA" data-bs-content="Başvuru Tipini Müelliflerden gizler">
                                    ?</button>
                            </div>
                        </div>
                        <div className="p-3 pt-1 row" >
                            <div class="col-4" >
                                <label class="form-check-label" for="inlineCheckbox1" > Zorunlu Dosya Kontrol </label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="eksiksiz" defaultChecked={basvuruTipi.eksiksiz} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-7  textvmuted small" style={{ "text-align": "justify" }}>
                                <button type="button" className="btn btn-secondary bg-secondary btn-sm fw-bold pt-0 pb-0 mt-0 "
                                    id="mypopover2" data-bs-toggle="popover" data-bs-trigger="focus"
                                    title="AÇIKLAMA" data-bs-content="Bu başvuruda zorunlu olarak isaretlediğiniz dosyaların yüklenmeden başvurunun gönderilmesini engeller.">
                                    ?</button>
                            </div>
                        </div>

                        <div className="p-3 pt-1 row" >
                            <div class="col-4" >
                                <label class="form-check-label" for="inlineCheckbox1" > Hızlı Arşiv </label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="hizliArsiv" defaultChecked={basvuruTipi.hizliArsiv} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-7  textvmuted  small" style={{ "text-align": "justify" }}>
                                <button type="button" className="btn btn-secondary bg-secondary btn-sm fw-bold pt-0 pb-0 mt-0"
                                    id="mypopover3" data-bs-toggle="popover" data-bs-trigger="focus"
                                    title="AÇIKLAMA" data-bs-content="Bu başvuruda zorunlu olarak isaretlediğiniz dosyaların yüklenmeden başvurunun gönderilmesini engeller.">
                                    ?</button>
                            </div>
                        </div>

                        <div className="p-3 pt-1 row" >
                            <div class="col-4" >
                                <label class="form-check-label" for="inlineCheckbox1" >Kabul Süreci </label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="kabulSureci" defaultChecked={basvuruTipi.kabulSureci} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-7  textvmuted  small" style={{ "text-align": "justify" }}>
                                <button type="button" className="btn btn-secondary bg-secondary btn-sm fw-bold pt-0 pb-0 mt-0"
                                    id="mypopover6" data-bs-toggle="popover" data-bs-trigger="focus"
                                    title="AÇIKLAMA" data-bs-content="Bu başvuruda kabul süreci otomatik olarak geçilerek başvuru yapıldığı anda havale sürecine düşer. İşaretli olursa normal süreç, işaret konmazsa otomatik olarak havale aşamasına düşecek">
                                    ?</button>
                            </div>
                        </div>





                        <div className="p-3 pt-1 row" >
                            <div class="col-4" >
                                <label class="form-check-label" for="inlineCheckbox1" >  Onay Süreci </label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="onaysureci" defaultChecked={basvuruTipi.onaysureci} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-7  textvmuted small" style={{ "text-align": "justify" }}>
                                <button type="button" className="btn btn-secondary bg-secondary btn-sm fw-bold pt-0 pb-0 mt-0"
                                    id="mypopover4" data-bs-toggle="popover" data-bs-trigger="focus"
                                    title="AÇIKLAMA" data-bs-content="Başvurunun kontrolü tamamlandıktan sonra yöneticinin onayına sunulmasını sağlar. Bunun için yöneticinin rolüne Kullanıcı Yönetimi /Kullanıcı Rolleri ekranından Onay yetkisi verilmelidir. ">
                                    ?</button>

                            </div>
                        </div>


                        <div className="p-3 pt-1 row" >
                            <div class="col-4" >
                                <label class="form-check-label" for="inlineCheckbox1" >  Ruhsat Süreci </label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="ruhsatsureci" defaultChecked={basvuruTipi.ruhsatsureci} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-7 textvmuted small" style={{ "text-align": "justify" }}>
                                <button type="button" className="btn btn-secondary bg-secondary btn-sm fw-bold pt-0 pb-0 mt-0"
                                    id="mypopover5" data-bs-toggle="popover" data-bs-trigger="focus"
                                    title="AÇIKLAMA" data-bs-content=" Başvurunun ruhsat aşamasının olup olmayacağını belirler.">
                                    ?</button>

                            </div>
                        </div>
                    </div>
                </div>





                <div className="col-12 col-md-6" >
                    <div className="bg-white rounded-3" >
                        <div className="p-2 fw-bold border-bottom" >
                            Süreler ve Kıstaslar

                        </div>
                        <div className="row p-3 pt-2" >
                            <div className="col-4" >

                                Düzeltme Süre <button type="button" className="btn btn-secondary bg-secondary btn-sm fw-bold pt-0 pb-0 mt-0 ms-3 mb-1"
                                    id="mypopover6" data-bs-toggle="popover" data-bs-trigger="focus"
                                    title="AÇIKLAMA" data-bs-content="Kontrolörlerin istediği düzeltmeler için müellife verilen süredir. Bu süre zarfında düzeltme yapılıpmaz ise  düzeltmeyi gönder butonuo pasif olur ve başvuru İade Listesi ne düşer. Kurum içindeki yetkili kullanıcı kendisi isterse düzeltmeyi gönder butonuna basarak başvuruyu müellif adına belediyeye gönderebilir.">
                                    ?</button>
                                <input type="number" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} placeholder="" name="duzeltmeDeadline" onChange={(e) => updateRecord(basvuruTipi, e)} defaultValue={basvuruTipi.duzeltmeDeadline} />
                            </div>
                            < div className="col-4" >
                                Düzeltme Sayı<button type="button" className="btn btn-secondary bg-secondary btn-sm fw-bold pt-0 pb-0 mt-0 ms-3 mb-1"
                                    id="mypopover7" data-bs-toggle="popover" data-bs-trigger="focus"
                                    title="AÇIKLAMA" data-bs-content=" Kontrolörlerin isteyeceği düzeltmelere bir sayı sınırı koyar. Bu sınır geçtiğinde düzeltmeyi gönder butonu pasif olur ve başvuru İade Listesi ne düşer. Kurum içindeki yetkili kullanıcı kendisi isterse düzeltmeyi gönder butonuna basarak başvuruyu müellif adına belediyeye gönderebilir.">
                                    ?</button>
                                <input type="number" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} placeholder="" name="duzeltmeSayi" onChange={(e) => updateRecord(basvuruTipi, e)} defaultValue={basvuruTipi.duzeltmeSayi} />
                            </div>




                            <div className="col-4" >
                                Kontrol Süre <button type="button" className="btn btn-secondary bg-secondary btn-sm fw-bold pt-0 pb-0 mt-0 ms-3 mb-1"
                                    id="mypopover8" data-bs-toggle="popover" data-bs-trigger="focus"
                                    title="AÇIKLAMA" data-bs-content="Kontrollerini tamamlamaları için Kontrolörlere verilen süredir. Bu sürede kontrol tamamlanmazsa geciken başvurular bölümüne düşer. Verilen süre kontrolör ekranında, yetkisi olan kullanıcılara geri sayım olarak görünür. Belirtilen süre İŞ GÜNÜDÜR. Hafta sonları dahil değildir.">
                                    ?</button>
                                <input type="number" className="form-control form-control-sm  " placeholder="" name="deadline" onChange={(e) => updateRecord(basvuruTipi, e)} defaultValue={basvuruTipi.deadline} />

                            </div>
                        </div>
                        <div className="row mt-2 border-top pt-3 ps-2" >
                            <div class="col-2" >

                            </div>
                            <div class="col-1 text-primary" >
                                Aktif
                            </div>
                            <div class="col-1 text-primary" >
                                Zorunlu
                            </div>
                            <div class="col-3" >
                            </div>
                            <div class="col-2" >

                            </div>
                            <div class="col-1 text-primary" >
                                Aktif
                            </div>
                            <div class="col-1 text-primary" >
                                Zorunlu
                            </div>
                        </div>


                        <div className="row ps-3" >
                            <div class="col-2" >
                                Mevki
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="mevkiAktif" defaultChecked={basvuruTipi.mevkiAktif} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="mevkiZorunlu" defaultChecked={basvuruTipi.mevkiZorunlu} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-3" >
                            </div>
                            <div class="col-2" >
                                Mahalle
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="mahalleAktif" defaultChecked={basvuruTipi.mahalleAktif} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="mahalleZorunlu" defaultChecked={basvuruTipi.mahalleZorunlu} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                        </div>
                        <div className="row ps-3 mt-2" >
                            <div class="col-2" >
                                Ada/Parsel
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="adaAktif" defaultChecked={basvuruTipi.adaAktif} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="adaZorunlu" defaultChecked={basvuruTipi.adaZorunlu} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-3" >
                            </div>
                            <div class="col-2" >
                                Sokak
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="sokakAktif" defaultChecked={basvuruTipi.sokakAktif} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="sokakZorunlu" defaultChecked={basvuruTipi.sokakZorunlu} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                        </div>
                        <div className="row ps-3 mt-2" >
                            <div class="col-2" >
                                No
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="noAktif" defaultChecked={basvuruTipi.noAktif} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="noZorunlu" defaultChecked={basvuruTipi.noZorunlu} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-3" >
                            </div>
                            <div class="col-2" >
                                Müellif
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="muellifAktif" defaultChecked={basvuruTipi.muellifAktif} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="muellifZorunlu" defaultChecked={basvuruTipi.muellifZorunlu} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                        </div>
                        <div className="row ps-3 mt-2" >
                            <div class="col-2" >
                                Idari Mahalle
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="idariMahalleAktif" defaultChecked={basvuruTipi.idariMahalleAktif} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="idariMahalleZorunlu" defaultChecked={basvuruTipi.idariMahalleZorunlu} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>

                            <div class="col-3" >
                            </div>
                            <div class="col-2" >
                                Taşınmaz Sahibi
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="tasinamazSahibiAktif" defaultChecked={basvuruTipi.tasinamazSahibiAktif} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="tasinamazSahibiZorunlu" defaultChecked={basvuruTipi.tasinamazSahibiZorunlu} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                        </div>




                        < div className="p-3 pt-3 mt-3 border-top" >
                            <div style={{ "text-align": "justify" }}> Koşul
                                <button type="button" className="btn btn-secondary bg-secondary btn-sm fw-bold pt-0 pb-0 mt-0 ms-2"
                                    id="mypopover8" data-bs-toggle="popover" data-bs-trigger="focus"
                                    title="AÇIKLAMA" data-bs-content="Başvurunun yapılabilmesi için seçilecek diğer başvuruların sürecinin tamamlanmasını gerektirir.">
                                    ?</button>
                                <Multiselect className="p-0 ps-1 mt-1 form-control-sm"
                                    options={listData.filter(x => x.isadimi != true)}
                                    emptyRecordMsg="Başka Kayıt Yok"
                                    ref={multiselectRef}
                                    displayValue="name"
                                    isObject={true}
                                    onSelect={(selectedList, removedItem) => basvuruTipi["precondition"] = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join()} // Function will trigger on select event
                                    onRemove={(selectedList, removedItem) => basvuruTipi["precondition"] = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join()} // Function will trigger on remove event
                                    selectedValues={basvuruTipi.precondition == null ? [] : listData.filter(x => x.isadimi != true && basvuruTipi.precondition.split(",").includes(x.id + ""))}
                                />

                            </div>
                        </div>



                    </div>

                    <div className="bg-white rounded-3 mt-3" >
                        <div className="p-2 fw-bold border-bottom" >
                            İş Adımları
                            <span className="spinner-border spinner-border-sm ms-3" id="adresspiner" hidden>   </span>
                        </div>
                        {basvuruTipi.id == null && <div className="text-muted ps-2">İş Adımlarını seçmek için önce Başvuru Tipini Kaydediniz </div>}


                        < div className="p-2" >
                            <Multiselect className="p-0 ps-1 form-control-sm"
                                options={listData.filter(x => x.isadimi == true)}
                                emptyRecordMsg="Başka Kayıt Yok"
                                ref={multiselectRef}

                                displayValue="name"
                                disable={basvuruTipi.id == null}
                                isObject={true}
                                onSelect={(selectedList, item) => addBasvuruTipiisAdimi(item)} // Function will trigger on select event
                                onRemove={(selectedList, item) => removeBasvuruTipiisAdimi(item)} // Function will trigger on remove event
                                selectedValues={listData.filter(x => x.isadimi == true && basvuruTipiAdimlari.some(b => b.isAdimi == x.id))}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {basvuruTipi.id != null ? <HavaleSablonSetting disabled basvuruTipi={basvuruTipi} isAdimlari={basvuruTipiAdimlari} key={basvuruTipiAdimlari} /> : <span className="text-muted p-3">Havale Şablonları eklemek için önce Başvuru Tipini Kaydediniz </span>}

        </div>

    );
}