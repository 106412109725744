import React, { useState, useEffect } from 'react';
import Select from "react-select";
import axios from "axios";
import { ajaxFail, showErrorMessage } from '../GlobalState.js';

export default function UserUploadFilePanel(props) {

    const [files, setFiles] = useState([]);
    const [options, setOptions] = useState([]);
    var userType = props.userdata.userType;


    useEffect(() => {
        userfilenamelist();
    }, [])

    const handleTypeChange = (event) => {
        var e = document.getElementById("myfiletypeselect");

        document.getElementById("formFile").accept = "";
        for (var i = 0; i < options.length; i++)
            if (options[i].TypeID == event.value && options[i].fileType != null && options[i].fileType.length > 0)
                document.getElementById("formFile").accept = options[i].fileType

    }


    const userfilenamelist = () => {

        fetch("userfilenamelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setOptions(value);

                })
            }
        });
    }



    const getOptions = () => {
        var o = [];
        if (options == null)
            return o;

        for (var i = 0; i < options.length; i++) {
            if (options[i].userType != null) {
                if (userType == null)
                    continue;
                if (options[i].userType.split(',').some(t => userType.includes(t.trim())) == false)
                    continue;
            }
            o.push(<option value={options[i].id} tag={i}>{options[i].name + (options[i].zorunlu == true ? " *" : "")}</option>)
        }
        return o;
    }

        const SendFiles = (selectedFiles) => {
            var uploadspinner = document.getElementById("uploadspinner"); //this element is in parent component
            if (uploadspinner != null)
                uploadspinner.hidden = false;

            for (let i = 0; i < selectedFiles.length; i++) {
                var formData = new FormData();
                formData.append("file", selectedFiles[i].file);
                // formData.append("caption", selectedFiles[i].file.name);
                var extns = "";
                var idxx = selectedFiles[i].file.name.lastIndexOf(".");
                if (idxx >= 0)
                    extns = selectedFiles[i].file.name.substr(idxx);
                formData.append("caption", options.find(x => x.id == selectedFiles[i].TypeID).name + extns);
                formData.append("typeID", selectedFiles[i].TypeID);
                formData.append("userName", props.userdata.userName);
                axios.post(
                    "submituserfile",
                    formData,
                    {
                        headers: {
                            //"Authorization": "YOUR_API_AUTHORIZATION_KEY_SHOULD_GOES_HERE_IF_HAVE",
                            "Content-type": "multipart/form-data",
                        },
                    }
                ).then(res => {
                    if (uploadspinner != null)
                        uploadspinner.hidden = true;
                    document.getElementById("myfiletypeselect").selectedIndex = -1;
                    props.refreshFiles();

                })
                    .catch(err => { showErrorMessage(err); })
            }
        }

        const handleSelectedFile = (event) => {
            if (event.target.files[0] != null) {
                if (event.target.files[0].size > 20971520) {
                    showErrorMessage("dosya boyu en fazla 20 MB olabilir");
                    event.target.value = "";
                    return;
                }
                SendFiles([{ file: event.target.files[0], TypeID: document.getElementById("myfiletypeselect").value }]);
            }
        }
      

        return (
            <div >
                <div className=" p-1 mb-2" >
                    <div className="row pt-2 pb-2 ">
                        <div className="col-11">

                            <label for="formFile" class="form-label">Dosya Yukle</label>
                            <br />
                            <select id="myfiletypeselect" className="mb-1 form-select form-select-sm" placeholder="Dosya Türü" name="filenames"
                                onChange={(e) => handleTypeChange(e)}
                            >
                                {getOptions()}
                            </select>
                            <input class="form-control form-control-sm" type="file" id="formFile" onChange={(e) => handleSelectedFile(e)} />
                        </div>
                        <div className="col-1 text-center" id="uploadspinner" hidden>
                            <div className="spinner-border  spinner-border-sm" ></div>
                        </div>
                    </div>
                    {/*<div className="row ">*/}
                    {/*    <div className="col-12 col-md-6">{renderAppTable()}</div>*/}
                    {/*</div>*/}
                </div >
            </div >
        );


    }
