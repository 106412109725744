import React, { useState, useEffect } from 'react';
import { ajaxFail, showConfirmMessage, showSuccessMessage, showErrorMessage } from '../GlobalState.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";

export default function DuyuruSetting(props) {
    var postdata = "";
    const [listData, setListData] = useState(null);

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const removeRecord = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removeduyuru", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    fetchList();
                }
            }));
    }



    const submitUpdateRecord = (rec) => {

        fetch("updateduyuru", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }
    const fetchList = () => {

        fetch("getduyurulist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Add = () => {
        fetch("addduyuru", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                fetchList();
            }
        });

    }


    const SendFiles = (event, selectedRecord) => {
        document.getElementById("fileuploadsp").hidden = false;
        var selectedFile = { file: event.target.files[0] }
        var formData = new FormData();
        formData.append("file", selectedFile.file);
        formData.append("caption", selectedFile.file.name);
        formData.append("appid", selectedRecord.id);
        axios.post(
            "submitduyurufile",
            formData,
            {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            }
        ).then(res => {
            document.getElementById("fileuploadsp").hidden = true;
           
            setListData(res.data);
        })
            .catch(err => { showErrorMessage(err); })
    }


    const renderTable = (tabledata) => {
        var mytable = <div>
            <div className="row ms-1">

                <div className="col-11 border border-secondary rounded-3 p-2" style={{ "resize": "vertical", "overflow-y": "scroll", "height": "120px" }}>
                    <ReactQuill className="h-100" theme="snow" value="" onChange={(e) => postdata = e} />
                </div>
                <div className="col-1">  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">
                    <i class="fa fa-plus-circle" aria-hidden="true" style={{ fontSize:"20px" }}></i>
                </button></div>
            </div>
            <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th width="50px">No</th>
                        <th >Duyuru</th>
                        <th width="190px">Kayıt Tarihi</th>
                        <th width="130px">Ekleyen</th>
                        <th width="130px">Dosya</th>
                        <th width="100px"> <span class="spinner-border  spinner-border-sm" hidden id="fileuploadsp" ></span></th>
                    </tr>
                </thead>
                <tbody id="myTable">
                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            <td className="text-center">{apprec.id}</td>
                            <td style={{ "resize": "vertical", "overflow-y": "scroll", "height": "120px" }} className="p-2">


                                <ReactQuill className="h-100 bg-white" theme="snow" onChange={(e) => apprec.message = e} defaultValue={apprec.message} />

                                {/*  <input type="text" className="form-control form-control-sm" placeholder="Başvuru Turu" name="message" defaultValue={apprec.message} onChange={(e) => updateRecord(apprec, e)} />*/}

                            </td>
                            <td>{apprec.postDate}</td>
                            <td>{apprec.userName}</td>
                            <td>
                                <a className="text-decoration-none" href={'getduyurufile?id=' + apprec.id} download={apprec.fileName}> {apprec.fileName} </a>
                            </td>
                            <td>
                                <label for={"formFile" + apprec.id}  class="form-label"><i aria-hidden="true" title="Dosya Yükle" className="fa fa-upload text-primary" style={{ cursor: "pointer" } }></i></label>
                                <input class="form-control form-control-sm mt-2" hidden type="file" id={"formFile" + apprec.id}  onChange={(e) => SendFiles(e, apprec)} />&nbsp;

                                <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0"><i class="fa fa-floppy-o" aria-hidden="true" title="Güncelle"></i></button>&nbsp;
                                <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0"><i class="fa fa-trash" title="Sil"></i></button>
                               
                            </td>
                           
                        </tr>
                    )}

                </tbody>
            </table>
        </div>
        return (mytable);
    }





    useEffect(() => {
        fetchList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className="  rounded ">

            <h5 className="ps-1">Duyurular</h5>
            
                    {listTable}
             
        </div>

    );


}