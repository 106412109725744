import React, { useState, useEffect } from 'react';
import { isEmpty, showSuccessMessage, useGlobalState } from '../GlobalState.js';
import $ from 'jquery';
import background from "../Dashboard/loading.gif";
import EImzaDetails from '../Eimza/EImzaDetails';
import * as UserPermConstants from '../User/UserPermConstants';
import EImzaList from '../Eimza/EImzaList.js';

export default function PDFView(props) {
    const [role, setRole] = useGlobalState('role');
    const [e, setEimza] = useState(props.Eimza);
    const [numPages, setNumPages] = useState(null);
    const [draggedObjectList, setDraggedObjectList] = useState({});

    const [pageNumber, setPageNumber] = useState(1);
    var zoom = 0;

    if (isEmpty(e.detectedPos) == false)
        e.detectedPosObject = JSON.parse(e.detectedPos)

    useEffect(() => {
        placeObjectsOnPic();
    }, [])


    const updateWidthHeight = (id) => {
        var obj = document.getElementById(id);
        if (obj == null)
            return;

        var border = Math.abs(obj.clientWidth - obj.offsetWidth);

        if (id.startsWith("DGID")) {
            var myid = id.substring(4);
            var textobj = e.detectedPosObject.find(x => x.text == myid);
            if (textobj != null) {
                textobj.width = obj.clientWidth + border;
                textobj.height = obj.clientHeight + (2 * border)
            }
            return;
        }


        if (draggedObjectList[id] != null) {
            draggedObjectList[id].width = obj.clientWidth + border;
            draggedObjectList[id].height = obj.clientHeight + (2 * border)
        }
    }



    const submitEimzaVisualSetting = () => {
        updateWidthHeight("DGformaltext")
        updateWidthHeight("DGqrcode")
        updateWidthHeight("DGID#ruhsatno")
        updateWidthHeight("DGID#kot")
        updateWidthHeight("DGID#fikra")
        updateWidthHeight("DGID#bend")
        updateWidthHeight("DGID#madde")


        fetch("submitEimzaVisualSetting", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "eimzaid": e.id, "zoom": zoom, "draggedObjectList": draggedObjectList, detectedPosObject: e.detectedPosObject })

        }).then(async response => {
            if (!response.ok) {
                // ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    if (props.source == "NEW_TASK") {
                        props.setActivePanel(<EImzaList appData={props.appData} setActivePanel={props.setActivePanel} changeComponent={props.changeComponent}/>)
                        showSuccessMessage("Yerleştirme işlemi tamamlandı. Sayfanın altında bulunan alanda imza sürecini başlatarak evrakınızı imzalayabilirsiniz.");
                        return;
                    }
                    setDraggedObjectList({ ...draggedObjectList })
                    setEimza(data);
                    showSuccessMessage("Yerleştirme başarıyla tamamlandı");
                });


            }
        });
    }




    const drag = (event) => {
        var id = event.target.id;
        var rect = event.target.getBoundingClientRect();
        var grabX = event.clientX - rect.left;
        var  grabY = event.clientY - rect.top;


        event.dataTransfer.setData("text", id);
        event.dataTransfer.setData("grabX", grabX);
        event.dataTransfer.setData("grabY", grabY);
    }
    const allowDrop = (event) => {
        event.preventDefault();
    }






    const drop = (event) => {
        event.preventDefault();
        var dragid = event.dataTransfer.getData("text");
        if (dragid == null || dragid.startsWith("DG") == false)
            return;

        var grabX = event.dataTransfer.getData("grabX");
        var grabY = event.dataTransfer.getData("grabY");


        var DraggedObject = document.getElementById(dragid);
        var div = DraggedObject.parentElement;
        var droppableArea = document.getElementById("pdfimage");
        var droppableDiv = droppableArea.parentElement;


        var activepaneldiv = document.getElementById("activepaneldiv");;
        var scleft = activepaneldiv.scrollLeft + droppableDiv.scrollLeft;
        var sctop = activepaneldiv.scrollTop + droppableDiv.scrollTop;



        div.style.position = "absolute";

        var dragWidth = DraggedObject.clientWidth
        var dragHeight = DraggedObject.clientHeight



        var x = event.clientX - droppableDiv.offsetLeft - grabX + scleft;
        var y = event.clientY - droppableDiv.offsetTop - grabY + sctop;
        if (x < 0) x = 0;
        if (y < 0) y = 0;
        //if (x > droppableDiv.clientWidth - dragWidth)
        //    x = droppableDiv.clientWidth - dragWidth;
        //if (y > droppableDiv.clientWidth - dragWidth)
        //    y = droppableDiv.clientWidth - dragHeight;


        div.style.left = x + "px";
        div.style.top = y + "px";
        div.style.zIndex = 2;
        var fontsize = document.getElementById("fontsize").value;
        droppableDiv.appendChild(div);
        if (dragid.startsWith("DGID")) {
            var id = dragid.substring(4);
            if (e.detectedPosObject == null)
                e.detectedPosObject = [];

            var obj = e.detectedPosObject.find(x => x.text == id)
            if (obj != null) {
                obj.x = x;
                obj.y = y;
                obj.width = dragWidth;
                obj.height = dragHeight;
                obj.font = fontsize;
            }
            else {
                e.detectedPosObject.push({ text: id, x: x, y: y, width: dragWidth, height: dragHeight, font: fontsize });
            }
        }
        else
            draggedObjectList[dragid] = { x: x, y: y, width: dragWidth, height: dragHeight, font: fontsize };

    }

    const dragleave = (event) => {
        event.preventDefault();

    }
    const enter = (event) => {
        event.preventDefault();

    }


    const zoomin = () => {
        var myImg = document.getElementById("pdfimage");
        var currWidth = myImg.clientWidth;
        zoom += 1;
        myImg.style.width = (currWidth + 5000) + "px";

    }

    const zoomout = () => {
        var myImg = document.getElementById("pdfimage");
        var currWidth = myImg.clientWidth;
        if (zoom == 0) return false;
        else {
            myImg.style.width = (currWidth - 5000) + "px";
            zoom -= 1;
        }
    }


    const updateBoxfont = (id, fontsize, source) => {
        var item = document.getElementById(id);
        if (item != null) {
            item.style.fontSize = fontsize + "px";
            if (id.startsWith("DGID"))
                id = id.substring(4);

            if (source != null && source[id] != null) {
                source[id].font = fontsize;
            }
            if (e.detectedPosObject != null) {
                var obj = e.detectedPosObject.find(x => x.text == id)
                if (obj != null)
                    obj.font = fontsize;
            }
        }
    }

    const updatefont = () => {
        var fontsize = document.getElementById("fontsize").value;
        updateBoxfont("DGformaltext", fontsize, draggedObjectList);
        updateBoxfont("DGID#tarih", fontsize, null);
        updateBoxfont("DGID#ruhsatno", fontsize, null);
        updateBoxfont("DGID#kot", fontsize, null);
        updateBoxfont("DGID#fikra", fontsize, null);
        updateBoxfont("DGID#bend", fontsize, null);
        updateBoxfont("DGID#madde", fontsize, null);

    }


    const hasloc = (data, text) => {
        if (data.detectedPosObject == null) return false
        var list = data.detectedPosObject.filter(x => x.text == text);
        if (list.length > 0) {
            return true
        }
        return false;
    }

    const getUnwknownItems = () => {
        var paftaRuhsatOnayKod = <></>;
        var paftaRuhsatTarih = <></>;
        var kot = <></>;
        var fikra = <></>;
        var bend = <></>;
        var madde = <></>;
        var boxWidth = "100%"
        if (isEmpty(e.paftaRuhsatOnayKod) == false) {
            paftaRuhsatOnayKod = <div className="col-1" style={{ "background-color": "white" }}>
                <div style={{ minHeight: "20px", "background-color": "white" }}>
                    <div id={"DGID#ruhsatno"} className="border border-warning border-3" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: boxWidth, height: "30px", "z-index": 2, resize: "both", overflow: "hidden", fontSize: "10px", "background-color": "transparent" }}>
                        <div className="text-danger" style={{ "background-color": "transparent" }}> {e.paftaRuhsatOnayKod} </div>
                    </div>
                </div>
            </div>
        }

        if (isEmpty(e.paftaRuhsatTarih) == false) {
            paftaRuhsatTarih = <div className="col-1" style={{ "background-color": "white" }}>
                <div style={{ minHeight: "20px", "background-color": "white" }}>
                    <div id={"DGID#tarih"} className="border border-warning border-3" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: boxWidth, height: "30px", "z-index": 2, resize: "both", overflow: "hidden", fontSize: "10px", "background-color": "transparent" }}>
                        <div className="text-danger" style={{ "background-color": "transparent" }}> {e.paftaRuhsatTarih} </div>
                    </div>
                </div>
            </div>
        }

        if (isEmpty(e.kot) == false) {
            kot = <div className="col-1" style={{ "background-color": "white" }}>
                <div style={{ minHeight: "20px", "background-color": "white" }}>
                    <div id={"DGID#kot"} className="border border-warning border-3" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: boxWidth, height: "30px", "z-index": 2, resize: "both", overflow: "hidden", fontSize: "10px", "background-color": "transparent" }}>
                        <div className="text-danger" style={{ "background-color": "transparent" }}> {e.kot} </div>
                    </div>
                </div>
            </div>
        }
        if (isEmpty(e.fikra) == false) {
            fikra = <div className="col-1" style={{ "background-color": "white" }}>
                <div style={{ minHeight: "20px", "background-color": "white" }}>
                    <div id={"DGID#fikra"} className="border border-warning border-3" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: boxWidth, height: "30px", "z-index": 2, resize: "both", overflow: "hidden", fontSize: "10px", "background-color": "transparent" }}>
                        <div className="text-danger" style={{ "background-color": "transparent" }}> {e.fikra} </div>
                    </div>
                </div>
            </div>
        }
        if (isEmpty(e.bend) == false) {
            bend = <div className="col-1" style={{ "background-color": "white" }}>
                <div style={{ minHeight: "20px", "background-color": "white" }}>
                    <div id={"DGID#bend"} className="border border-warning border-3" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: boxWidth, height: "30px", "z-index": 2, resize: "both", overflow: "hidden", fontSize: "10px", "background-color": "transparent" }}>
                        <div className="text-danger" style={{ "background-color": "transparent" }}> {e.bend} </div>
                    </div>
                </div>
            </div>
        }
        if (isEmpty(e.madde) == false) {
            madde = <div className="col-1" style={{ "background-color": "white" }}>
                <div style={{ minHeight: "20px", "background-color": "white" }}>
                    <div id={"DGID#madde"} className="border border-warning border-3" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: boxWidth, height: "30px", "z-index": 2, resize: "both", overflow: "hidden", fontSize: "10px", "background-color": "transparent" }}>
                        <div className="text-danger" style={{ "background-color": "transparent" }}> {e.madde} </div>
                    </div>
                </div>
            </div>
        }


        return <div className="row border-bottom mb-2 pb-2 " id="unknownlocitems" >
            {paftaRuhsatOnayKod}
            {paftaRuhsatTarih}
            {kot}
            {fikra}
            {bend}
            {madde}
        </div>
    }



    const placeObjectsOnPic = () => {
        if (isEmpty(e.detectedPos) == true)
            return;

        var detectedPos = JSON.parse(e.detectedPos);
        for (var rec of detectedPos) {
            var value = "unkown"
            if (rec.text == "#tarih") {
                value = e.paftaRuhsatTarih;
            }
            if (rec.text == "#ruhsatno") {
                value = e.paftaRuhsatOnayKod;
            }
            if (rec.text == "#kot") {
                value = e.kot;
            }
            if (rec.text == "#fikra") {
                value = e.fikra;
            }
            if (rec.text == "#bend") {
                value = e.bend;
            }
            if (rec.text == "#madde") {
                value = e.madde;
            }
            var xloc = rec.x;// * PNG_PDF_RATIO;
            var yloc = rec.y;// * PNG_PDF_RATIO;

            var div = document.createElement('div');
            div.innerHTML = value;
            div.classList.add("border");
            div.classList.add("border-warning");
            div.classList.add("border-3");
            div.classList.add("text-danger");
            div.id = "DGID" + rec.text
            div.draggable = true
            div.addEventListener('dragenter', enter);
            div.addEventListener('dragover', allowDrop);
            div.addEventListener('dragleave', dragleave);
            div.addEventListener('dragstart', drag);
            div.addEventListener('drop', drop);
            div.style.resize = "both";
            div.style.overflow = "hidden";
            div.style["background-color"] = "transparent";



            var droppableArea = document.getElementById("pdfimage");
            var droppableDiv = droppableArea.parentElement;


            var div0 = document.createElement('div');

            div0.style.left = xloc + "px";
            div0.style.top = yloc + "px";
            div0.style.width = rec.width + "px";
            div0.style.height = rec.height + "px";
            div0.style.zIndex = 2;
            div0.style.position = "absolute";

           

            div0.appendChild(div);
            droppableDiv.appendChild(div0);






            //fontsize = 8 * PNG_PDF_RATIO;
            //if (rec.font != null)
            //    fontsize = rec.font * PNG_PDF_RATIO;
            //textwidth = rec.width * PNG_PDF_RATIO;
            //        float height = rec.height * PNG_PDF_RATIO;
            //getRotationMargin(rotation, textwidth, height, 0, pageWidth, pageHeight, ref xloc, ref yloc);

        }


        /*
        var paftaRuhsatOnayKod = <></>;
        var paftaRuhsatTarih = <></>;
        var kot = <></>;
        var fikra = <></>;
        var bend = <></>;
        var madde = <></>;
        var boxWidth = "100%"
        if (isEmpty(e.paftaRuhsatOnayKod) == false) {
            paftaRuhsatOnayKod = <div className="col-1" style={{ "background-color": "white" }}>
                <div style={{ minHeight: "20px", "background-color": "white" }}>
                    <div id={"DGID#ruhsatno"} className="border border-warning border-3" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: boxWidth, height: "30px", "z-index": 2, resize: "both", overflow: "hidden", fontSize: "10px", "background-color": "transparent" }}>
                        <div className="text-danger" style={{ "background-color": "transparent" }}> {e.paftaRuhsatOnayKod} </div>
                    </div>
                </div>
            </div>
        }

        if (isEmpty(e.paftaRuhsatTarih) == false) {
            paftaRuhsatTarih = <div className="col-1" style={{ "background-color": "white" }}>
                <div style={{ minHeight: "20px", "background-color": "white" }}>
                    <div id={"DGID#tarih"} className="border border-warning border-3" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: boxWidth, height: "30px", "z-index": 2, resize: "both", overflow: "hidden", fontSize: "10px", "background-color": "transparent" }}>
                        <div className="text-danger" style={{ "background-color": "transparent" }}> {e.paftaRuhsatTarih} </div>
                    </div>
                </div>
            </div>
        }

        if (isEmpty(e.kot) == false) {
            kot = <div className="col-1" style={{ "background-color": "white" }}>
                <div style={{ minHeight: "20px", "background-color": "white" }}>
                    <div id={"DGID#kot"} className="border border-warning border-3" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: boxWidth, height: "30px", "z-index": 2, resize: "both", overflow: "hidden", fontSize: "10px", "background-color": "transparent" }}>
                        <div className="text-danger" style={{ "background-color": "transparent" }}> {e.kot} </div>
                    </div>
                </div>
            </div>
        }
        if (isEmpty(e.fikra) == false) {
            fikra = <div className="col-1" style={{ "background-color": "white" }}>
                <div style={{ minHeight: "20px", "background-color": "white" }}>
                    <div id={"DGID#fikra"} className="border border-warning border-3" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: boxWidth, height: "30px", "z-index": 2, resize: "both", overflow: "hidden", fontSize: "10px", "background-color": "transparent" }}>
                        <div className="text-danger" style={{ "background-color": "transparent" }}> {e.fikra} </div>
                    </div>
                </div>
            </div>
        }
        if (isEmpty(e.bend) == false) {
            bend = <div className="col-1" style={{ "background-color": "white" }}>
                <div style={{ minHeight: "20px", "background-color": "white" }}>
                    <div id={"DGID#bend"} className="border border-warning border-3" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: boxWidth, height: "30px", "z-index": 2, resize: "both", overflow: "hidden", fontSize: "10px", "background-color": "transparent" }}>
                        <div className="text-danger" style={{ "background-color": "transparent" }}> {e.bend} </div>
                    </div>
                </div>
            </div>
        }
        if (isEmpty(e.madde) == false) {
            madde = <div className="col-1" style={{ "background-color": "white" }}>
                <div style={{ minHeight: "20px", "background-color": "white" }}>
                    <div id={"DGID#madde"} className="border border-warning border-3" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: boxWidth, height: "30px", "z-index": 2, resize: "both", overflow: "hidden", fontSize: "10px", "background-color": "transparent" }}>
                        <div className="text-danger" style={{ "background-color": "transparent" }}> {e.madde} </div>
                    </div>
                </div>
            </div>
        }


        return <div className="row border-bottom mb-2 pb-2 " id="unknownlocitems" >
            {paftaRuhsatOnayKod}
            {paftaRuhsatTarih}
            {kot}
            {fikra}
            {bend}
            {madde}
        </div>*/
    }


    if (props.source == "NEW_TASK") {
        window.Swal.fire({
            title: "Lütfen QR Kod ve Kırmızı renkli yasal metni, evrakın görmek istediğiniz yerine sürükleyerek taşıyınız.", confirmButtonText: "Tamam", confirmButtonColor: "#0d6efd",
            icon: 'warning',
        }).then((result) => {
        })

    }
    return (
        <div key={pageNumber} >
            {props.source == "NEW_TASK" && <div className="row mb-1"  >
                <div className="col-12 text-center fw-bold text-danger">
                    <h5 className="fw-bold">Yüklediğinz  dosyada yasal yazı ve QR kod konumu tespit edilemedi.Lütfen QR Kod ve Kırmızı renkli yasal metni, evrakın görmek istediğiniz yerine sürükleyerek taşıyınız.</h5>
                </div>
            </div>}


            {props.source == "DETAL_UPDATE" && <div className="row mb-1"  >
                <div className="col-12 text-center fw-bold text-warning">
                    Aşağıdaki maddelerin konumunu belirtiniz.
                </div>
            </div>}


            <div className="row mb-1"  >
                <div className="col-3">
                    <h6 className="text-primary p-0 m-0">PDF Ayarları</h6 >
                </div>
                <div className="col-6 text-center text-info">
                    E-İmza ID: {e.id}
                </div>
                <div className="col-3 text-end">
                    {isEmpty(e.imzatarih) == false && <a className="text-decoration-none align-middle me-2" href={'getimzalidosya?eimzaid=' + e.id} download="eimza.pdf"><i className="fa fa-cloud-download" style={{ "font-size": "20px" }}></i>  </a>}
                    {role.indexOf(UserPermConstants.Imzaya_Açma) > -1 && <a className="text-decoration-none btn btn-link border-0  p-0 me-2" onClick={() => props.setActivePanel(<EImzaDetails eimza={e} setActivePanel={props.setActivePanel} appData={props.appData} />)}> <i class="fa fa-cog" aria-hidden="true"></i></a>}

                    <button className="btn btn-sm btn-link text-decoration-none p-0 me-1" type="button" onClick={() => props.setActivePanel(<EImzaList appData={props.appData} setActivePanel={props.setActivePanel} changeComponent={props.changeComponent}/>)} title="kapat"> <i class="fa fa-close" style={{ "font-size": "18px" }}></i></button>

                </div>
            </div>


            <div className="row mb-2"  id="mainrow">
                {/*<div className="col-1">*/}

                {/*    */}{/*<button className="btn btn-sm btn-primary me-1" type="button" onClick={zoomin}> <i class="fa fa-search-plus"></i></button>*/}
                {/*    */}{/*<button className="btn btn-sm btn-primary me-1" type="button" onClick={zoomout}>  <i class="fa fa-search-minus"></i></button>*/}

                {/*</div>*/}

                <div className="col-2">
                    <div class="form-group">
                        <label for="email">Metin Boyutu:</label>
                        <input type="number" className="form-control form-control-sm" id="fontsize" defaultValue={10} placeholder="Font" name="fontsize" onChange={updatefont} />

                    </div>
                </div>
                <div className="col-1">
                </div>
                <div className="col-6 text-info text-center">
                    Kutucukları sürükleyerek belgede ilgili alana bırakın.
                </div>

                <div className="col-3 text-end">
                    <button className="btn btn-sm btn-primary me-1" type="button" onClick={() => setPageNumber(pageNumber + 1)}> Sıfırla</button>

                    <button className="btn btn-sm btn-primary me-1" type="button" onClick={submitEimzaVisualSetting}> Uygula</button>

                </div>
            </div>




            {props.source != "DETAL_UPDATE" ? <div className="row border-bottom mb-2 pb-2"  >
                <div className="col-1">
                    <div style={{ minHeight: "50px" }} >
                        <div className="border border-warning border-3 p-0" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: "auto", height: "50px", "z-index": 2, resize: "both", overflow: "hidden", "aspect-ratio": "1/1" }}>
                            <img id="DGqrcode" className="p-0" src={require('../Dashboard/qr.png').default} style={{ width: "100%", height: "100%;auto", "z-index": 2, resize: "both", overflow: "auto", "aspect-ratio": "1/1" }} />
                        </div>
                    </div>
                </div>

                <div className="col-5" style={{ "background-color": "transparent" }}>
                    <div style={{ minHeight: "50px", "background-color": "transparent" }}>
                        <div id="DGformaltext" className="border border-warning border-3" draggable="true" onDragStart={drag} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} style={{ width: "300px", height: "50px", "z-index": 2, resize: "both", overflow: "hidden", fontSize: "10px", "background-color": "transparent" }}>
                            <div className="text-danger" style={{ "background-color": "transparent" }}> {/*<i class="fa fa-arrows text-secondary" ></i>*/} Bu belge, güvenli elektronik imza ile imzalanmıştır. Doğrulama Kodu: 2409c458dee211edb5ea0242ac120002 Doğrulama: https://www.belediye.bel.tr/eimzadogrulama</div>
                        </div>
                    </div>
                </div>
            </div> : getUnwknownItems()}






            <div id="pdfimagediv" style={{
                backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "50px 50px", backgroundPosition: "center", overflow: "auto", width: "100%", height: "700px", "z-index": 1, position: "relative"
            }} onDragOver={allowDrop} onDrop={drop} onDragEnter={enter} onDragLeave={dragleave} >

                <img id="pdfimage" src={"/getdosyaasimage?eimzaid=" + e.id} style={{ Width: "100%", Height: "100%", objectFit: "contain" }} />
            </div>



        </div>

    );


}