import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, showConfirmMessage } from '../GlobalState.js';

export default function SettingUserFile(props) {
    var postdata = {};
    postdata["FileType"] = ".pdf";
    const [listData, setListData] = useState(null);
    const [typeOptions, setTypeOptions] = useState([]);
    var multiselectRef = React.createRef();
    var multiselectRef2 = React.createRef();



    const sil = (rec) => {
        showConfirmMessage(rec.name + " İsimli Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
        fetch("removeuserfilename", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        }));

    }

    const onSelect1 = (selectedList, selectedItem) => {
        postdata["userType"] = multiselectRef.current.getSelectedItems().map(c => c.name).join(", ")
    }
    const onRemove1 = (selectedList, removedItem) => {
        postdata["userType"] = multiselectRef.current.getSelectedItems().map(c => c.name).join(", ")
    }
    const onSelect2 = (selectedList, selectedItem) => {
        postdata["fileType"] = selectedList.join(", ")
    }
    const onRemove2 = (selectedList, removedItem) => {
        postdata["fileType"] = selectedList.join(", ")
    }


    useEffect(() => {
        userfilenamelist();
        getAppTypes();
    }, [])

    const Add = () => {
        fetch("saveuserfilename", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                document.getElementById("zorunlu").checked = false;
                document.getElementById("dosyaadi").value = "";
                multiselectRef.current.resetSelectedValues();
                multiselectRef2.current.resetSelectedValues();
                userfilenamelist();
            }
        });
    }


    const getAppTypes = () => {
        fetch("getusertypelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    var opt = [];
                    for (var x of value) {
                        opt.push({ name: x.appType })
                    }
                    setTypeOptions(opt);
                })
            }
        });
    }



    const userfilenamelist = () => {
        fetch("userfilenamelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }


    const renderTable = (tabledata) => {
        var mytable =

            <table className='table table-striped table-sm' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Dosya Adı</th>
                        <th>Dosya Tipi</th>
                        <th>Kullanıcı Tipi</th>
                        <th>Zorunlu</th>
                    </tr>
                </thead>
                <tbody id="myTable">
                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            {/*<td>{apprec.id}</td>*/}
                            <td>{apprec.name}</td>
                            <td>{apprec.fileType}</td>
                            <td>{apprec.userType}</td>
                            {/*<td>{apprec.crerateTime}</td>*/}
                            {/*<td>{apprec.creatorUserName}</td>*/}
                            <td>
                                <input type="checkbox" className="form-check-input" name="zorunlu" checked={apprec.zorunlu} />
                            </td>
                            <td>  <button onClick={() => sil(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button></td>
                        </tr>
                    )}
                    <tr className="bg-white">

                        <td>
                            <input type="text" className="form-control form-control-sm" id="dosyaadi" placeholder="Dosya Adı" name="Name" onChange={handleInputChange} />
                        </td>
                        <td width="30%">

                            <Multiselect className="text-dark form-select-sm m-0 p-0"
                                options={[".pdf", ".zip", ".doc",".docx", ".xls","xlsx", "image/*"]}
                                emptyRecordMsg="Başka Dosya Tipi Yok"
                                isObject={false}
                                ref={multiselectRef2}
                                onSelect={onSelect2} // Function will trigger on select event
                                onRemove={onRemove2} // Function will trigger on remove event
                                hidePlaceholder={true}

                            />
                        </td>
                        <td width="30%">
                            <Multiselect className="text-dark form-select-sm m-0 p-0"
                                options={typeOptions} // Options to display in the dropdown
                                //selectedValues={appData.başvuruTipi} // Preselected value to persist in dropdown
                                onSelect={onSelect1} // Function will trigger on select event
                                onRemove={onRemove1} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                emptyRecordMsg="Başka Kullanıcı Tipi Yok"
                                ref={multiselectRef}
                                hidePlaceholder={true}
                            />
                        </td>

                        <td> <input type="checkbox" className="form-check-input" name="zorunlu" id="zorunlu" onChange={handleInputChange} /></td>
                        <td>  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                    </tr>
                </tbody>
            </table>

        return (mytable);
    }


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }





    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div >
            <h5>Kullanıcı Dosyaları</h5>

   {listTable}

        </div>

    );


}